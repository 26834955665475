import type { FunctionComponent } from 'react';
import * as styles from './LoadingCart.module.css';

const row = () => (
  <div className={styles.row}>
    <div className={styles.image} />
    <div>
      <div className={styles.title} />
      <div className={styles.title} />
      <div className={styles.button} />
    </div>
    <div className={styles.price} />
  </div>
);

type LoadingCartProps = {
  windowSize: string;
};

const LoadingCart: FunctionComponent<LoadingCartProps> = ({ windowSize }) => (
  <div>
    <div className={styles.tabContainer}>
      <div className={styles.tab} />
      <div className={styles.tab} />
    </div>
    {row()}
    {row()}
    {row()}
    {row()}
    {windowSize === 'mobile web' && row()}
    <div className={styles.totalContiner}>
      <div className={styles.title} />
      <div className={styles.total} />
    </div>
    <div className={styles.footer} />
  </div>
);

export default LoadingCart;
