import { useState, useEffect, useRef } from 'react';

const useInView = () => {
  const [isInView, setIsInView] = useState(false);
  const elementRef = useRef();

  useEffect(() => {
    const onChange = (entries) => {
      const element = entries[0];
      setIsInView(element.isIntersecting);
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: '0px',
    });
    observer.observe(elementRef.current);

    return () => observer.disconnect();
  });

  return { isInView, elementRef };
};

export default useInView;
