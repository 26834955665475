import isBrowser from './isBrowser';

const buildPath = (route: string, subdomain = '', prodOnly = false) => {
  const isAbsolutePath = route.startsWith('http') || route.startsWith('https');
  if (isAbsolutePath) return route;

  const domainParts = isBrowser() ? window.location.hostname.split('.') : ['fabfitfun', 'com'];
  const domain = prodOnly ? 'fabfitfun' : domainParts[domainParts.length - 2];
  let host = `${domain}.${domainParts[domainParts.length - 1]}`;
  if (subdomain !== '') {
    host = `${subdomain}.${host}`;
  }
  return `//${host}${route}`;
};

export { buildPath };
