/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormEvent, useRef, type FunctionComponent } from 'react';
import { CardElement, useRecurly, type CardElementChangeEvent } from '@recurly/react-recurly';
import camelCase from 'lodash/camelCase';
import forEach from 'lodash/forEach';
import { track } from '@fff-web/fff-analytics';
import {
  Select as AdminSelect,
  Button,
  Input,
  showErrorToast,
  showSuccessToast,
} from '@fff-web/fff-ui-shared';
import useFormData from '../../hooks/useFormData';
import { getBillingInformation, updateBillingInformation } from '../../services/billing';
import { ACCOUNT_INFO, ACCOUNT_SECTIONS, ACCOUNT_TRACK_NAMES } from '../../utils/constants';
import * as styles from './EditPaymentMethodForm.module.css';

const BUTTON_CTAS = {
  save: 'SAVE',
  cancel: 'CANCEL',
};

const COUNTRIES = [
  {
    name: 'United States',
    value: 'US',
  },
  {
    name: 'Canada',
    value: 'CA',
  },
  {
    name: 'United Kingdom',
    value: 'GB',
  },
];

interface BillingInfo extends Record<string, string | number | undefined> {
  cardType?: string;
  country: string;
  firstName: string;
  firstSix?: string;
  lastFour?: string;
  lastName: string;
  month?: number;
  year?: number;
  postalCode?: string;
}

interface PaymentFormProps {
  onSubmit: () => void;
  onClose: () => void;
  billingInformation: BillingInfo;
  setBillingInformation: (info: BillingInfo) => void;
}

const EditPaymentMethodForm: FunctionComponent<PaymentFormProps> = ({
  onSubmit,
  onClose,
  billingInformation,
  setBillingInformation,
}) => {
  const {
    formData,
    handleSelectChange,
    handleInputChange,
    handleErrors,
    formErrors,
    removeFormError,
    updatingForm,
    setUpdatingForm,
  } = useFormData(billingInformation);
  const formRef = useRef<HTMLFormElement>(null);
  const recurly = useRecurly();

  const getErrorMessage = (code: string, field: string) => {
    const displayMap: { [key: string]: string } = {
      postal_code: 'zip code',
      country: 'country',
      first_name: 'first name',
      last_name: 'last name',
    };
    if (code === 'validation') {
      return 'This is a required field.';
    }
    return `Please enter a valid ${displayMap[field]}.`;
  };

  const handleRecurlyError = (err: unknown) => {
    const { code, details, message } = err as {
      code: string;
      details: { field: string }[];
      message: string;
    };
    if (message === 'Cvv must be three digits') {
      return handleErrors({
        creditCard: 'The CVV must be three digits for this type of credit card.',
      });
    }
    if (message === 'Cvv must be four digits') {
      return handleErrors({
        creditCard: 'The CVV must be four digits for this type of credit card.',
      });
    }
    return forEach(details, (d: { field: string }) => {
      if (d.field === 'number' || d.field === 'month' || d.field === 'year' || d.field === 'cvv') {
        handleErrors({
          creditCard: 'Please enter valid credit card information.',
        });
      }
      if (
        d.field === 'first_name' ||
        d.field === 'last_name' ||
        d.field === 'postal_code' ||
        d.field === 'country'
      ) {
        handleErrors({
          [camelCase(d.field)]: getErrorMessage(code, d.field),
        });
      }
    });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setUpdatingForm(true);
    if (formRef.current) {
      recurly.token(formRef.current, async (err: unknown, token: { id: string }) => {
        let updateResult = err ? 'failed' : 'success';
        if (err) {
          handleRecurlyError(err);
          showErrorToast(
            'Payment Update Failed',
            'Your payment information was not updated. Please try again.',
          );
        } else {
          try {
            await updateBillingInformation(token.id);
            const response = (await getBillingInformation()) as BillingInfo;
            setBillingInformation({ ...response, postalCode: response.zip as string });
            showSuccessToast(
              'Payment Updated',
              'Your payment information has been successfully updated.',
            );
            track({
              trackName: ACCOUNT_TRACK_NAMES.completed,
              properties: {
                cta_name: BUTTON_CTAS.save,
                account_section: ACCOUNT_SECTIONS.billing,
                info_updated: ACCOUNT_INFO.payment,
              },
            });
            onSubmit();
          } catch {
            showErrorToast(
              'Payment Update Failed',
              'Your payment information was not updated. Please try again.',
            );
            updateResult = 'payment failed';
          }
        }
        setUpdatingForm(false);
        track({
          trackName: 'CC Update: Update of payment method',
          properties: {
            update_result: updateResult,
          },
        });
      });
    } else {
      setUpdatingForm(false);
    }
  };

  const handleRecurlyInput = (e: CardElementChangeEvent) => {
    removeFormError('creditCard');
    if (!e.focus && !e.empty && !e.valid) {
      handleErrors({
        creditCard: 'Please enter valid credit card information.',
      });
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className={styles.row}>
          <Input
            label="First Name"
            placeholder="First Name"
            name="firstName"
            dataRecurly="first_name"
            value={(formData.firstName as string) || ''}
            error={formErrors.firstName}
            onChange={handleInputChange}
            type="text"
            datatestid="edit-payment-first-name"
          />
          <Input
            label="Last Name"
            placeholder="First Name"
            name="lastName"
            dataRecurly="last_name"
            value={(formData.lastName as string) || ''}
            onChange={handleInputChange}
            error={formErrors.firstName}
            type="text"
            datatestid="edit-payment-last-name"
          />
        </div>
        <div className={styles.creditCard}>
          {/* TODO: Please fix the a11y issues with this element and then remove the comments at the top of the file. */}
          <label>Credit Card</label>
          <CardElement onChange={handleRecurlyInput} />
        </div>
        <div className={styles.row}>
          <Input
            label="Zip Code"
            placeholder="Zip Code"
            name="zip"
            dataRecurly="postal_code"
            value={(formData.zip as string) || ''}
            error={formErrors.firstName}
            onChange={handleInputChange}
            type="text"
            datatestid="edit-payment-zip-code"
          />
          <AdminSelect
            dataRecurly="country"
            style={{ width: '100%' }}
            label="Country"
            error={formErrors.country}
            name="country"
            value={(formData.country as string) || ''}
            list={COUNTRIES}
            selectLabel="Select Country"
            onChange={handleSelectChange}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button variation="black-outline" onClick={onClose} className={styles.cancelButton}>
            {BUTTON_CTAS.cancel}
          </Button>
          <Button
            type="submit"
            variation="black"
            disabled={updatingForm}
            className={styles.submitButton}
          >
            {BUTTON_CTAS.save}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditPaymentMethodForm;
