import { getEnv } from '../utils/environment';

const defaultOptions = {
  credentials: 'include',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const simpleFetch = (uri, options = {}, baseUrl = getEnv().REACT_APP_API) =>
  fetch(`${baseUrl}${uri}`, { ...defaultOptions, ...options }).then((response) => {
    if (response.ok) {
      return response.text().then((text) => {
        try {
          return JSON.parse(text);
        } catch {
          throw new Error('Something went wrong');
        }
      });
    }

    throw response;
  });
