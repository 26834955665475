import { sortByOrderSelector } from './common';

export const seasonalTimelineSelector = ({ timelineSteps, currentTimelineSteps }) => {
  const sortedData = sortByOrderSelector(timelineSteps);
  const groupedData = [];
  let currentHappened = false;

  sortedData.forEach((st) => {
    const currentHappening = currentTimelineSteps.includes(st.id);
    if (st.group) {
      const index = groupedData.findIndex(({ label }) => label === st.group);
      if (index > -1) {
        groupedData[index].nodes.push(getWithStatus(st, currentHappened, currentHappening));
      } else {
        groupedData.push({
          label: st.group,
          nodes: [getWithStatus(st, currentHappened, currentHappening)],
        });
      }
    } else {
      groupedData.push({ nodes: [getWithStatus(st, currentHappened, currentHappening)] });
    }

    currentHappened = currentHappened || currentHappening;
  });

  return groupedData;
};

const getWithStatus = (st, currentHappened, currentHappening) => {
  if (currentHappening) {
    return { ...st, status: 'active' };
  }
  if (currentHappened) {
    return { ...st, status: 'future' };
  }

  return { ...st, status: 'past' };
};
