import popupCloseBtn from './popup-close.png';
import sgtfBox from './sgtf-box.png';
import './InvitePopup.css';
import { INVITE_FRIEND_PAGE_URL } from '../../utils/constants';
import { handleTrackAndRedirect, trackInviteStarted } from '../../utils/track';

const INVITE_CTA_NAME = 'SEND A FREE BOX';
const INVITE_EXPERIENCE = 'Homepage Popup';

type InvitePopupProps = {
  closeInvitePopup: () => void;
};

const InvitePopup = ({ closeInvitePopup }: InvitePopupProps) => {
  const handleOnClick = () => {
    handleTrackAndRedirect(
      () => trackInviteStarted(INVITE_CTA_NAME, INVITE_EXPERIENCE),
      INVITE_FRIEND_PAGE_URL,
    );
  };

  return (
    <div>
      <div
        className="invite-popup-overlay"
        role="button"
        aria-label="Close"
        tabIndex={0}
        onClick={closeInvitePopup}
        onKeyPress={closeInvitePopup}
      />
      <div className="invite-popup-container">
        <div className="invite-popup">
          <div
            className="invite-popup__close"
            role="button"
            tabIndex={0}
            onClick={closeInvitePopup}
            onKeyPress={closeInvitePopup}
          >
            <img src={popupCloseBtn} alt="" />
          </div>
          <div className="invite-popup__info">
            <span className="invite-popup__info__title--small">Share a Taste Of FabFitFun</span>
            <h2 className="invite-popup__info__title">Send A Free Starter Box &#127873;</h2>
            <p className="invite-popup__info__title--msg">
              You have Starter Boxes to send! Be the best friend ever and send friends their first
              box free. Each Starter Box is valued at over $125. *Shipping rates apply.
            </p>
            <button className="invite-popup__info__button" type="button" onClick={handleOnClick}>
              Send A Free Box
            </button>
          </div>
          <div className="invite-popup__box">
            <img src={sgtfBox} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitePopup;
