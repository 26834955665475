import type { FunctionComponent } from 'react';
import Button from '../../../shared/components/Button/Button';
import * as styles from './EmptyState.module.css';

type EmptyStateProps = {
  icon: string;
  title: string;
  description: string;
  cta: string;
  action: () => void;
  secondDescription?: string;
};

const EmptyState: FunctionComponent<EmptyStateProps> = ({
  icon,
  title,
  description,
  cta,
  action,
  secondDescription,
}) => (
  <div className={styles.container}>
    {icon && <i className={icon} />}
    <p className={styles.title}>{title}</p>
    {description && <p className={styles.description}>{description}</p>}
    {secondDescription && <p className={styles.description}>{secondDescription}</p>}
    <Button onClick={action} text={cta} theme="primary" style={{ marginTop: 24 }} />
  </div>
);

export default EmptyState;
