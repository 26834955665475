import { useEffect, useReducer } from 'react';

import { actions, homepageReducer, initialState } from '../Store';
import { getWebCards } from '../../../services/homepage';

const useWebCards = () => {
  const [{ webCards }, stateDispatch] = useReducer(homepageReducer, initialState());

  useEffect(() => {
    stateDispatch(actions.getWebCards());
    getWebCards()
      .then((data) => stateDispatch(actions.getWebCardsSuccess(data)))
      .catch((error) => stateDispatch(actions.getWebCardsFail(error)));
  }, []);

  return webCards;
};

export default useWebCards;
