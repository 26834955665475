import { bool, func } from 'prop-types';
import './style.css';

const ReviewButton = ({ desktop = false, trackElements = () => null }) => (
  <a
    className={`review-button${desktop ? '-desktop' : ''}`}
    href="/rate-my-box/"
    onClick={() => trackElements('Review My Box')}
  >
    <i className="fal fa-star" />
    Review My Box
  </a>
);

ReviewButton.propTypes = {
  desktop: bool,
  trackElements: func,
};

export default ReviewButton;
