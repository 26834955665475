import { Fragment } from 'react';
import cx from 'classnames';
import { isLastElementAttrEqual } from '../../utils/array';
import { parseNodeData } from '../../utils/timeline';
import Node from './Node';

type NodeBoxProps = {
  label: string;
  nodes: {
    id: string | number;
    message: string;
    order: string | number;
    date: string;
    group: string;
    status: string;
  }[];
};

const NodeBox = ({ label, nodes }: NodeBoxProps) => (
  <div className={cx('timeline__node-box', { colored: label })} data-testid="node-box">
    {label && <div className="timeline__node-box__label">{label}</div>}
    <div className="timeline__node-box__container">
      {nodes &&
        nodes.map((node, index) => {
          const { message, status, link, date } = parseNodeData(node);
          return (
            <Fragment key={node.id}>
              <Node message={message} status={status} link={link} date={date} />
              {index < nodes.length - 1 && (
                <div
                  className={cx('timeline__node-box__line', { solid: node.status === 'past' })}
                />
              )}
            </Fragment>
          );
        })}
      <div
        className={cx('timeline__line', { solid: isLastElementAttrEqual(nodes, 'status', 'past') })}
      />
    </div>
  </div>
);

export default NodeBox;
