/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { FunctionComponent } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { SALE_TYPES } from '../../../services/utils/campaign';
import { CART_TYPE } from '../../../services/utils/types';
import IncrementButton from '../../../shared/components/IncrementButton/IncrementButton';
import * as styles from './CartItem.module.css';

const SHIPPING_RESTRICTION_TOOLTIP =
  'This product cannot be shipped to you based on the Shipping Address on file.';
const CADENCE_MAP = {
  NINETY_DAYS: 'Ships every 90 days',
  GO_WITH_BOX: 'First order ships immediately, renewals ship with your Seasonal Box.',
  GO_WITH_BOX_EDIT: 'Ships every season with box',
};
const CADENCE_TYPES = {
  NINETY_DAYS: 'NINETY_DAYS',
  GO_WITH_BOX: 'GO_WITH_BOX',
};

type CartItemProps = {
  id: string;
  cartDisplayName: string;
  quantity: number;
  onAddItem: () => void;
  imageUrl: string;
  soldOut: boolean;
  onRemoveItem: () => void;
  onClickProduct: () => void;
  cartLimit: number;
  inventoryLimit: number;
  shippingRestrictions: string[];
  userShippingRestriction: string;
  cartType: typeof CART_TYPE;
  displayPrice: string;
  strikethroughPrice: string;
  sponsored: boolean;
  cadenceType: typeof CADENCE_TYPES;
  campaignType: typeof SALE_TYPES;
  isAdditionalChoice: boolean;
  isLuxeChoice: boolean;
};

const CartItem: FunctionComponent<CartItemProps> = ({
  id,
  cartDisplayName,
  quantity,
  onAddItem,
  imageUrl,
  soldOut,
  onRemoveItem,
  onClickProduct,
  cartLimit,
  inventoryLimit,
  shippingRestrictions,
  userShippingRestriction,
  cartType,
  displayPrice,
  strikethroughPrice,
  sponsored,
  cadenceType,
  campaignType,
  isAdditionalChoice,
  isLuxeChoice,
}) => {
  const isVariantAtPurchaseLimit = quantity === cartLimit || isLuxeChoice;
  const isOverInventoryLimit = quantity >= inventoryLimit;
  const isRestricted = includes(shippingRestrictions, userShippingRestriction);
  const isSubscribableShopVariant = !!cadenceType;
  const itemName = (
    <>
      {isLuxeChoice && <b>Luxe Choice: </b>}
      {isAdditionalChoice && !isLuxeChoice && <b>Additional Choice: </b>}
      {cartDisplayName}
      {!!isSubscribableShopVariant && ' Subscription'}
    </>
  );
  const displayShopWarningNote = cartType === CART_TYPE.shop && quantity > inventoryLimit;

  const getCadenceCopy = () => {
    if (campaignType === SALE_TYPES.EDIT && cadenceType === CADENCE_TYPES.GO_WITH_BOX) {
      return CADENCE_MAP.GO_WITH_BOX_EDIT;
    }
    return CADENCE_MAP[cadenceType];
  };

  const renderShopWarningNote = () => {
    const defaultNote = `Only ${inventoryLimit} of this item are available. Please update quantity.`;
    const warningNote = {
      0: 'This item is sold out. Please remove it.',
      1: 'Only 1 of this item is available. Please update quantity.',
    };
    const note = (message) => (
      <div className={styles.warning} data-testid="cart-item-warning">
        <p>{message}</p>
      </div>
    );
    const message = get(warningNote, inventoryLimit, defaultNote);
    return note(message);
  };

  {
    /* TODO: Please fix the a11y issues with this element and then remove the comments at the top of the file. */
  }
  return (
    <div
      className={classNames(styles.container, {
        [styles.additionalChoiceContainer]: isAdditionalChoice,
      })}
      onClick={isAdditionalChoice ? onClickProduct : undefined}
      data-testid="cart-item-container"
    >
      <div className={styles.variant} data-testid="cart-item-variant">
        {isRestricted ? (
          <span
            data-tip={SHIPPING_RESTRICTION_TOOLTIP}
            data-for="shipping-restriction"
            className={styles.imageLink}
          >
            <img alt="product" src={imageUrl} />
          </span>
        ) : (
          <button
            type="button"
            className={classNames(styles.nakedButton, styles.imageLink)}
            onClick={onClickProduct}
            data-dd-action-name={`${itemName} link image in the cart`}
            data-testid="cart-item-button-imagelink"
          >
            <img alt="product" src={imageUrl} />
          </button>
        )}
        <div className={styles.content}>
          {isRestricted ? (
            <span data-tip={SHIPPING_RESTRICTION_TOOLTIP} data-for="shipping-restriction">
              {itemName}
            </span>
          ) : (
            <button
              type="button"
              className={classNames(styles.nakedButton, styles.name)}
              onClick={onClickProduct}
              data-dd-action-name={`${itemName} link button in the cart`}
              data-testid="cart-item-button-name"
            >
              {itemName}
            </button>
          )}
          {isSubscribableShopVariant && (
            <div className={styles.cadence} data-testid="cart-item-cadence">
              {getCadenceCopy()}
            </div>
          )}
          <IncrementButton
            id={id}
            quantity={quantity}
            isVariantAtPurchaseLimit={isVariantAtPurchaseLimit}
            onAddItem={onAddItem}
            soldOut={soldOut}
            isOverInventoryLimit={isOverInventoryLimit}
            onRemoveItem={onRemoveItem}
            sponsored={sponsored}
          />
        </div>
        <div className={styles.right}>
          <span className={styles.price} data-testid="cart-item-price">{`$${displayPrice}`}</span>
          {strikethroughPrice && (
            <span
              className={classNames(styles.price, styles.strikethroughPrice)}
            >{`$${strikethroughPrice}`}</span>
          )}
        </div>
      </div>
      {displayShopWarningNote && renderShopWarningNote()}
    </div>
  );
};

export default CartItem;
