const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getNodeMessage = (message: string, date: string) => {
  if (date) {
    try {
      const jsDate = new Date(`${date.replace(/-/g, '/')} UTC`);

      return `${message} ${monthNames[jsDate.getMonth()]}. ${jsDate.getDate()}`;
    } catch (_) {
      return message;
    }
  }

  return message;
};
