import { bool, shape, oneOfType, node, func } from 'prop-types';
import { FontAwesome } from '@fff-web/fff-ui-shared';
import ChallengeBox from './ChallengeBox';
import useScreenMode from '../../hooks/useScreenMode';
import './styles.css';

const MyBoxStatus = ({
  showReviewButton = false,
  RightContent = null,
  flags = {},
  showAddressDetails = false,
}) => {
  const screenMode = useScreenMode();

  return (
    <>
      <FontAwesome />
      <ChallengeBox
        flags={flags}
        screenMode={screenMode}
        showReviewButton={showReviewButton}
        RightContent={RightContent}
        showAddressDetails={showAddressDetails}
      />
    </>
  );
};

MyBoxStatus.propTypes = {
  showReviewButton: bool,
  showAddressDetails: bool,
  flags: shape({}),
  RightContent: oneOfType([node, func]),
};

export default MyBoxStatus;
