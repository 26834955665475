import { useDecision } from '@optimizely/react-sdk';

const useFeatureFlagExperiment = (featureFlag) => {
  const [decision, isEnable] = useDecision(featureFlag, { autoUpdate: true });

  const { ruleKey, variationKey } = decision || {};

  return isEnable ? [ruleKey, variationKey, decision] : [];
};

export default useFeatureFlagExperiment;
