export const SHOP_COOKIE = `fff_shop_globalCart`;
export const FLASH_COOKIE = `fff_flash_globalCart`;

export const cookie = {
  get(key) {
    const v = global.window?.document?.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  },
  // maxAge in days
  set(key, value, maxAge) {
    if (key && value) {
      global.window.document.cookie = `${key}=${value};${maxAge && ` max-age=${60 * 60 * 24 * maxAge}`}`;

      // check if cookie was successfully set to browser
      return this.get(key) === value;
    }

    return false;
  },
};
