import { useMediaQuery } from 'react-responsive';

const useScreeMode = () => {
  let screenMode = 'mobile';

  if (useMediaQuery({ minWidth: 767, maxWidth: 1023 })) {
    screenMode = 'tablet';
  }
  if (useMediaQuery({ minWidth: 1024 })) {
    screenMode = 'desktop';
  }

  if (useMediaQuery({ minWidth: 1199 })) {
    screenMode = 'big-desktop';
  }

  return screenMode;
};

export default useScreeMode;
