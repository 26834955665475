/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import cx from 'classnames';
import { ReferrerQueryString, track, trackElement } from '@fff-web/fff-analytics';
import '../Footer.css';
import UserWay from '../UserWay';
import { SUBSCRIPTION_STATE } from '../utils/constants';
import isBrowser from '../utils/isBrowser';
import type { FooterLink, LinkFooterProps, LinkFooterState } from './types';

const SWAP_ITEM_ID = 'm-0-4';
const filterSwapElement = (links: FooterLink[]) => links.filter((link) => link.id !== SWAP_ITEM_ID);

export class LinkFooter extends Component<LinkFooterProps, LinkFooterState> {
  state = {
    activeMenuId: 0,
  };

  handleClickHeader = (e, category) => {
    const { buildPath } = this.props;

    if (!category.links && isBrowser()) {
      window.location.replace(buildPath(category.route, category.subdomain, category.prodOnly));
      return;
    }
    const header = e.target.dataset.headername;
    this.setState(({ activeMenuId }) => ({
      activeMenuId: activeMenuId === header ? 0 : header,
    }));
  };

  render() {
    const { categories, isMobile, isLogin, buildPath, userInfo, subscriptionPlans, hideSwap } =
      this.props;
    const { activeMenuId } = this.state;
    const { handleClickHeader } = this;
    const isExpired = userInfo?.user.subscriptionState === SUBSCRIPTION_STATE.expired;

    const linkContainerStyles = cx({
      'fff-shared-footer-top-section-col': true,
      'fff-shared-footer-top-section-col-loged': isLogin,
    });
    const getInnerLinkStyle = () =>
      cx({
        'fff-shared-Footer-linkFooter': true,
        'fff-shared-Footer-link-container': isMobile,
      });

    const innerContainerLinkStyle = (header) =>
      cx({
        'border-b': isMobile,
        'fff-shared-footer-inner-container-link': true,
        'fff-shared-footer-inner-container-link-login': isLogin,
        'o-hidden': true,
        'is-active': header === activeMenuId,
      });
    const headerLinkStyle = cx({
      'fff-shared-footer-header-link': true,
    });
    const plan = subscriptionPlans && subscriptionPlans[userInfo?.user?.planCode];

    const getInnerContainerLinkStyles = (header, links) => {
      const linkHeight = isLogin ? 54 : 42;

      return isMobile
        ? { height: header === activeMenuId ? 48 * links.length + 60 : linkHeight }
        : {};
    };

    const clickListener = (buildedPath, trakingName, label) => {
      if (label === 'Swap for Credit') {
        const path = `${buildedPath}?referrer=${ReferrerQueryString.footerLink}`;
        track({
          trackName: 'Swap Started',
          properties: {
            swap_amount: plan?.boxPrice?.amount,
            plan_code: userInfo.user.planCode,
            cta_name: label,
            experience: 'Footer Link',
          },
          callback: () => {
            window.location.href = path;
          },
        });
      } else if (trakingName) {
        trackElement({
          elementName: trakingName,
          callback: () => {
            window.location.href = buildedPath;
          },
        });
      }
    };

    return (
      <div className={linkContainerStyles}>
        {isMobile && <UserWay />}
        {categories.map((category) => {
          const { label } = category;
          const { header = label, id, links } = category;

          const filteredLinks = hideSwap || isExpired ? filterSwapElement(links) : links;

          return (
            <div
              key={id}
              className={innerContainerLinkStyle(header)}
              style={getInnerContainerLinkStyles(header, filteredLinks)}
            >
              <button
                type="button"
                className={headerLinkStyle}
                data-headername={header}
                onClick={(e) => handleClickHeader(e, category)}
              >
                {header}
              </button>
              <div id={header.toLowerCase().split(' ').join('-')} className={getInnerLinkStyle()}>
                {filteredLinks.map(({ id, label, route, subdomain, prodOnly, trakingName }) => (
                  /* TODO: Please fix the a11y issues with this element and then remove the comments at the top of the file. */
                  <a
                    key={id}
                    className="fff-shared-footer-links footer-child-link"
                    href={trakingName ? undefined : buildPath(route, subdomain, prodOnly)}
                    onClick={() =>
                      clickListener(buildPath(route, subdomain, prodOnly), trakingName, label)
                    }
                  >
                    {label}
                  </a>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
