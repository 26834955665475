import { Fallbacks } from '../types';

const upgradeConfirmationFallbacks: Fallbacks = {
  ['upgrade-to-annual']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Upgrade Now to Annual',
    cta: null,
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: null,
  },
};

export default upgradeConfirmationFallbacks;
