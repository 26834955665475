import { simpleFetch } from './customFetch';

export const getHeroBanner = () => simpleFetch('/hero-banners/current');

export const getWebCards = () => simpleFetch('/web-cards?limit=18');

export const getSeasonalTimelines = () => simpleFetch('/seasonal-timelines');

export const getBoxState = () =>
  simpleFetch('/boxes/self/box-state', {}, process.env.REACT_APP_BOX_API);
