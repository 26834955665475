import type { FunctionComponent } from 'react';
import Button from '../../../shared/components/Button/Button';
import * as styles from './SponsoredProductModal.module.css';

const copy = {
  sponsored: {
    title: 'Are you sure you want to remove this Free Gift?',
    description: 'Quantities are limited and you might not have an opportunity to add this again!',
  },
  nonSponsored: {
    title: 'Are you sure you want to remove this product?',
    description: "You'll lose your free gift :(",
  },
};

type SponsoredProductModalProps = {
  closeModal: () => void;
  handleClickContinue: () => void;
  modalType: string;
};

const SponsoredProductModal: FunctionComponent<SponsoredProductModalProps> = ({
  closeModal,
  handleClickContinue,
  modalType,
}) => {
  return (
    <div className={styles.modal}>
      {}
      <div
        className={styles.overlay}
        onClick={closeModal}
        role="button"
        onKeyDown={closeModal}
        tabIndex={0}
      />
      <div className={styles.container}>
        <button className="cart-button" type="button" onClick={closeModal}>
          <i className="fas fa-times-circle" />
        </button>
        <h2>{copy[modalType as keyof typeof copy].title}</h2>
        <p>{copy[modalType as keyof typeof copy].description}</p>
        <div className={styles.buttonContainer}>
          <Button
            text="Yes, Remove"
            theme="outline"
            onClick={handleClickContinue}
            style={{ width: '228px' }}
          />
          <Button text="Cancel" theme="primary" onClick={closeModal} style={{ width: '228px' }} />
        </div>
      </div>
    </div>
  );
};

export default SponsoredProductModal;
