import type { FunctionComponent } from 'react';
import * as styles from './CartFooter.module.css';

type CartFooterProps = {
  cartText: string;
};

const CartFooter: FunctionComponent<CartFooterProps> = ({ cartText }) => (
  <div className={styles.container}>
    <h3>No checkout Necessary</h3>
    <div className={styles.cartText} dangerouslySetInnerHTML={{ __html: cartText }} />
  </div>
);

export default CartFooter;
