import { useEffect, useReducer } from 'react';
import { getInvitePopupExpirationFromStorage } from '../helpers';
import { actions, homepageReducer, initialState } from '../Store';

const { getShowInvitePopupSuccess, getShowInvitePopupFail } = actions;

const useShowInvitePopup = (key) => {
  const [{ showInvitePopup }, stateDispatch] = useReducer(homepageReducer, initialState());

  useEffect(() => {
    if (Date.now() > getInvitePopupExpirationFromStorage(key)) {
      stateDispatch(getShowInvitePopupSuccess());
    } else {
      stateDispatch(getShowInvitePopupFail());
    }
  }, []);

  const stateDispatchHandler = (action) => stateDispatch(action);

  return { showInvitePopup, stateDispatch: stateDispatchHandler };
};

export default useShowInvitePopup;
