import { useEffect } from 'react';

const useLaunchDarkly = (ldClient, userData) => {
  useEffect(() => {
    if (ldClient) {
      const user = {
        key: userData && userData.user && userData.user.userId ? userData.user.userId : 'anonymous',
      };
      ldClient.identify(user);
    }
  }, [!ldClient, userData && userData.user]);
};

export default useLaunchDarkly;
