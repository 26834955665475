import { getUserInfo } from '@fff-web/fff-utilities';
import {
  FffAnalyticsProps,
  FilteredIdentifyProps,
  PropertiesProps,
  TrackElementProps,
  TrackEventProps,
  TrackPageLoadProps,
  TrackProps,
} from './interfaces';
import IdentifyProps from './interfaces/identifyProps';
import {
  analyticsIsNotInitialized,
  ctaNameIsRequired,
  elementParameterIsRequired,
  trackNameIsRequired,
} from './lib/helpers/consoleWarn';
import { isTargetingAllowed } from './lib/helpers/onetrust';
import { removeTrailingSlash } from './lib/helpers/removeTrailingSlash';
import { getEnv } from './lib/utils/environment';
import { getDevice, getGenericTrackingData } from './lib/utils/genericTracking';
import identifyInfo from './lib/utils/identifyInfo';
import isBrowser from './lib/utils/isBrowser';
import { loadSegment } from './lib/utils/loadSegment';
import { ReferrerQueryString } from './lib/utils/referrerQueryString';
import { SKIP_TITLE_CASE } from './lib/utils/toTitleCase';
import trackInfo from './lib/utils/trackInfo';
import { EXPERIMENT_VIEWED, useTrackOptimizely } from './lib/utils/useTrackOptimizely';

let fffAnalytics: FffAnalyticsProps;

if (isBrowser()) {
  fffAnalytics = window.fffAnalytics = window.fffAnalytics || {
    segmentToken: getEnv().segmentToken,
    userData: {},
    sessionInfo: getGenericTrackingData(),
    init: false,
  };
}

const initialize = async (): Promise<void> => {
  if (!fffAnalytics) return;
  if (!fffAnalytics.init) fffAnalytics.init = loadSegment({ token: fffAnalytics.segmentToken });
  fffAnalytics.sessionInfo = getGenericTrackingData();
  fffAnalytics.userData = await getUserInfo();
};

const trackPageLoad = async (pageLoadProps?: TrackPageLoadProps): Promise<void | undefined> => {
  const defaultBehavior = {
    pathName: null,
    properties: {},
    options: {},
    callback: () => null,
  };
  const { pathName, properties, options, callback } = pageLoadProps || defaultBehavior;
  if (!isBrowser() || !window.analytics) return analyticsIsNotInitialized();
  const targetingAllowed = isTargetingAllowed();
  if (!targetingAllowed) return Promise.resolve();
  const localStorageUserInfo = await getUserInfo();
  fffAnalytics.userData = localStorageUserInfo;
  const page = removeTrailingSlash(pathName || isBrowser() ? window.location.pathname : '');
  const trackProperties: PropertiesProps = trackInfo({
    fffAnalytics,
    localStorageUserInfo,
    properties,
  });
  return window.analytics.page(page, trackProperties, options, callback);
};

const trackEvent = async ({
  ctaName,
  properties = {},
  options = {},
  callback = () => null,
  trackName,
}: TrackEventProps): Promise<void> => {
  if (!ctaName) return ctaNameIsRequired();
  return await track({
    trackName: trackName || 'Clicked CTA',
    properties: { cta_name: ctaName, ...properties },
    options,
    callback,
  });
};

const trackElement = async ({
  elementName,
  properties = {},
  options = {},
  callback = () => null,
}: TrackElementProps): Promise<void> => {
  if (!elementName) return elementParameterIsRequired();
  return await track({
    trackName: 'Clicked Element',
    properties: { element_name: elementName, ...properties },
    options,
    callback,
  });
};

const track = async ({
  trackName,
  properties = {},
  options = {},
  callback = () => null,
}: TrackProps): Promise<void> => {
  if (!trackName) return trackNameIsRequired();
  if (!isBrowser() || !window.analytics) return analyticsIsNotInitialized();
  const targetingAllowed = isTargetingAllowed();
  if (!targetingAllowed) return Promise.resolve();
  const localStorageUserInfo = await getUserInfo();
  fffAnalytics.userData = localStorageUserInfo;

  const trackProperties: PropertiesProps = trackInfo({
    fffAnalytics,
    localStorageUserInfo,
    properties,
  });
  properties.propertiesToOmit?.forEach((prop) => {
    delete trackProperties[prop];
  });
  return window.analytics.track(trackName, trackProperties, options, callback);
};

const identify = async (identifyProps?: IdentifyProps): Promise<void> => {
  const defaultIdentifyProps = {
    userId: '',
    traits: {},
    options: {},
    callback: () => null,
  };
  const { userId, traits, options, callback } = identifyProps || defaultIdentifyProps;
  if (!isBrowser() || !window.analytics) return analyticsIsNotInitialized();
  const targetingAllowed = isTargetingAllowed();
  if (!targetingAllowed) return Promise.resolve();
  const localStorageUserInfo = await getUserInfo();
  fffAnalytics.userData = localStorageUserInfo;
  const identifyUserId = userId || localStorageUserInfo?.user?.userId;
  const uuid = localStorageUserInfo?.user?.uuid;
  const identifyProperties: FilteredIdentifyProps = identifyInfo({
    userInfo: localStorageUserInfo,
    traits: { fffUUID: uuid, ...traits },
  });
  if (window.analytics.user) window.analytics.user().traits({});

  return window.analytics.identify(identifyUserId ?? null, identifyProperties, options, callback);
};

export {
  EXPERIMENT_VIEWED,
  getDevice,
  identify,
  initialize,
  ReferrerQueryString,
  SKIP_TITLE_CASE,
  track,
  trackElement,
  trackEvent,
  trackPageLoad,
  useTrackOptimizely,
};
