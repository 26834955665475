import { track } from '@fff-web/fff-analytics';
import { TRACK_NAMES, HOMEPAGE_EXPERIENCE, INVITE_TYPE, MEMBER_TYPES } from './constants';

export const handleTrackAndRedirect = (trackFn, page) => {
  trackFn().finally(() => {
    window.location.href = page;
  });
};

export const trackUpgradeStarted = (cta) =>
  track({
    trackName: TRACK_NAMES.upgradeStarted,
    properties: {
      cta_name: cta,
      experience: HOMEPAGE_EXPERIENCE,
    },
  });

export const trackInviteStarted = (cta, experience) =>
  track({
    trackName: TRACK_NAMES.inviteStarted,
    properties: {
      cta_name: cta,
      experience,
      invite_type: INVITE_TYPE,
    },
  });

export const trackReactivationStarted = (cta, isExpired, subscriptionType) =>
  track({
    trackName: TRACK_NAMES.reactivationStarted,
    properties: {
      cta_name: cta,
      experience: HOMEPAGE_EXPERIENCE,
      type: isExpired ? MEMBER_TYPES.expired : MEMBER_TYPES.canceled,
      reactivation_type: isExpired ? 'Expired' : `Canceled ${subscriptionType}`,
    },
  });
