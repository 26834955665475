import cx from 'classnames';
import { getNodeMessage } from '../../utils/timeline';

type NodeProps = {
  isBoxNode?: boolean;
  isMobile?: boolean;
  message?: string;
  date?: string;
  status?: string;
  link?: string;
  trackingUrl?: string;
  bodyDetail?: string;
};

const Node = ({
  isBoxNode,
  isMobile,
  message,
  date,
  status,
  link,
  trackingUrl,
  bodyDetail,
}: NodeProps) => {
  const filledShippedNode = (status === 'active' || status === 'past') && message === 'Shipped';
  const showBodyDetail = status === 'active' && bodyDetail;

  return isBoxNode ? (
    // TODO: Is this really necessary?
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      data-testid="node-box"
      className={cx({
        'challenge-timeline__node': isMobile,
        'challenge-timeline__node-desktop': !isMobile,
        'challenge-timeline__node-cursor': filledShippedNode,
      })}
      href={filledShippedNode ? trackingUrl : undefined}
      target="_blank"
    >
      <div className={cx('timeline__node__point', status)} />
      <div
        className={
          isMobile ? 'challenge-timeline__node__label' : 'challenge-timeline__node__label-desktop'
        }
      >
        {message}
        {showBodyDetail && (
          <div
            className="timeline__node__current-step-message"
            dangerouslySetInnerHTML={{ __html: bodyDetail }}
          />
        )}
      </div>
    </a>
  ) : (
    <div className="timeline__node" data-testid="timeline-node">
      <div className={cx('timeline__node__point', status)} />
      {link ? (
        <div className="timeline__node__label__container">
          <a className="timeline__node__label" href={link}>
            {message}
          </a>
          <span className="timeline__node__label">{getNodeMessage('', date)}</span>
        </div>
      ) : (
        <div className="timeline__node__label">{getNodeMessage(message, date)}</div>
      )}
    </div>
  );
};

export default Node;
