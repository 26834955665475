import type { FunctionComponent, SVGProps } from 'react';

const Icon: FunctionComponent<SVGProps<SVGSVGElement>> = ({
  children,
  width,
  height,
  viewBox,
  ...props
}) => (
  <svg width={width} height={height} viewBox={viewBox} {...props}>
    {children}
  </svg>
);

export default Icon;
