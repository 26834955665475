import { track as trackEvent } from '@fff-web/fff-analytics';
import { GROWTH_GLOBAL_NAV } from './featureFlagVariants/growth-global-nav';

const { VARIATION_A, VARIATION_B, VARIATION_C, VARIATION_D, VARIATION_E } = GROWTH_GLOBAL_NAV;

export const MENU_LABELS = {
  gift: 'gift',
  login: 'login',
  membership: 'membership',
  shop: 'shop',
  getStarted: 'get started',
  quiz: 'take the quiz',
};

export const ANNUAL_PLAN_ID = 'start_amped_quiz';
export const FEATURE_LIST_ID = 'how-it-works-section';
const CTA_JOIN_TEXT = 'JOIN';
const CTA_GET_THE_BOX = 'GET THE BOX';
export const CTA_TRACK_LABEL = 'GTB Clicked CTA';
export const CTA_SHOP_LABEL = 'Public Shop Accessed';
export const CTA_NAMES = {
  membership: 'MEMBERSHIP',
  shop: 'SHOP',
  gift: 'GIFT',
  login: 'LOGIN',
  getStarted: 'GET STARTED',
  'take the quiz': 'TAKE THE QUIZ',
};

export const COMPONENT_LABEL = 'MENU';

export const scrollTo = (SECTION_ID) =>
  document.getElementById(SECTION_ID)?.scrollIntoView({ behavior: 'smooth', block: 'start' });

export const renderButtonLabel = (defaultLabel, isMobile) => {
  if (defaultLabel) return isMobile ? CTA_JOIN_TEXT : defaultLabel;
  return isMobile ? CTA_JOIN_TEXT : CTA_GET_THE_BOX;
};

export const variationMapDesktop = {
  [VARIATION_A]: [MENU_LABELS.gift, MENU_LABELS.login],
  [VARIATION_B]: [MENU_LABELS.login, MENU_LABELS.membership, MENU_LABELS.shop],
  [VARIATION_C]: [MENU_LABELS.gift, MENU_LABELS.login, MENU_LABELS.membership, MENU_LABELS.shop],
  [VARIATION_D]: [MENU_LABELS.gift, MENU_LABELS.login, MENU_LABELS.membership],
  [VARIATION_E]: [MENU_LABELS.quiz, MENU_LABELS.login, MENU_LABELS.membership, MENU_LABELS.gift],
};

export const variationMapMobile = {
  [VARIATION_A]: [MENU_LABELS.gift, MENU_LABELS.login, MENU_LABELS.getStarted],
  [VARIATION_B]: [MENU_LABELS.login],
  [VARIATION_C]: [MENU_LABELS.gift, MENU_LABELS.login],
  [VARIATION_D]: [MENU_LABELS.gift, MENU_LABELS.login],
  [VARIATION_E]: [MENU_LABELS.gift, MENU_LABELS.login],
};

/**
 *
 * @param {Array} routes - An array of route objects, each containing a 'label' property.
 * @param {string} variation - The name of the desired variation.
 * @param {object} variationMap - A mapping of variation names to arrays of associated labels.
 * @returns {Array} - An array containing the filtered routes that match the selected variation's labels.
 */

/**
 * Filter the routes based on the selected variation
 * @param {Array} routes - An array of route objects, each containing a 'label' property.
 * @param {string} variation - The name of the desired variation.
 * @param {object} variationMap - A mapping of variation names to arrays of associated labels.
 * @returns {Array} - An array containing the filtered routes that match the selected variation's labels.
 */
export function chosenRouteBaseOnVariation(routes, variation, variationMap) {
  const routesArray = routes || [];
  const selectedVariation = variationMap[variation] || variationMapDesktop.Variation_A;

  if (!selectedVariation) {
    return [];
  }

  return routesArray.filter((item) => selectedVariation.includes(item.label));
}

/**
 * Create the routes for the global navigation
 * @param {string} baseUrl - The base URL of the site.
 * @param {string} loginUrl - The URL of the login page.
 * @param {boolean} isLoginPage - Whether the current page is the login page.
 * @returns {Array} - An array of route objects.
 */
export function createRoutes(baseUrl, loginUrl, isLoginPage = false) {
  return [
    {
      label: MENU_LABELS.membership,
      url: `${baseUrl}/get-the-box#how-it-works-section`,
    },
    {
      label: MENU_LABELS.quiz,
      url: `${baseUrl}/get-the-box/quiz/`,
    },
    {
      label: MENU_LABELS.shop,
      url: `${baseUrl}/shop/?utm_source=gtb`,
      onClick: undefined,
    },
    {
      label: MENU_LABELS.gift,
      url: `${baseUrl}/gift`,
      onClick: undefined,
    },
    !isLoginPage && {
      label: MENU_LABELS.login,
      url: `${loginUrl}`,
      onClick: undefined,
    },
    {
      label: MENU_LABELS.getStarted,
      url: undefined,
      onClick: isLoginPage
        ? () => (window.location.href = `${baseUrl}/get-the-box`)
        : () => scrollTo(ANNUAL_PLAN_ID),
    },
  ];
}

export const handleTrackerName = (ctaName) => {
  if (ctaName === MENU_LABELS.shop) {
    return CTA_SHOP_LABEL;
  }
  return CTA_TRACK_LABEL;
};

export const createTrackingData = async ({ ctaName, segmentTrackingData }) => {
  try {
    const trackName = handleTrackerName(ctaName);
    await trackEvent({
      trackName,
      properties: {
        cta_name: CTA_NAMES[ctaName],
        sessionId: segmentTrackingData?.userId,
        sessionCreationDate: segmentTrackingData?.sessionCreationDate,
        component_label: COMPONENT_LABEL,
      },
    });
  } catch (error) {
    console.log({ error });
  }
};
