export const getEnv = () => {
  const { hostname } = window.location;

  if (hostname.includes('fabfitfun')) {
    return {
      name: 'prod',
      REACT_APP_CUSTOMER_SERVICE_URL: 'https://support.fabfitfun.com/en',
      REACT_APP_API: 'https://homepage-api.fabfitfun.com',
      REACT_APP_QUESTIONNAIRE_API: 'https://questionnaire.fabfitfun.com',
      REACT_APP_SITE_URL: 'https://www.fabfitfun.com',
    };
  }

  if (hostname.includes('fff-staging')) {
    return {
      name: 'staging',
      REACT_APP_CUSTOMER_SERVICE_URL: 'https://support.fabfitfun.com/en',
      REACT_APP_API: 'https://homepage-api.fff-staging.com',
      REACT_APP_QUESTIONNAIRE_API: 'https://questionnaire.fff-staging.com',
      REACT_APP_SITE_URL: 'https://fff-dev.com',
    };
  }

  if (hostname.includes('fff-qa')) {
    return {
      name: 'qa',
      REACT_APP_CUSTOMER_SERVICE_URL: 'https://support.fabfitfun.com/en',
      REACT_APP_API: 'https://homepage-api.fff-qa.com',
      REACT_APP_QUESTIONNAIRE_API: 'https://questionnaire.fff-qa.com',
      REACT_APP_SITE_URL: 'https://fff-qa.com',
    };
  }
  return {
    name: 'dev',
    REACT_APP_CUSTOMER_SERVICE_URL: 'https://support.fabfitfun.com/en',
    REACT_APP_API: 'https://homepage-api.fff-dev.com',
    REACT_APP_QUESTIONNAIRE_API: 'https://questionnaire.fff-dev.com',
    REACT_APP_SITE_URL: 'https://fff-dev.com',
  };
};
