import { string, bool, func } from 'prop-types';
import BoxImage from './BoxImage';
import BoxTextBodyLinks from './BoxTextBodyLinks';
import StatusIcon from './StatusIcon';

const Status = ({
  imageURL = '',
  name = '',
  id,
  title = '',
  body = '',
  trackingUrl = '',
  showReviewButton = false,
  hasFailed = false,
  isMobile = false,
  wizmoMode,
  trackElements = () => null,
}) => (
  <div className="status-container">
    <BoxImage imageURL={imageURL} showReviewButton={showReviewButton} isMobile={isMobile} />
    <div className="status">
      <h3>
        {!wizmoMode && <span>{`${hasFailed || !name ? 'BOX ' : name} STATUS`}</span>}
        {title}
      </h3>
      <p className="status-container__body">
        {wizmoMode && <StatusIcon type={id} />}
        {body}
      </p>
      <BoxTextBodyLinks
        boxStatusId={id}
        trackingUrl={trackingUrl}
        title={title}
        body={body}
        trackElements={trackElements}
      />
    </div>
  </div>
);

Status.propTypes = {
  imageURL: string,
  name: string,
  title: string,
  body: string,
  trackingUrl: string,
  showReviewButton: bool,
  hasFailed: bool,
  isMobile: bool,
  trackElements: func,
  wizmoMode: bool,
  id: string,
};

export default Status;
