const getBrowserType = (): string => {
  const userAgent = window.navigator.userAgent;

  if (/opr/i.test(userAgent)) {
    return 'Opera';
  } else if (/edg/i.test(userAgent)) {
    return 'Edge';
  } else if (/chrome|crios|crmo/i.test(userAgent)) {
    return 'Chrome';
  } else if (/firefox|iceweasel|fxios/i.test(userAgent)) {
    return 'Firefox';
  } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo/i.test(userAgent)) {
    return 'Safari';
  } else if (/trident|msie/i.test(userAgent)) {
    return 'Internet Explorer';
  } else {
    return 'Unknown';
  }
};

export default getBrowserType;
