import className from 'classnames';
import UserWay from '../UserWay';
import FacebookIcon from '../images/fff-facebook.svg';
import InstagramIcon from '../images/fff-instagram.svg';
import PinterestIcon from '../images/fff-pinterest.svg';
import TiktokIcon from '../images/fff-tiktok.svg';
import TwitterIcon from '../images/fff-twitter.svg';
import YoutubeIcon from '../images/fff-youtube.svg';
import './SMFooter.css';
import '../Footer.css';
import type { ReactNode } from 'react';

const iconImages: Record<string, string> = {
  InstagramIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  YoutubeIcon,
  TiktokIcon,
};

interface SMFooterProps {
  socialMedias: {
    icons: {
      id: number | string;
      route: string;
      img: string;
      icon?: ReactNode;
    }[];
  };
  isTablet?: boolean;
  isMobile: boolean;
  isLogin: boolean;
}

export const SMFooter = ({ socialMedias, isTablet, isMobile, isLogin }: SMFooterProps) => {
  const { icons } = socialMedias;
  const getTabletStyles = className({
    'fff-shared-footer-top-section-col-logout': isTablet && isLogin,
  });

  const mappedIcons = icons.map(({ id, route, img, icon }) => (
    <a key={id} href={route} data-testid="sm-footer-icon">
      {icon || <img src={iconImages[img]} className="fff-shared-footer-sm-icons" alt="social" />}
    </a>
  ));

  const wrapperStyles = className(
    'fff-shared-footer-sm-container fff-shared-footer-top-section-col',
    getTabletStyles,
  );

  return (
    <div className={wrapperStyles} data-testid="sm-footer">
      <div>
        {isTablet ? (
          mappedIcons
        ) : (
          <div>
            {mappedIcons}
            {!isMobile && <UserWay />}
          </div>
        )}
      </div>
    </div>
  );
};
