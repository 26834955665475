import { useEffect, useRef, useState, type FunctionComponent, type ReactNode } from 'react';
import cx from 'classnames';
import useToggle from '../../hooks/useToggle';
import './CollapsiblePanel.css';

type CollapsiblePanelProps = {
  initialValue: boolean;
  title: string;
  screenMode: string;
  children: ReactNode;
};

const CollapsiblePanel: FunctionComponent<CollapsiblePanelProps> = ({
  initialValue = true,
  title,
  screenMode = 'mobile',
  children,
}) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [isOpen, setOpen, , handleToggle] = useToggle(initialValue);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = screenMode.indexOf('desktop') !== -1;

  useEffect(() => {
    if (isDesktop) {
      setHeight(undefined);
      setOpen();
    } else {
      setHeight(bodyRef.current?.clientHeight);
    }
  }, [children, screenMode, isDesktop]);

  const handleOpen = () => {
    if (!isDesktop) {
      handleToggle();
    }
  };

  return (
    <div className={cx('collapsible-panel', screenMode, { collapsed: !isOpen })}>
      {title && (
        <div
          className="collapsible-panel__header"
          role="button"
          tabIndex={-1}
          onClick={handleOpen}
          onKeyPress={handleOpen}
        >
          <h4>{title}</h4>
        </div>
      )}
      <div
        className="collapsible-panel__body"
        style={{ maxHeight: isOpen ? height || undefined : 0 }}
        ref={bodyRef}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsiblePanel;
