import { useCallback, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import GlobalCart from '@fff-web/fff-cart';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import { filterCommunityItems } from '../../utils/filters';
import UserProfile from '../UserProfile';
import MenuItem from './MenuItem';
import './NavigationMenu.css';

const logged = 'loginState';
const logout = 'logoutState';

const NavigationMenu = ({
  routes = { loginState: [], logoutState: [], userProfile: [] },
  isLoggedIn = false,
  isVisible = false,
  isDesktop = false,
  userInfo = {},
  stickyCart = false,
  newMemberState,
  onNewMemberStateFetched,
}) => {
  const { mainProps, env, membershipInfo } = useContext(GlobalNavInternalContext);
  const navRef = useRef();

  const setNavHeight = useCallback(() => {
    if (navRef.current && !isDesktop) {
      navRef.current.style.height = `${window.innerHeight - navRef.current.offsetTop}px`;
    }
    if (navRef.current && isDesktop) {
      navRef.current.style.height = null;
    }
  }, [isDesktop]);

  useEffect(() => {
    window.addEventListener('resize', setNavHeight);

    return () => window.removeEventListener('resize', setNavHeight);
  }, [setNavHeight]);

  useEffect(() => {
    setNavHeight();
  }, [isVisible, isDesktop, setNavHeight]);

  return (
    <nav
      ref={navRef}
      className={classNames('navigation-bar', {
        'is-visible': isVisible,
        'is-logout__nav': !isLoggedIn,
      })}
    >
      {!isDesktop && isLoggedIn && (
        <ul>
          <UserProfile isDesktop={isDesktop} routes={routes.userProfile} userInfo={userInfo} />
        </ul>
      )}
      <ul>
        {routes[isLoggedIn ? logged : logout]
          .filter((item) =>
            filterCommunityItems(item, membershipInfo?.membership_state, membershipInfo?.flags),
          )
          .map((item) => (
            <MenuItem key={item.id} item={item} isDesktop={isDesktop} />
          ))}
      </ul>
      {isDesktop && isLoggedIn && (
        <div className="user-container">
          <UserProfile
            isDesktop={isDesktop}
            routes={routes.userProfile}
            userInfo={userInfo}
            membershipInfo={membershipInfo}
          />
          <div
            className={classNames('shopping-cart', {
              'sticky-cart': stickyCart,
            })}
          >
            {!mainProps.hideCart && (
              <GlobalCart
                {...mainProps}
                env={env.name}
                newMemberState={newMemberState}
                onNewMemberStateFetched={onNewMemberStateFetched}
              />
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

NavigationMenu.propTypes = {
  isLoggedIn: bool,
  isVisible: bool,
  isDesktop: bool,
  routes: shape({
    loginState: arrayOf(shape()),
    logoutState: arrayOf(shape()),
    userProfile: arrayOf(shape()),
  }),
  userInfo: shape({
    firstName: string,
    lastName: string,
    role: string,
    subscriptionState: string,
    subscriptionType: string,
    avatar: string,
  }),
  stickyCart: bool,
  newMemberState: shape({
    isLoading: bool,
    memberState: string,
  }),
  onNewMemberStateFetched: func,
};

export default NavigationMenu;
