import type { CSSProperties, FunctionComponent } from 'react';
import classNames from 'classnames';
import * as styles from './button.module.css';

type ButtonProps = {
  theme?: string;
  text: string;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
};

const Button: FunctionComponent<ButtonProps> = ({
  theme,
  text,
  onClick,
  style,
  className,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={classNames(styles.button, styles[theme || ''], className)}
      style={style}
    >
      {text}
    </button>
  );
};

export default Button;
