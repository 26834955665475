import OnboardingModal, {
  OnboardingModalACTIONS,
  OnboardingModalEVENTS,
  OnboardingModalORIGIN,
  OnboardingModalSTATUS,
} from './OnboardingModal';

export {
  OnboardingModalACTIONS,
  OnboardingModalEVENTS,
  OnboardingModalORIGIN,
  OnboardingModalSTATUS,
};
export default OnboardingModal;
