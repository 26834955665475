import { createContext, useContext, type PropsWithChildren } from 'react';
import get from 'lodash/get';
import { HOSTNAMES } from '../services/utils/env';
import { getPage, PAGE } from '../services/utils/page';

type InternalContextValue = {
  env: string | null;
  hostname: string | null;
  page: string | null;
  isShopPage: boolean | null;
  isEditPage: boolean | null;
  isAddOnsPage: boolean | null;
  isBoostsPage: boolean | null;
  isNewMemberBoostsPage: boolean | null;
  isNewMemberCustomize: boolean | null;
};

const InternalContext = createContext<InternalContextValue | undefined>(undefined);

const InternalContextProvider = ({ env, children }: PropsWithChildren<{ env: string }>) => (
  <InternalContext.Provider
    // TODO: Add useMemo here

    value={{
      env,
      hostname: get(HOSTNAMES, env),
      page: getPage(),
      isShopPage: getPage() === PAGE.shop,
      isEditPage: getPage() === PAGE.edit,
      isAddOnsPage: getPage() === PAGE['add-ons'],
      isBoostsPage: getPage() === PAGE.boosts,
      isNewMemberBoostsPage: getPage() === PAGE['new-member-boosts'],
      isNewMemberCustomize: getPage() === PAGE['new-member-customize'],
    }}
  >
    {children}
  </InternalContext.Provider>
);

export const useInternalContext = () => {
  const context = useContext(InternalContext);
  if (!context) {
    throw new Error('useInternalContext must be used within a InternalContextProvider');
  }
  return context;
};

export default InternalContextProvider;
