import isBrowser from './isBrowser';

const PROD = 'prod';
const STAGING = 'staging';
const QA = 'qa';
const DEV = 'dev';
//const LOAD = 'load';

function getEnv() {
  const { hostname } = isBrowser() ? window.location : { hostname: 'fabfitfun.com' };
  if (hostname.includes('fabfitfun')) {
    return PROD;
  }
  if (hostname.includes('fff-staging')) {
    return STAGING;
  }
  if (hostname.includes('fff-qa')) {
    return QA;
  }
  return DEV;
}

function getHomepageHost() {
  const env = getEnv();
  switch (env) {
    case PROD:
      return 'fabfitfun.com';
    case STAGING:
      return 'fff-staging.com';
    case QA:
      return 'fff-qa.com';
    default:
      return 'fff-dev.com';
  }
}

function getLDClientId() {
  const env = getEnv();
  switch (env) {
    case PROD:
      return '5f90aeab892e740961596bba';
    case STAGING:
      return '5f90b058892e740961596c0a';
    case QA:
      return '5f90b04bd1a56a0983a8cef8';
    default:
      return '5f90b008892e740961596c05';
  }
}

function getHostName() {
  const env = getEnv();
  switch (env) {
    case PROD:
      return 'fabfitfun';
    case STAGING:
      return 'fff-staging';
    case QA:
      return 'fff-qa';
    default:
      return 'fff-dev';
  }
}

function getPactSafeVars() {
  const env = getEnv();
  if (env === PROD) {
    return {
      pactSafeGTBGroup: '16492',
      pactSafeSGTFGroup: '16494',
      pactSafeEmailOptInGroup: '16495',
      pactSafeReactivateMyMembershipGroup: '16493',
      pactSafeSid: 'f5cccf06-b9d2-4d67-a249-32d6e0182582',
    };
  }
  return {
    pactSafeGTBGroup: '16266',
    pactSafeSGTFGroup: '16267',
    pactSafeEmailOptInGroup: '16269',
    pactSafeReactivateMyMembershipGroup: '16270',
    pactSafeSid: '72097bf3-0831-44ad-b21b-744afe8e4a3d',
  };
}

function getZendesk() {
  const env = getEnv();
  switch (env) {
    case PROD:
      return '6f223498-a3ca-4a4e-bda0-678a2af96c01';
    case STAGING:
      return '6f223498-a3ca-4a4e-bda0-678a2af96c01';
    case QA:
      return '6f223498-a3ca-4a4e-bda0-678a2af96c01';
    default:
      return '6f223498-a3ca-4a4e-bda0-678a2af96c01';
  }
}

export { getPactSafeVars, getHostName, getLDClientId, getZendesk, getHomepageHost };
