import request from './request';

/**
 * Fetch credits for the cart.
 * @param {string} env - The environment to fetch credits for.
 * @returns {Promise<object>} - The credits for the cart.
 */
export async function fetchCredits(env) {
  const endpoint = 'api/1/credits/campaigns/default';
  // TODO, delete try useless on this file
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'GET', env);
    return response;
  } catch (err) {
    throw err;
  }
}

/**
 * Fetch invoice preview for the cart.
 * @param {string} env - The environment to fetch invoice preview for.
 * @param {string} cartId - The cart ID to fetch invoice preview for.
 * @returns {Promise<object>} - The invoice preview for the cart.
 */
export async function fetchInvoicePreview(env, cartId) {
  const requestUrl = `api/1/invoices/preview/carts/${cartId}`;
  return request(requestUrl, 'POST', env);
}
