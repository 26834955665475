import { PAGE, isPreviewingCampaign } from './page';

export const SALE_TYPES = {
  SEASON: 'SEASON',
  EDIT: 'EDIT',
  SHOP: 'SHOP',
};

export const CAMPAIGN_STATUS = {
  windowOne: 'WINDOW_ONE',
  windowTwo: 'WINDOW_TWO',
  ended: 'ENDED',
};

export const campaignHasEnded = (campaignStatus) => campaignStatus === CAMPAIGN_STATUS.ended;

export const campaignIsLive = (campaignStatus) =>
  campaignStatus === CAMPAIGN_STATUS.windowOne || campaignStatus === CAMPAIGN_STATUS.windowTwo;

export const CAMPAIGN_TYPE_LINKS = {
  SHOP: 'shop',
  SEASON: 'add-ons',
  EDIT: 'edit',
};

export const getIsOnOwnPage = (page, campaignType) => {
  return (
    (page === PAGE.shop && campaignType === SALE_TYPES.SHOP) ||
    ((page === PAGE['add-ons'] || page === PAGE.boosts) && campaignType === SALE_TYPES.SEASON) ||
    ((page === PAGE.edit || page === PAGE['new-member-boosts']) && campaignType === SALE_TYPES.EDIT)
  );
};

export const getCampaignType = (page) => {
  if (
    page === PAGE.edit ||
    page === PAGE['new-member-boosts'] ||
    page === PAGE['new-member-customize']
  )
    return SALE_TYPES.EDIT;
  if (page === PAGE['add-ons'] || page === PAGE.boosts) return SALE_TYPES.SEASON;
  return SALE_TYPES.SHOP;
};

export const getActiveCampaign = (
  seasonCampaign,
  editCampaign,
  isAddOnsPage,
  isBoostsPage,
  isEditPage,
) => {
  if (isPreviewingCampaign) {
    if (isAddOnsPage || isBoostsPage) return seasonCampaign;
    if (isEditPage) return editCampaign;
  }
  // hotfix for add-ons campaign being in pre status and edit campaign being live
  if (
    seasonCampaign?.campaignStatus === 'BEFORE_START' &&
    editCampaign?.campaignStatus === 'WINDOW_ONE'
  ) {
    return editCampaign;
  }
  // return active campaign based on campaignStatus
  if (seasonCampaign && !campaignHasEnded(seasonCampaign.campaignStatus)) return seasonCampaign;
  if (editCampaign && !campaignHasEnded(editCampaign.campaignStatus)) return editCampaign;
  return null;
};
