import { FFFStorage } from '@fff-web/fff-utilities';
import { getLocalStorage } from '../../utils/localStorageHelpers';

const UNAVAILABLE = 'UNAVAILABLE';
const NOT_SUBSCRIBED = 'NOT_SUBSCRIBED';

export const getInitialValueFromStorage = (key, defaultValue) => {
  const data = FFFStorage.getFromStorage({ key });

  if (data && data.data) {
    return { data: data.data, loading: false, fail: false };
  }

  return { data: defaultValue, loading: false, fail: false };
};

export const setInitialValueToStorage = (key, value) =>
  FFFStorage.setToStorage({
    key,
    value: { data: value },
  });

export const isUnavailableStatus = (status) => status && status.id === UNAVAILABLE;

export const isNotSubscribedStatus = (status, userInfo) =>
  (status && status.id === NOT_SUBSCRIBED) ||
  (userInfo && userInfo.user && userInfo.user.subscriptionType === 'TRIAL');

export const isExpired = (data, userInfo) =>
  (!data.fail &&
    Object.keys(data.data).length > 0 &&
    data.data.status &&
    data.data.status.id === 'EXPIRED') ||
  (userInfo &&
    userInfo.user &&
    (userInfo.user.subscriptionState === 'CANCELED' ||
      userInfo.user.subscriptionState === 'EXPIRED'));

export const isHomepageLoading = ({ myBox, webCards, seasonalTimelines }) =>
  myBox.loading || webCards.loading || seasonalTimelines.loading;

export const getInvitePopupExpirationFromStorage = (key) => {
  let invitePopupExpDate = getLocalStorage(key);
  if (invitePopupExpDate === null) {
    invitePopupExpDate = 0;
  }
  return Date.parse(invitePopupExpDate);
};
