/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { dismissAll, showErrorToast, showInfoToast, Toast } from '@fff-web/fff-ui-shared';
import { useInternalContext } from '../../../context/InternalContext';
import { fetchInvoicePreview } from '../../../services/apis/paymentRequests';
import { getIsOnOwnPage, SALE_TYPES } from '../../../services/utils/campaign';
import { clickProductHandler } from '../../../services/utils/cart-item';
import {
  getAllCreditValues,
  getCartSubtotal,
  getCartTotal,
  getShipping,
  getUnavailableProducts,
} from '../../../services/utils/cartCalculations';
import { COUNTRIES } from '../../../services/utils/constants';
import { cookie, FLASH_COOKIE, SHOP_COOKIE } from '../../../services/utils/cookie';
import {
  convertToDollars,
  formatCurrency,
  formatCurrencyInCents,
} from '../../../services/utils/currency';
import track, { segmentEvents } from '../../../services/utils/segment';
import {
  getCartItemTrackingData,
  getCartTrackingData,
} from '../../../services/utils/tracking-data';
import { VARIANT_TYPES } from '../../../services/utils/types';
import { trackUpdateCart, updateCartHandler } from '../../../services/utils/update-cart';
import BANNER_TYPE from '../../../utils/publicShop';
import CartFooter from '../CartFooter/CartFooter';
import CartItem from '../CartItem/CartItem';
import CartTotal from '../CartTotal/CartTotal';
import EmptyCart from '../EmptyCart/EmptyCart';
import SponsoredProductModal from '../SponsoredProductModal/SponsoredProductModal';
import '../../../global-css/index.css';
import { useDataContext } from '../../../context/DataContext';
import Banner from '../Banner/Banner';
import ManageYourBoxBanner from '../ManageYourBoxBanner/ManageYourBoxBanner';
import * as styles from './Cart.module.css';

const FLASH_SALES = <span style={{ color: '#FB6A66' }}>Flash Sales</span>;
const THE_SHOP = <span style={{ color: '#FB6A66' }}>The Shop</span>;
const FLASH_TITLE = ['Welcome to the ', FLASH_SALES, ' Cart'];
const SHOP_TITLE = ['Welcome to ', THE_SHOP, ' Cart'];
const FLASH_SUBTITLE =
  'Add items to your cart during the flash sale to have those items charged to your payment method on file at the close of the sale. No checkout necessary.';
const SHOP_SUBTITLE = "Add items from The Shop at any time and checkout when you're ready.";

const CART_TYPE = {
  EDIT: 'edit',
  SEASON: 'add-ons',
  SHOP: 'shop',
};

const TOAST_ID = 'cart-toast';

const MINIMUM_TOTAL_FOR_FREE_SHIPPING_PUBLIC_SHOPPING = 4900;
// TODO: Make work the remove and add items with public shopping.
// TODO: Please convert this to typescript.ƒ
const Cart = ({
  setCartData,
  cartData,
  campaignData,
  closeCart,
  itemsContainerRef,
  tab,
  receiveUpdateCart,
  receiveCartProducts,
  isPublicShopping,
  seasonalPlanPrice,
  selfCheckoutFF,
  flashSaleDiscountCouponsFF,
}) => {
  const { env, page } = useInternalContext();
  const { userData, initialCredits, onClickProduct, shoppingCreditFF } = useDataContext();
  const { type: campaignType, sponsoredThreshold } = campaignData;
  const { cartVariants, cartDetails, itemsCount, cartId, customizeCampaignDetails } = cartData;
  const { variantCadenceTypes } = cartDetails;
  const isShopCampaign = campaignType === SALE_TYPES.SHOP;

  const userCountry = userData.shippingAddress?.country;
  const cartType = _.get(CART_TYPE, campaignType);
  const displayForCanada = userCountry === COUNTRIES.ca && cartType !== CART_TYPE.SEASON;
  const displayTax = userCountry === COUNTRIES.uk || displayForCanada;

  // page logic
  const isOnOwnPage = getIsOnOwnPage(page, campaignType);

  const hasSponsoredProduct = !!_.find(cartVariants, { sponsored: true });

  // logic to remove items from cart
  const [removeItemData, setRemoveItemData] = useState({});

  //sponsored product modal state
  const [nonSponsoredProductModalOpen, setNonSponsoredProductModalOpen] = useState(false);
  const [sponsoredProductModalOpen, setSponsoredProductModalOpen] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxError, setTaxError] = useState(false);

  // cart calculations
  const subtotal = useMemo(() => getCartSubtotal(cartVariants), [cartVariants]);

  const publicShopFreeShipping =
    isPublicShopping && subtotal > MINIMUM_TOTAL_FOR_FREE_SHIPPING_PUBLIC_SHOPPING;

  const { shippingAmount, additionalShippingAmount, remainingSpendToFreeShipping, showShipping } =
    useMemo(
      () => getShipping(cartVariants, cartDetails, subtotal, publicShopFreeShipping),
      [cartVariants, cartDetails],
    );

  const shippingTotal = useMemo(
    () => shippingAmount + additionalShippingAmount,
    [shippingAmount, additionalShippingAmount],
  );

  const {
    hasPurchaseCredit,
    hasSaleCredit,
    hasSocialCredit,
    remainingCredit,
    appliedPurchaseCredit,
    appliedSaleCredit,
    hasAppliedPurchaseCredit,
    hasAppliedSaleCredit,
    hasAppliedShoppingCredit,
    appliedShoppingCredit,
    hasShoppingCredit,
  } = useMemo(
    () =>
      getAllCreditValues(subtotal, initialCredits, campaignType, shoppingCreditFF, shippingTotal),
    [subtotal, initialCredits, campaignType, shoppingCreditFF, shippingTotal],
  );

  const total = useMemo(() => {
    return getCartTotal(
      subtotal,
      initialCredits,
      campaignType,
      taxAmount,
      shippingTotal,
      shoppingCreditFF,
    );
  }, [subtotal, initialCredits, campaignType, taxAmount, shippingTotal, shoppingCreditFF]);

  const cartTrackingData = useMemo(() => {
    if (!_.isEmpty(userData) && !_.isEmpty(campaignData)) {
      return getCartTrackingData(userData, campaignData, cartData);
    }
    return null;
  }, [userData, cartData, campaignData]);

  // display Welcome Toast
  useEffect(() => {
    if (isPublicShopping) return;
    const isShopCookiePresent = cookie.get(SHOP_COOKIE);
    const isFlashCookiePresent = cookie.get(FLASH_COOKIE);
    dismissAll(TOAST_ID);
    if (!isFlashCookiePresent && campaignType !== SALE_TYPES.SHOP) {
      showInfoToast(FLASH_TITLE, FLASH_SUBTITLE, { containerId: TOAST_ID });
      cookie.set(FLASH_COOKIE, true, 365);
    }
    if (!isShopCookiePresent && campaignType === SALE_TYPES.SHOP) {
      showInfoToast(SHOP_TITLE, SHOP_SUBTITLE, { containerId: TOAST_ID });
      cookie.set(SHOP_COOKIE, true, 365);
    }
  }, [tab, isPublicShopping]);

  // display Shop Inventory error Toast
  useEffect(() => {
    // if Shop campaign && quantity no longer available
    if (isShopCampaign) {
      const noAvailableProducts = getUnavailableProducts(cartVariants);
      if (noAvailableProducts) {
        showErrorToast(
          'Update to Your Cart',
          'We’re sorry, one or more items in your cart are unavailable. Please edit your cart and try again.',
          { containerId: TOAST_ID },
        );
      }
    }
  }, [itemsCount]);

  // track cart viewed - we only want to track this once when the cart/user data loads
  useEffect(() => {
    if (!_.isEmpty(userData)) {
      const trackingData = getCartTrackingData(userData, campaignData, cartData, tab);
      track(segmentEvents.cartViewed, {
        ...trackingData,
        total: convertToDollars(total),
      });
    }
  }, [userData, tab]);

  useEffect(() => {
    const getTax = async () => {
      setTaxError(false);
      try {
        const response = await fetchInvoicePreview(env, cartId);
        const { tax } = response;
        if (tax) {
          setTaxAmount(tax / 100);
        }
      } catch (_) {
        setTaxError(true);
      }
    };

    if (displayTax && cartId) {
      setTaxAmount(0);
      getTax();
    }
  }, [cartId, cartVariants, displayTax]);

  const handleRemoveItem = (id, trackingData, sponsored, salePrice, cta, cadenceType) => {
    const newSubtotal = subtotal - salePrice * 100;
    if (newSubtotal < sponsoredThreshold && hasSponsoredProduct) {
      setNonSponsoredProductModalOpen(true);
      return setRemoveItemData({
        id,
        trackingData,
      });
    }
    if (sponsored) {
      setSponsoredProductModalOpen(true);
      return setRemoveItemData({
        id,
        trackingData,
      });
    }
    trackUpdateCart(trackingData, cta, 'REMOVE');
    forceCheck();
    return updateCartHandler(
      id,
      'REMOVE',
      campaignType,
      cartVariants,
      receiveUpdateCart,
      receiveCartProducts,
      env,
      setCartData,
      cadenceType,
      isPublicShopping,
    );
  };

  const handleAddItem = (id, trackingData, cta, cadenceType, itemType) => {
    trackUpdateCart(trackingData, cta, 'ADD');
    return updateCartHandler(
      id,
      'ADD',
      campaignType,
      cartVariants,
      receiveUpdateCart,
      receiveCartProducts,
      env,
      setCartData,
      cadenceType,
      isPublicShopping,
      itemType,
    );
  };

  const handleNonSponsoredModal = () => {
    const { id, trackingData } = removeItemData;
    trackUpdateCart(trackingData, 'Yes, Remove', 'REMOVE');
    updateCartHandler(
      id,
      'REMOVE',
      campaignType,
      cartVariants,
      receiveUpdateCart,
      receiveCartProducts,
      env,
      setCartData,
    );
    //track removal of sponsored items in segment
    _.forEach(cartVariants, (variant, index) => {
      if (variant.sponsored) {
        const sponsoredTrackingData = getCartItemTrackingData(
          variant,
          index,
          userData,
          campaignData,
          formatCurrencyInCents(total),
        );
        track(segmentEvents.productRemoved, {
          ...sponsoredTrackingData,
          cta_name: 'Yes, Remove',
        });
      }
    });
    setNonSponsoredProductModalOpen(false);
  };

  const handleSponsoredModal = () => {
    const { id, trackingData } = removeItemData;
    trackUpdateCart(trackingData, 'Yes, Remove', 'REMOVE');
    updateCartHandler(
      id,
      'REMOVE',
      campaignType,
      cartVariants,
      receiveUpdateCart,
      receiveCartProducts,
      env,
      setCartData,
    );
    setSponsoredProductModalOpen(false);
  };

  const handleClickProduct = (variant) => {
    clickProductHandler(variant, campaignType, onClickProduct, closeCart, env);
  };
  let showBannerType = null;

  if (
    isPublicShopping &&
    cartVariants.length &&
    cartVariants.some((variant) => variant.variantType === VARIANT_TYPES.intro)
  ) {
    showBannerType = BANNER_TYPE.memberPricingUnlocked;
  } else if (cartVariants.some((variant) => variant.variantType === VARIANT_TYPES.prospectSingle)) {
    showBannerType = BANNER_TYPE.unlockMemberPricing;
  }

  const showManageYourBoxBanner = campaignType === SALE_TYPES.EDIT && !!customizeCampaignDetails;
  return (
    <div className="global-cart-container">
      {!cartVariants.length && !showManageYourBoxBanner ? (
        <EmptyCart
          campaignType={campaignType}
          closeCart={closeCart}
          isOnOwnPage={isOnOwnPage}
          isPublicShopping={isPublicShopping}
        />
      ) : (
        <>
          {(hasPurchaseCredit || hasSaleCredit || hasSocialCredit || hasShoppingCredit) && (
            <div className={styles.remainingCredit}>
              <span>{`Credit Remaining: $${remainingCredit}`}</span>
            </div>
          )}
          {showBannerType && seasonalPlanPrice && (
            <Banner
              type={showBannerType}
              cartVariants={cartVariants}
              receiveUpdateCart={receiveUpdateCart}
              receiveCartProducts={receiveCartProducts}
              env={env}
              setCartData={setCartData}
              isPublicShopping={isPublicShopping}
              seasonalPlanPrice={seasonalPlanPrice}
            />
          )}
          <div className={styles.itemsContainer} ref={itemsContainerRef}>
            {showManageYourBoxBanner && (
              <ManageYourBoxBanner
                customizeCampaignDetails={customizeCampaignDetails}
                variantsCount={cartVariants?.length}
              />
            )}
            {_.map(cartVariants, (variant, index) => {
              const {
                id,
                quantity,
                cartLimit,
                inventoryLimit,
                imageUrl,
                salePrice,
                price,
                soldOut,
                shareUrl,
                cartDisplayName,
                sponsored,
                variantType,
                premiumProduct,
              } = variant;
              let displayPrice;
              let strikethroughPrice;
              const cadenceType = variantCadenceTypes[id] || null;
              if (variantType === VARIANT_TYPES.prospectSingle) {
                displayPrice = formatCurrency(price * quantity);
              } else {
                displayPrice = formatCurrency(salePrice * quantity);
                strikethroughPrice = formatCurrency(price * quantity);
              }

              const trackingData = getCartItemTrackingData(
                variant,
                index,
                userData,
                campaignData,
                formatCurrencyInCents(total),
                cadenceType,
              );
              return (
                <LazyLoad key={id} overflow height={90} offset={90}>
                  <CartItem
                    id={id}
                    quantity={quantity}
                    imageUrl={imageUrl}
                    onAddItem={(cta) =>
                      handleAddItem(id, trackingData, cta, cadenceType, variantType)
                    }
                    displayPrice={displayPrice}
                    strikethroughPrice={strikethroughPrice}
                    cartDisplayName={cartDisplayName}
                    shareUrl={shareUrl}
                    soldOut={soldOut}
                    cartLimit={cartLimit}
                    inventoryLimit={inventoryLimit}
                    onRemoveItem={(cta) =>
                      handleRemoveItem(id, trackingData, sponsored, salePrice, cta, cadenceType)
                    }
                    onClickProduct={() => {
                      handleClickProduct(variant);
                    }}
                    cartType={cartType}
                    sponsored={sponsored}
                    cadenceType={cadenceType}
                    campaignType={campaignType}
                    isAdditionalChoice={variantType === VARIANT_TYPES.customizeAdditionalChoice}
                    isLuxeChoice={premiumProduct}
                  />
                </LazyLoad>
              );
            })}
          </div>
          <CartTotal
            shippingAmount={shippingAmount}
            additionalShippingAmount={additionalShippingAmount}
            remainingSpendToFreeShipping={remainingSpendToFreeShipping}
            showShipping={showShipping || showManageYourBoxBanner}
            appliedPurchaseCredit={appliedPurchaseCredit}
            appliedSaleCredit={appliedSaleCredit}
            hasAppliedPurchaseCredit={hasAppliedPurchaseCredit}
            hasAppliedSaleCredit={hasAppliedSaleCredit}
            trackingData={{
              ...cartTrackingData,
              subtotal: formatCurrencyInCents(subtotal),
            }}
            campaignType={campaignType}
            taxAmount={taxAmount}
            total={total}
            taxLabel={cartDetails.taxLabel}
            displayTax={displayTax}
            userCountry={userCountry}
            taxError={taxError}
            appliedShoppingCredit={appliedShoppingCredit}
            hasAppliedShoppingCredit={hasAppliedShoppingCredit}
            isPublicShopping={isPublicShopping}
            selfCheckoutFF={selfCheckoutFF}
            flashSaleDiscountCouponsFF={flashSaleDiscountCouponsFF}
            subtotal={subtotal}
            minimumTotalFreeShippingPublicShop={MINIMUM_TOTAL_FOR_FREE_SHIPPING_PUBLIC_SHOPPING}
            tab={tab}
          />
          {campaignType !== SALE_TYPES.SHOP && (
            <CartFooter cartText={cartDetails && cartDetails.cartText} />
          )}
        </>
      )}
      {
        // modal to display when removing a non-sponsored item that will lower the cart total below the sponsored threshold
        nonSponsoredProductModalOpen && (
          <SponsoredProductModal
            closeModal={() => setNonSponsoredProductModalOpen(false)}
            handleClickContinue={handleNonSponsoredModal}
            modalType="nonSponsored"
          />
        )
      }
      {
        // modal to display when removing a sponsored item
        sponsoredProductModalOpen && (
          <SponsoredProductModal
            closeModal={() => setSponsoredProductModalOpen(false)}
            handleClickContinue={handleSponsoredModal}
            modalType="sponsored"
          />
        )
      }
      <Toast isCart containerId={TOAST_ID} />
    </div>
  );
};

export default Cart;
