import { useState } from 'react';
import { string, bool } from 'prop-types';

import DefaultBox from './assets/placeholder-box@2x.png';
import ReviewButton from './ReviewButton';

const BoxImage = ({ imageURL = null, showReviewButton = false, isMobile = false }) =>
  showReviewButton ? (
    <div className="review-container">
      <ImgTag imageURL={imageURL} isMobile={isMobile} />
      <ReviewButton />
    </div>
  ) : (
    <ImgTag imageURL={imageURL} isMobile={isMobile} />
  );

BoxImage.propTypes = {
  imageURL: string,
  showReviewButton: bool,
  isMobile: bool,
};

const ImgTag = ({ imageURL = null, isMobile = false }) => {
  const [isImageFailing, setIsImageFailing] = useState(false);
  const imgClassName = `challenge-mybox-image${!isMobile ? '-desktop' : ''}`;
  return imageURL && !isImageFailing ? (
    <img
      src={imageURL}
      alt="Current box"
      className={imgClassName}
      onError={() => setIsImageFailing(true)}
    />
  ) : (
    <img src={DefaultBox} alt="Current box" className={imgClassName} />
  );
};

ImgTag.propTypes = {
  imageURL: string,
  isMobile: bool,
};

export default BoxImage;
