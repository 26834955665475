import { DEV, PROD, QA, STAGING } from '../services/constants/environments';

/**
 * Get the LD client ID based on the environment
 * @param {string} env - The environment to get the LD client ID for.
 * @returns {string} - The LD client ID.
 */
export function getLDClientId(env) {
  switch (env) {
    case PROD:
      return '5f90aeab892e740961596bba';
    case STAGING:
      return '5f90b058892e740961596c0a';
    case QA:
      return '5f90b04bd1a56a0983a8cef8';
    case DEV:
      return '5f90b008892e740961596c05';
    //DEFAULT RETURNS PROD
    default:
      return '5f90aeab892e740961596bba';
  }
}
