import { ReferrerQueryString } from '@fff-web/fff-analytics';

const HOMEPAGE_EXPERIENCE = 'Homepage';

const INVITE_FRIEND_PAGE_URL = `${process.env.REACT_APP_SITE_URL}/invite-friends?referrer=${ReferrerQueryString.homepage}`;

const UPGRADE_PAGE_URL = `${process.env.REACT_APP_SITE_URL}/upgrade-confirmation?referrer=${ReferrerQueryString.homepage}`;

const REACTIVATE_MY_MEMBERSHIP_PAGE_URL = `${process.env.REACT_APP_SITE_URL}/reactivate-my-membership?referrer=${ReferrerQueryString.homepage}`;

const REACTIVATE_TODAY_PAGE_URL = `${process.env.REACT_APP_SITE_URL}/reactivate-today`;
const ACTIVATE_MEMBERSHIP_URL = `${process.env.REACT_APP_SITE_URL}/activate-membership`;

const TRACK_NAMES = {
  inviteStarted: 'Invite Started',
  upgradeStarted: 'Upgrade Started',
  reactivationStarted: 'Reactivation Started',
};

const MEMBER_TYPES = {
  expired: 'Expired Member',
  canceled: 'Canceled Member',
};

const INVITE_TYPE = 'SGTF Trial';

export {
  INVITE_FRIEND_PAGE_URL,
  UPGRADE_PAGE_URL,
  REACTIVATE_MY_MEMBERSHIP_PAGE_URL,
  TRACK_NAMES,
  INVITE_TYPE,
  HOMEPAGE_EXPERIENCE,
  MEMBER_TYPES,
  REACTIVATE_TODAY_PAGE_URL,
  ACTIVATE_MEMBERSHIP_URL,
};
