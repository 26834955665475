import { IUserContext, IUserInfo } from '../../interfaces';

export const defaultUserInfo: IUserInfo = {
  avatar: '',
  lives: [],
  loggedIn: false,
  seasonCode: '',
  user: {
    userId: null,
    firstName: '',
    lastName: '',
    role: '',
    shippingCountry: '',
    shippingState: '',
    shippingZipCode: '',
    shippingCity: '',
    subscriptionEndDate: 0,
    subscriptionStartDate: 0,
    subscriptionState: '',
    subscriptionType: '',
    email: '',
    planCode: '',
  },
};

const defaultContext: IUserContext = {
  userInfo: defaultUserInfo,
  clearStorage: () => {},
  invalidateUserCache: () => {},
};

export default defaultContext;
