import classNames from 'classnames';
import { bool, shape, string } from 'prop-types';
import { getMembershipLabelGlobalNav } from '@fff-web/fff-utilities';
import { toLowerCase } from '../../helpers/string';
import Avatar from '../Avatar';

const UserProfileItem = ({
  userInfo: { firstName, lastName, avatar, role, planCode } = {
    firstName: '',
    lastName: '',
    role: '',
    planCode: '',
    avatar: null,
  },
  membershipInfo,
  isDesktop = false,
}) => (
  <div className="user-profile__item">
    {!isDesktop && <Avatar image={avatar} />}
    <div className="user-profile__item__detail">
      <div className="user-profile__item__detail__name">{`${firstName} ${lastName}`}</div>
      {membershipInfo?.membership_state && (
        <div>
          <span
            className={classNames(
              'subscription-state',
              toLowerCase(membershipInfo?.membership_state),
            )}
          >
            {membershipInfo?.membership_state.toUpperCase()}
          </span>
        </div>
      )}
      <div className="user-profile__item__detail__subscription">
        <span>{getMembershipLabelGlobalNav({ membershipInfo, role, planCode })}</span>
      </div>
    </div>
  </div>
);

UserProfileItem.propTypes = {
  userInfo: shape({
    firstName: string,
    lastName: string,
    role: string,
    avatar: string,
    planCode: string,
  }),
  isDesktop: bool,
  membershipInfo: shape({
    membership_state: string,
  }),
};

export default UserProfileItem;
