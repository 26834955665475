import type { FunctionComponent } from 'react';
import { getCampaign, TABS } from '../../../utils/campaign';
import Tab from './Tab';
import * as styles from './Tabs.module.css';

type TabsProps = {
  activeCampaign: {
    type: string;
  };
  activeTab: number;
  setActiveTab: (tab: number) => void;
  saleItemsCount: number;
  shopItemsCount: number;
  isNewMemberOnboarding: boolean;
  showSecondaryTab: boolean;
};

const Tabs: FunctionComponent<TabsProps> = ({
  activeCampaign,
  activeTab,
  setActiveTab,
  saleItemsCount,
  shopItemsCount,
  isNewMemberOnboarding,
  showSecondaryTab,
}) => {
  const campaign = getCampaign(activeCampaign, isNewMemberOnboarding);
  const secondCampaign = TABS.SHOP;

  return (
    <div className={styles.container}>
      <Tab
        // TODO change this from spreading to normal props

        {...campaign}
        active={activeTab === 0}
        quantity={campaign.id === TABS.UPCOMING.id ? 0 : saleItemsCount}
        onClick={() => setActiveTab(0)}
        onlyTab={!showSecondaryTab}
      />
      {showSecondaryTab && (
        <Tab
          {...secondCampaign}
          active={activeTab === 1}
          quantity={shopItemsCount}
          onClick={() => setActiveTab(1)}
        />
      )}
    </div>
  );
};

export default Tabs;
