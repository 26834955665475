import { getEnv } from '../../utils/environment';

const account = {
  label: 'MY ACCOUNT',
  href: `${getEnv().REACT_APP_SITE_URL}/my-account`,
};
const myBox = {
  label: 'MY BOX',
  href: `${getEnv().REACT_APP_SITE_URL}/my-box`,
};
const orderHistory = {
  label: 'INVOICE HISTORY',
  href: `${getEnv().REACT_APP_SITE_URL}/my-account/invoice-history`,
};

export const activateMembership = {
  label: 'ACTIVATE MEMBERSHIP',
  href: '/activate-membership',
};

export const links = [account, myBox, orderHistory];

export const notSubscribedLinks = [myBox, orderHistory];
