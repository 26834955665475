export const PUBLIC_SHOP_BANNER = {
  subText: 'on Orders $49+ in Contiguous US',
  published: false,
  id: 0,
  title: 'Free Shipping',
  createdAt: '',
  lastEditedAt: '',
  lastEditedBy: '',
  hideBanner: false,
};
