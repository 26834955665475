import warnMessages from './warnMessages';

const trackNameIsRequired = (): void => console.warn(warnMessages.TRACK_NAME_IS_REQUIRED);
const analyticsIsNotInitialized = (): void =>
  console.warn(warnMessages.ANALYTICS_IS_NOT_INITIALIZED);
const elementParameterIsRequired = (): void =>
  console.warn(warnMessages.ELEMENT_PARAMETER_IS_REQUIRED);
const ctaNameIsRequired = (): void => console.warn(warnMessages.CTA_NAME_IS_REQUIRED);

export {
  trackNameIsRequired,
  analyticsIsNotInitialized,
  elementParameterIsRequired,
  ctaNameIsRequired,
};
