// extracted by mini-css-extract-plugin
export var button = "LoadingCart_button__urcSh";
export var footer = "LoadingCart_footer__4-1Ws";
export var image = "LoadingCart_image__Rc54W";
export var price = "LoadingCart_price__8-OBf";
export var progress = "LoadingCart_progress__jPDSZ";
export var row = "LoadingCart_row__J1Gi2";
export var tab = "LoadingCart_tab__j3yVP";
export var tabContainer = "LoadingCart_tabContainer__xogPJ";
export var title = "LoadingCart_title__6Yuio";
export var titlesContainer = "LoadingCart_titlesContainer__9Inbm";
export var total = "LoadingCart_total__2I3+m";
export var totalContiner = "LoadingCart_totalContiner__pb6pm";