import { IGetFromStorage, IRemoveFromStorage, ISetToStorage } from '../../interfaces';
import isBrowser from './isBrowser';

export const removeFromStorage = ({ key }: IRemoveFromStorage): void => {
  if (isBrowser()) {
    sessionStorage.removeItem(`FFF_${key}`);
  }
};

export const getFromStorage = ({ key, defaultItem = null }: IGetFromStorage) => {
  if (!isBrowser()) return defaultItem;

  const stgItem = sessionStorage.getItem(`FFF_${key}`);
  if (!stgItem) return defaultItem;

  try {
    const { createAt, durationInMs, data } = JSON.parse(stgItem);
    const currentTime = Date.now();
    const isExpired = durationInMs !== 0 && createAt + durationInMs < currentTime;
    if (!durationInMs || (createAt && durationInMs && data && !isExpired)) {
      return data;
    }
    removeFromStorage({ key });
  } catch (_) {
    console.error('Error when deserializing JSON navigation');
  }

  return defaultItem;
};

export const setToStorage = ({ key, value, durationInMs = 0 }: ISetToStorage): void => {
  const itemWithExpirationTime = {
    createAt: Date.now(),
    durationInMs,
    data: value,
  };
  const json = JSON.stringify(itemWithExpirationTime);
  if (isBrowser()) {
    sessionStorage.setItem(`FFF_${key}`, json);
  }
};

export const clearStorage = (): void => {
  if (isBrowser()) {
    sessionStorage.clear();
  }
};

export const clearFFFStorage = (): void => {
  if (isBrowser()) {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith('FFF_')) {
        sessionStorage.removeItem(key);
      }
    });
  }
};

export const SESSION_STORAGE_KEY = {
  userInfo: 'user-info-app',
};

export const SESSION_STORAGE_EVENT = 'sessionStorage';
