import type { FunctionComponent } from 'react';
import { ButtonLink } from '@fff-web/fff-ui-shared';
import * as styles from './ManageYourBoxBanner.module.css';

type ManageYourBoxBannerProps = {
  customizeCampaignDetails: {
    title: string;
    image: string;
  };
  variantsCount: number;
};

const ManageYourBoxBanner: FunctionComponent<ManageYourBoxBannerProps> = ({
  customizeCampaignDetails,
  variantsCount,
}) => {
  const boxName = customizeCampaignDetails?.title + ' Box';

  const onManageYourBoxClick = () => {
    window.location.href = '/my-subscriptions';
  };

  const boxImage = customizeCampaignDetails?.image;

  return (
    <>
      <div className={styles.boxContainer}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={boxImage} alt="" role="presentation" />
        </div>
        <div className={styles.nameAndLinkContainer}>
          <p className={styles.boxName}>{boxName}</p>
          <ButtonLink bold fontSize={12} onClick={onManageYourBoxClick} label="Manage your Box" />
        </div>
        <div className={styles.prepaidContainer}>
          <span className={styles.prepaid}>Prepaid</span>
        </div>
      </div>
      {variantsCount > 0 && (
        <div className={styles.disclaimer}>All items below will shop with your box. </div>
      )}
    </>
  );
};

export default ManageYourBoxBanner;
