export const SUBSCRIPTION_STATE = {
  expired: 'EXPIRED',
  active: 'ACTIVE',
};

export const SUBSCRIPTION_TYPE = {
  trial: 'TRIAL',
};

export const MENU_IDS = {
  MY_BOX: 'my-box',
  CUSTOMIZE: 'customize',
};

export const REACTIVATION_URL = '/reactivate-my-membership';

export const TRAIL_REACTIVATION_URL = '/activate-membership';

export const INVITE_ID_ROUTE = 'invite';

export const RR_ID_ROUTE = 'rate_and_review';

export const INVITE_FRIENDS_ID_ROUTE = 'invite_friends';

export const GUEST_PASS_LABEL = 'Guest pass';

export const VARIATIONS = {
  ON: 'on',
  OFF: 'off',
  CONTROL: 'control',
  VERSION_1: 'version_1',
};
