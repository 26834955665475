import { ReferrerQueryStringValues } from '../../interfaces';

export type OwnReferrerQueryString = {
  [key: string]: ReferrerQueryStringValues;
};

export const ReferrerQueryString: OwnReferrerQueryString = {
  myAccount: 'my_account',
  globalFooter: 'global_footer',
  footerLink: 'footer_link',
  footer: 'footer',
  myBox: 'my_box',
  homepage: 'homepage',
  customize: 'customize',
  globalNav: 'global_nav',
  globalBanner: 'global_banner',
  homepagePopup: 'homepage_popup',
  cancelFlow: 'cancel_flow',
  myAccountCta: 'my_account_cta',
};

export default ReferrerQueryString;
