import { useEffect, useState } from 'react';

const BREAKPOINTS = {
  xxLarge: 1400,
  xLarge: 1200,
  large: 992,
  medium: 768,
  small: 576,
  xSmall: 0,
};

const isBrowserEnvironment = () => typeof window !== 'undefined';

function getCurrentViewSize(windowWidth: number) {
  if (windowWidth >= BREAKPOINTS.xxLarge) {
    return 'xxLarge'; // Applies from 1400px and above
  } else if (windowWidth >= BREAKPOINTS.xLarge) {
    return 'xLarge'; // Applies from 1200px to 1399px
  } else if (windowWidth >= BREAKPOINTS.large) {
    return 'large'; // Applies from 992px to 1199px
  } else if (windowWidth >= BREAKPOINTS.medium) {
    return 'medium'; // Applies from 768px to 991px
  } else if (windowWidth >= BREAKPOINTS.small) {
    return 'small'; // Applies from 576px to 767px
  } else {
    return 'xSmall'; // Applies for anything less than 576px
  }
}

export function useViewSize() {
  const initialViewSize = isBrowserEnvironment() ? getCurrentViewSize(window.innerWidth) : 'xSmall';
  const [viewSize, setViewSize] = useState(initialViewSize);

  useEffect(() => {
    if (!isBrowserEnvironment()) return undefined;

    const handleResize = () => {
      setViewSize(getCurrentViewSize(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = viewSize === 'xSmall' || viewSize === 'small';
  const isTablet = viewSize === 'small' || viewSize === 'medium';
  const isDesktop = viewSize === 'large' || viewSize === 'xLarge' || viewSize === 'xxLarge';

  return { viewSize, isMobile, isTablet, isDesktop };
}

export default useViewSize;
