import { PropertiesProps } from '../../interfaces';

const SKIP_TITLE_CASE = 'skipTitleCase';

const propsToTitleCase = (properties: PropertiesProps | unknown): PropertiesProps => {
  const titleCasedProperties: PropertiesProps = {};
  const keys = Object.keys(properties);
  const skipTitleCase = properties.skipTitleCase || [];
  keys.forEach((key) => {
    if (typeof properties[key] === 'string' && !skipTitleCase.includes(key)) {
      titleCasedProperties[key] = toTitleCase(properties[key]);
    } else if (key !== SKIP_TITLE_CASE && key !== 'propertiesToOmit') {
      titleCasedProperties[key] = properties[key];
    }
  });
  return titleCasedProperties;
};

export { propsToTitleCase, toTitleCase, SKIP_TITLE_CASE };

const toTitleCase = (str: string): string =>
  str.toLowerCase().replace(/(?:^|[\s-_])\w/g, (match: string) => match.toUpperCase());
