import { forwardRef, type ButtonHTMLAttributes, type ReactNode } from 'react';
import { styled, ThemeProvider, type CSSObject } from 'styled-components';
import useCurrentWidth from '../../hooks/useCurrentWidth';
import breakpoints from '../../utils/breakpoints';
import { getTheme } from './buttonCssProperties';
import LoadingSvg from './LoadingSVG';

export type ButtonVariation =
  | 'primary'
  | 'black'
  | 'rounded-primary'
  | 'rounded-black'
  | 'primary-outline'
  | 'black-outline';

export type ButtonType = 'submit' | 'reset' | 'button';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  width?: number;
  height?: number;
  type?: ButtonType;
  variation?: ButtonVariation;
  small?: boolean;
  containerFullWidth?: boolean;
  styles?: CSSObject;
  loading?: boolean;
  'data-testid'?: string;
}

const DESKTOP_DEFAULT_WIDTH = 220;
const MOBILE_DEFAULT_WIDTH = 351;

const FFFButton = styled.button.attrs((props) => ({
  type: props.type ? props.type : 'button',
  disabled: props.disabled,
  name: props.name,
  onClick: props.onClick ? props.onClick : () => null,
  'data-testid': props['data-testid'],
}))<ButtonProps>((props) => {
  const currentWidth = useCurrentWidth();
  const isSmallDisplay = currentWidth <= breakpoints.small;

  const calculateWidth = () => {
    const smallScreenDisplayWidth = isSmallDisplay ? MOBILE_DEFAULT_WIDTH : DESKTOP_DEFAULT_WIDTH;
    const smallButtonWidth = props.small ? 105 : smallScreenDisplayWidth;
    return props.width || smallButtonWidth;
  };

  const buttonWidth = props.containerFullWidth ? '100%' : calculateWidth();

  const defaultTextTransform = props.small ? 'none' : 'uppercase';

  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    padding: 0,
    width: buttonWidth,
    height: props.height || 48,
    fontFamily: "'Nunito Sans', sans-serif;",
    fontWeight: 'bold',
    lineHeight: 1.33,
    letterSpacing: 1,
    fontSize: 16,
    textTransform: defaultTextTransform,
    ...props.theme,
    ...props.styles,
  };
});

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ForwardedButton(
  {
    onClick,
    width,
    height,
    variation,
    type,
    name,
    disabled,
    small,
    containerFullWidth,
    styles,
    children,
    loading,
    className,
    'data-testid': dataTestId,
  },
  ref,
) {
  return (
    <ThemeProvider theme={getTheme(variation || 'primary')}>
      <FFFButton
        onClick={onClick}
        width={width}
        height={height}
        disabled={disabled}
        small={small}
        containerFullWidth={containerFullWidth}
        styles={styles}
        type={type}
        name={name}
        ref={ref}
        className={className}
        data-testid={dataTestId}
      >
        {loading ? <LoadingSvg /> : children}
      </FFFButton>
    </ThemeProvider>
  );
});

export default Button;
