import { createContext, useContext, type PropsWithChildren } from 'react';

type DataContextValue = {
  userData: {
    subscriptionState: string | null;
    role: string | null;
  };
  initialCredits: object;
  onClickProduct: () => void;
  shoppingCreditFF: object | null;
  isNewMemberOnboarding: boolean;
  isNewMemberFlow: boolean;
  newMemberState: string;
};

const DataContext = createContext<DataContextValue | undefined>(undefined);

const DataContextProvider = ({
  userData,
  initialCredits,
  onClickProduct,
  children,
  shoppingCreditFF,
  isNewMemberOnboarding,
  isNewMemberFlow,
  newMemberState,
}: PropsWithChildren<DataContextValue>) => (
  <DataContext.Provider
    // TODO: Add useMemo here

    value={{
      userData,
      initialCredits,
      onClickProduct,
      shoppingCreditFF,
      isNewMemberOnboarding,
      isNewMemberFlow,
      newMemberState,
    }}
  >
    {children}
  </DataContext.Provider>
);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataContextProvider');
  }
  return context;
};

export default DataContextProvider;
