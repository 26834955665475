import { ContentObject } from '../interface';

const parseContent = (content: ContentObject[]): string =>
  content.reduce((acc, curr, idx, arr) => {
    const currentString = curr.url
      ? `${acc}<a href="${curr.url}">${curr.text}</a>`
      : `${acc}${curr.text}`;

    return idx + 1 === arr.length ? currentString : `${currentString} `;
  }, '');

export default parseContent;
