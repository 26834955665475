import type { FunctionComponent } from 'react';
import useCurrentWidth from '../../hooks/useCurrentWidth';
import breakpoints from '../../utils/breakpoints';
import MobileDrawer from '../MobileDrawer';
import Modal from '../Modal';
import IntroProductModalContent from './IntroProductModalContent';

type IntroProductModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  planPrice: number;
};

const IntroProductModal: FunctionComponent<IntroProductModalProps> = ({
  modalIsOpen,
  closeModal,
  planPrice,
}) => {
  const currentWidth = useCurrentWidth();
  const isMobile = currentWidth <= breakpoints.small;
  if (isMobile) {
    return (
      <MobileDrawer isOpen={modalIsOpen} handleClose={closeModal}>
        {(animatedOnClose) => (
          <IntroProductModalContent onClose={animatedOnClose} isMobile planPrice={planPrice} />
        )}
      </MobileDrawer>
    );
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      showCloseButton
    >
      <IntroProductModalContent onClose={closeModal} isMobile={false} planPrice={planPrice} />
    </Modal>
  );
};

export default IntroProductModal;
