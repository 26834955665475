import type { FunctionComponent } from 'react';
import classNames from 'classnames';
import * as styles from './Tab.module.css';

type TabProps = {
  name: string;
  active: boolean;
  quantity: number;
  onClick: () => void;
  onlyTab?: boolean;
};

const Tab: FunctionComponent<TabProps> = ({ name, active = false, quantity, onClick, onlyTab }) => {
  const buttonText = quantity > 0 ? `${name} (${quantity})` : name;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.button, {
        [styles.onlyTab]: onlyTab,
        [styles.active]: active && !onlyTab,
      })}
    >
      {buttonText}
    </button>
  );
};

export default Tab;
