import {
  createInstance,
  type Client,
  type OptimizelyDecision,
  type OptimizelyUserContext,
} from '@optimizely/optimizely-sdk';

type Decisions = Record<string, OptimizelyDecision>;

interface Context {
  decisions: Decisions;
  userContext: OptimizelyUserContext;
  optimizelyInstance: Client;
}

interface Options {
  sdkKey?: string;
  userId?: string;
}

const featureFlagManager = (() => {
  let optimizelyInstance: Client | null = null;
  let userContext: OptimizelyUserContext | null = null;
  let decisions: Decisions | null = null;
  async function initialize({ sdkKey, userId }: Options) {
    if (optimizelyInstance) return;

    const optimizelySdkKey =
      sdkKey ||
      process.env.GATSBY_APP_OPTIMIZELY_SDK_KEY ||
      process.env.REACT_APP_OPTIMIZELY_SDK_KEY;
    if (!optimizelySdkKey) {
      throw new Error(
        'Optimizely SDK key is missing. Set the OPTIMIZELY_SDK_KEY environment variable or provide it as an option.',
      );
    }

    optimizelyInstance = createInstance({ sdkKey: optimizelySdkKey });

    if (!optimizelyInstance) {
      throw new Error('Optimizely SDK is not able to create a instance');
    }

    const { success, reason } = await optimizelyInstance.onReady();

    if (!success || reason) {
      optimizelyInstance = null;
      throw new Error(reason || 'Optimizely SDK failed to initialize.');
    }

    userContext = optimizelyInstance.createUserContext(userId, {});
    if (!userContext) {
      throw new Error('Failed to create Optimizely user context.');
    }
  }

  return {
    async getFeatureFlag(
      featureKey: string,
      options?: Options,
    ): Promise<OptimizelyDecision | null> {
      await initialize(options || {});

      if (!userContext) {
        throw new Error('User context is not initialized.');
      }

      const decision = userContext.decide(featureKey);
      return decision || null;
    },

    async getDecisions(options?: Options): Promise<Decisions> {
      await initialize(options || {});
      if (!userContext) {
        throw new Error('User context is not initialized.');
      }
      decisions = userContext.decideAll();
      return decisions;
    },

    getContext(): Context {
      if (!decisions || !userContext || !optimizelyInstance) {
        throw new Error('Decisions are not available.');
      }
      return { decisions, userContext, optimizelyInstance };
    },
  };
})();

export default featureFlagManager;
