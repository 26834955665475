import { supplierDiversityUrl } from '../../fixtures/supplierDiversity';

const Banner = () => (
  <section className="banner-container">
    <div className="text-container">
      <h2 className="title">Come Work With FabFitFun!</h2>
      <p className="subtitle">
        FabFitFun is looking to continue to diversify its supplier base for both our e-commerce and
        subscription box platforms.
      </p>
      <button
        className="get-started"
        type="button"
        onClick={() => window.location.assign(supplierDiversityUrl)}
      >
        GET STARTED
      </button>
      <span className="details">
        We are using RangeMe as a partner to make it simple to showcase your product to our buyers.
      </span>
    </div>
  </section>
);

export default Banner;
