import { Banner } from './components/Banners/Banner';
import Header from './components/Header';
import MobileWebBanner from './components/MobileWebBanner';
import NavToast from './components/Toast/NavToast';

const GlobalNavContent = () => (
  <>
    <MobileWebBanner />
    <Banner />
    <Header />
    <NavToast loggedIn />
  </>
);

export default GlobalNavContent;
