import type { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OptimizedImageProps } from './interface';

const getQueryParams = (
  imageOptimizationFF: string,
  imageWidthRequest: string | undefined,
  format: 'jpg' | 'png' | 'webp' | undefined,
) => {
  const queryParams = new URLSearchParams({
    ...(imageOptimizationFF && imageWidthRequest && { w: imageWidthRequest }),
    ...(imageOptimizationFF && format && { f: format }),
  }).toString();
  return queryParams ? `?${queryParams}` : '';
};

const OptimizedImage: FunctionComponent<OptimizedImageProps> = ({
  height,
  width,
  imageWidthRequest,
  classnames,
  alt,
  src,
  onError,
  style,
  dataTestid,
  format,
}) => {
  // imageOptimizationFF _needs_ to be present in the app you are using this Component
  const { imageOptimization: imageOptimizationFF } = useFlags();
  // by default, if we don't specify `imageWidthRequest`, Component should use `width` prop as parameter
  const withRequest = imageWidthRequest || width;
  const url = `${src}${getQueryParams(imageOptimizationFF, withRequest, format)}`;

  return (
    <img
      src={url}
      alt={alt}
      className={classNames(classnames)}
      onError={onError}
      style={style}
      data-testid={dataTestid}
      height={height}
      width={width}
    />
  );
};

export default OptimizedImage;
