import { trackElement } from '@fff-web/fff-analytics';
import { handleTrackAndRedirect } from '../../utils/track';
import CollapsiblePanel from '../CollapsiblePanel';
import Timeline from '../Timeline';
import CTAButton from './components/CTAButton';
import { CTAS } from './constants';
import './SeasonalTimeline.css';

interface TimelineStep {
  id: string | number;
  message: string;
  order: string | number;
  date: string;
  group: string;
}

interface Props {
  screenMode?: string;
  data: {
    accountPlan?: string;
    currentTimelineSteps?: string[];
    timelineSteps?: TimelineStep[];
  };
  isNotSubscribed?: boolean;
}

const SeasonalTimeline = ({
  data: { timelineSteps, accountPlan },
  screenMode = 'mobile',
}: Props) => {
  const handleClickViewEvents = () => {
    handleTrackAndRedirect(
      () => trackElement({ elementName: CTAS.viewEvents }),
      'https://get.fabfitfun.com/upcoming/',
    );
  };

  return (
    <section className="seasonal-timeline">
      <div className="seasonal-timeline__container">
        <CollapsiblePanel title="SEASONAL TIMELINE" screenMode={screenMode}>
          <>
            <div className="seasonal-timeline__timeline__buttons">
              <div className="seasonal-timeline__timeline">
                <Timeline nodeBoxes={timelineSteps} />
              </div>
              <div className="seasonal-timeline__buttons">
                <CTAButton accountPlan={accountPlan} />
                <button
                  type="button"
                  className="fff-btn-primary-black-outline"
                  onClick={handleClickViewEvents}
                  rel="noreferrer"
                >
                  {CTAS.viewEvents}
                </button>
              </div>
            </div>
            <div className="divider" />
          </>
        </CollapsiblePanel>
      </div>
    </section>
  );
};

export default SeasonalTimeline;
