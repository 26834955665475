import NodeBox from './NodeBox';
import './Timeline.css';

type TimelineProps = {
  nodeBoxes: {
    label: string;
    nodes: {
      id: string | number;
      message: string;
      order: string | number;
      date: string;
      group: string;
      status: string;
    }[];
  }[];
};

const Timeline = ({ nodeBoxes = [] }: TimelineProps) => (
  <div className="timeline" data-testid="timeline">
    {nodeBoxes.map(({ label, nodes }) => (
      <NodeBox key={nodes && nodes[0] && nodes[0].id} label={label} nodes={nodes} />
    ))}
  </div>
);

export default Timeline;
