import { useMyBoxContext } from '@fff-web/fff-box';
import { useUserContext } from '@fff-web/fff-utilities';
import { isExpired, isNotSubscribedStatus } from '../../../pages/Homepage/helpers';
import {
  ACTIVATE_MEMBERSHIP_URL,
  HOMEPAGE_EXPERIENCE,
  INVITE_FRIEND_PAGE_URL,
  REACTIVATE_MY_MEMBERSHIP_PAGE_URL,
  REACTIVATE_TODAY_PAGE_URL,
  UPGRADE_PAGE_URL,
} from '../../../utils/constants';
import {
  handleTrackAndRedirect,
  trackInviteStarted,
  trackReactivationStarted,
  trackUpgradeStarted,
} from '../../../utils/track';
import { CTAS, FFFTRIAL_CTAS } from '../constants';

interface Props {
  accountPlan: string;
}

function Button({ onClick, children }: { onClick: () => void; children: string }) {
  return (
    <button type="button" className="fff-btn-primary" onClick={onClick}>
      {children}
    </button>
  );
}

function CTAButton({ accountPlan }: Props) {
  const { userInfo } = useUserContext();
  const { myBox } = useMyBoxContext();

  const isSubscribed = !isNotSubscribedStatus(myBox.data.status, userInfo);
  const subscriptionExpired = userInfo?.user?.subscriptionState === 'EXPIRED';
  const subscriptionType = userInfo?.user?.subscriptionType;
  const role = userInfo?.user?.role;
  const isExpiredUser = isExpired(myBox, userInfo);
  const isFFFTrial = userInfo?.user?.planCode === 'ffftrial';
  const showInviteCTA = (accountPlan === 'ANNUAL' && isSubscribed) || role === 'CLUB';

  const handleFFFTrialActive = () => {
    // this is not the same as isExpired, isExpired includes cancelled type
    handleTrackAndRedirect(
      () => trackReactivationStarted(FFFTRIAL_CTAS.active, subscriptionExpired, subscriptionType),
      ACTIVATE_MEMBERSHIP_URL,
    );
  };

  const handleReactivateOnClick = () => {
    // this is not the same as isExpired, isExpired includes cancelled type
    handleTrackAndRedirect(
      () => trackReactivationStarted(CTAS.reactivate, subscriptionExpired, subscriptionType),
      REACTIVATE_MY_MEMBERSHIP_PAGE_URL,
    );
  };

  const handleSendFreeBoxClick = () => {
    handleTrackAndRedirect(
      () => trackInviteStarted(CTAS.invite, HOMEPAGE_EXPERIENCE),
      INVITE_FRIEND_PAGE_URL,
    );
  };

  const handleClickUpgrade = () => {
    handleTrackAndRedirect(() => trackUpgradeStarted(CTAS.upgrade), UPGRADE_PAGE_URL);
  };

  const handleFFFTrialCanceled = () => {
    handleTrackAndRedirect(
      () => trackReactivationStarted(FFFTRIAL_CTAS.canceled, false, 'Trial'),
      REACTIVATE_TODAY_PAGE_URL,
    );
  };

  const handleFFFTrialExpired = () => {
    handleTrackAndRedirect(
      () => trackReactivationStarted(FFFTRIAL_CTAS.expired, true, 'Trial'),
      REACTIVATE_MY_MEMBERSHIP_PAGE_URL,
    );
  };

  const FFFTRIAL_ENUMS = {
    ACTIVE: {
      onClick: handleFFFTrialActive,
      label: FFFTRIAL_CTAS.active,
    },
    CANCELED: {
      onClick: handleFFFTrialCanceled,
      label: FFFTRIAL_CTAS.canceled,
    },
    EXPIRED: {
      onClick: handleFFFTrialExpired,
      label: FFFTRIAL_CTAS.expired,
    },
  };

  if (isFFFTrial) {
    return (
      <Button
        onClick={
          FFFTRIAL_ENUMS[userInfo?.user?.subscriptionState as keyof typeof FFFTRIAL_ENUMS].onClick
        }
      >
        {FFFTRIAL_ENUMS[userInfo?.user?.subscriptionState as keyof typeof FFFTRIAL_ENUMS].label}
      </Button>
    );
  }

  return (
    <>
      {!isSubscribed && (
        <a className="fff-btn-primary" href={ACTIVATE_MEMBERSHIP_URL}>
          {CTAS.activateMembership}
        </a>
      )}
      {isExpiredUser && isSubscribed ? (
        <Button onClick={handleReactivateOnClick}>{CTAS.reactivate}</Button>
      ) : showInviteCTA ? (
        <Button onClick={handleSendFreeBoxClick}>{CTAS.invite}</Button>
      ) : (
        isSubscribed && <Button onClick={handleClickUpgrade}>{CTAS.upgrade}</Button>
      )}
    </>
  );
}

export default CTAButton;
