// extracted by mini-css-extract-plugin
export var add = "Banner_add__3cDRV";
export var box = "Banner_box__c9ueP";
export var close = "Banner_close__9obht";
export var container = "Banner_container__Ya-uj";
export var content = "Banner_content__RTE7+";
export var description = "Banner_description__1pNyS";
export var note = "Banner_note__fdbJX";
export var shared = "\"../../../shared/shared.module.css\"";
export var sm = "576px";
export var title = "Banner_title__YdMnf";
export var wrapperClose = "Banner_wrapperClose__fvQ-a";