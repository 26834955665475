const account = {
  label: 'MY ACCOUNT',
  href: `${process.env.REACT_APP_SITE_URL}/my-account`,
};
const mySubscriptions = {
  label: 'MY SUBSCRIPTIONS',
  href: `${process.env.REACT_APP_SITE_URL}/my-subscriptions`,
};
const invoiceHistory = {
  label: 'INVOICE HISTORY',
  href: `${process.env.REACT_APP_SITE_URL}/my-account/invoice-history`,
};

export const activateMembership = {
  label: 'ACTIVATE MEMBERSHIP',
  href: '/activate-membership',
};

export const links = [account, mySubscriptions, invoiceHistory];

export const notSubscribedLinks = [mySubscriptions, invoiceHistory];
