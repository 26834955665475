import type { FunctionComponent } from 'react';
import { ReactComponent as SwapIcon } from './assets/swapped.svg';

const StatusIcon: FunctionComponent<{ type: string }> = ({ type }) => {
  switch (type) {
    case 'UNAVAILABLE':
    case 'BOX_NOT_PROCESSED':
      return <i className="far fa-box-open" />;
    case 'BOX_ORDER_SAVED':
      return <i className="far fa-box-check" />;
    case 'PREPARING_TO_SHIP':
      return <i className="far fa-truck-loading" />;
    case 'SHIPPED':
      return <i className="far fa-truck" />;
    case 'DELIVERED':
      return <i className="far fa-shipping-fast" />;
    case 'SKIPPED':
      return (
        <i>
          <SwapIcon />
        </i>
      );
    default:
      return null;
  }
};

export default StatusIcon;
