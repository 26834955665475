import { useState, useLayoutEffect } from 'react';

const breakpoint = 1200;

const MoreInfo = () => {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth });
  const isMobile = windowSize.width < breakpoint;
  const handleResize = () => {
    setWindowSize({ width: window.innerWidth });
  };
  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="info-container">
      <div className="divider" />
      <h2 className="info-title">
        Some of the Ways That We Define Diverse & Small Business Suppliers:
      </h2>
      <div className="details-container">
        <div className="detail">
          <h4 className="info-details-title" style={{ margin: '0 0 7px' }}>
            Minority Business Enterprise (MBE)
          </h4>
          <p className="info-details">
            Business that is at least 51 percent owned, operated, and controlled by one or more
            ethnic minority group member or, if publicly owned, at least 51 percent of its stock is
            owned by one or more minority group members. A minority group member is an individual
            who is Asian, Black, Hispanic or Native American. The minority/ethnic owner(s) of the
            business must be active in management and daily business operations.
          </p>
        </div>

        <div className="detail">
          <h4 className="info-details-title" style={{ margin: '0 0 7px' }}>
            Women Business Enterprise (WBE)
          </h4>
          <p className="info-details">
            Business that is at least is 51 percent owned, controlled, operated, and managed by a
            woman or women.
          </p>
        </div>

        <div className="detail">
          <h4 className="info-details-title">
            Lesbian, Gay, Bisexual and Transgender-owned Business Enterprise (LGBTBE)
          </h4>
          <p className="info-details">
            A business that is at least 51 percent owned, operated, managed, and controlled by a
            lesbian, gay, bi-sexual, or transgender person or persons, and that exercises
            independence from any non-LGBT business enterprise.
          </p>
        </div>

        <div className="detail" style={{ marginTop: !isMobile ? '38px' : '' }}>
          <h4 className="info-details-title" style={{ margin: '0 0 7px' }}>
            Veteran Business Enterprise (VBE)
          </h4>
          <p className="info-details">
            A business that is at least 51 percent owned, operated, and controlled by a service
            veteran regardless of race/ethnicity.
          </p>
        </div>

        <div className="detail" style={{ marginTop: !isMobile ? '-77px' : '' }}>
          <h4 className="info-details-title">Service-Disabled Veteran-Owned Enterprise (SDVOE)</h4>
          <p className="info-details">
            As recognized by the Small Business Administration (SBA) and the U.S. Department of
            Veteran Affairs for the purposes of federal contracting, a service-disabled
            veteran-owned business must be at least 51 percent owned by one or more service-disabled
            veterans, or in the case of any publicly owned business, not less than 51 percent of the
            stock of which is owned by one or more service-disabled veterans. The management and
            daily business operations must be controlled by one or more service-disabled veterans,
            or in the case of a veteran with a permanent and severe disability, a spouse or
            permanent caregiver of such veteran.
          </p>
        </div>

        <div className="detail" style={{ marginTop: !isMobile ? '-5px' : '' }}>
          <h4 className="info-details-title">Disability-Owned Business Enterprise (DOBE)</h4>
          <p className="info-details">
            A business that is (a) at least fifty-one percent (51%) owned, operated, managed, and
            controlled by individual(s) with a disability or service-disabled veteran(s) who are
            either U.S. citizens or lawful permanent resident; and (b) exercises independence from
            any other non-disability-owned business enterprise.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MoreInfo;
