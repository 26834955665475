import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS } from 'react-joyride';
import './OnboardingModal.css';
import type { ComponentProps, FunctionComponent } from 'react';

type FlexDirection =
  | 'row'
  | 'row-reverse'
  | 'column'
  | 'column-reverse'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'revert-layer'
  | 'unset';
type TextAlign =
  | 'start'
  | 'end'
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'match-parent'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'revert-layer'
  | 'unset';
type TextTransform =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'full-width'
  | 'full-size-kana'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'revert-layer'
  | 'unset';

const FONT_NUNITO_SANS = 'Nunito Sans';

const customProps = {
  styles: {
    options: {
      arrowColor: 'none',
      backgroundColor: '#fff',
      borderRadius: '6px',
      width: '351px',
      zIndex: 1000000,
    },
    tooltip: {
      transition: 'none',
    },
    spotlight: {
      transition: 'none',
    },
    tooltipContent: {
      color: '#000',
      fontFamily: FONT_NUNITO_SANS,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      marginTop: '16px',
      padding: '1px',
      textAlign: 'left' as TextAlign,
      width: '282px',
    },
    tooltipTitle: {
      alignItems: 'center',
      color: '#000',
      display: 'flex',
      fontFamily: FONT_NUNITO_SANS,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      justifyContent: 'space-between',
      lineHeight: '24px',
      padding: '1px',
      textAlign: 'left' as TextAlign,
      textTransform: 'uppercase' as TextTransform,
    },
    tooltipFooter: {
      display: 'flex',
      flexDirection: 'row-reverse' as FlexDirection,
      justifyContent: 'start',
    },
    buttonNext: {
      background: 'none',
      borderBottom: '2px solid #000',
      borderRadius: '0',
      color: '#000',
      fontFamily: FONT_NUNITO_SANS,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '18px',
      marginTop: '9px',
      padding: '0 0 2px',
      textEdge: 'cap',
      textTransform: 'uppercase' as TextTransform,
    },
    buttonClose: {
      color: '#fff',
    },
  },
  floaterProps: {
    disableAnimation: true,
  },
};

const OnboardingModal: FunctionComponent<ComponentProps<typeof Joyride>> = (props) => {
  const newProps = { ...props, ...customProps };

  return (
    <div data-testid="onboarding-wrapper-container">
      <Joyride {...newProps} />
    </div>
  );
};

export {
  ACTIONS as OnboardingModalACTIONS,
  EVENTS as OnboardingModalEVENTS,
  ORIGIN as OnboardingModalORIGIN,
  STATUS as OnboardingModalSTATUS,
};
export default OnboardingModal;
