// Hero banner
export const REQUEST_HERO_BANNER = 'REQUEST_HERO_BANNER';
export const REQUEST_HERO_BANNER_SUCCESS = 'REQUEST_HERO_BANNER_SUCCESS';
export const REQUEST_HERO_BANNER_FAIL = 'REQUEST_HERO_BANNER_FAIL';
// Web Cards
export const REQUEST_WEB_CARDS = 'REQUEST_WEB_CARDS';
export const REQUEST_WEB_CARDS_SUCCESS = 'REQUEST_WEB_CARDS_SUCCESS';
export const REQUEST_WEB_CARDS_FAIL = 'REQUEST_WEB_CARDS_FAIL';
// Seasonal Timelines
export const REQUEST_SEASONAL_TIMELINES = 'REQUEST_SEASONAL_TIMELINES';
export const REQUEST_SEASONAL_TIMELINES_SUCCESS = 'REQUEST_SEASONAL_TIMELINES_SUCCESS';
export const REQUEST_SEASONAL_TIMELINES_FAIL = 'REQUEST_SEASONAL_TIMELINES_FAIL';
// Invite Total
export const REQUEST_INVITE_TOTAL = 'REQUEST_INVITE_TOTAL';
export const REQUEST_INVITE_TOTAL_SUCCESS = 'REQUEST_INVITE_TOTAL_SUCCESS';
export const REQUEST_INVITE_TOTAL_FAIL = 'REQUEST_INVITE_TOTAL_FAIL';
// Show Invite Popup
export const REQUEST_SHOW_INVITE_POPUP = 'REQUEST_SHOW_INVITE_POPUP';
export const REQUEST_SHOW_INVITE_POPUP_SUCCESS = 'REQUEST_SHOW_INVITE_POPUP_SUCCESS';
export const REQUEST_SHOW_INVITE_POPUP_FAIL = 'REQUEST_SHOW_INVITE_POPUP_FAIL';
