import { useEffect, useRef, useState, type FunctionComponent, type ReactNode } from 'react';
import classNames from 'classnames';
import useSwipe from '../../hooks/useSwipe';
import Modal from '../Modal';
import * as styles from './MobileDrawer.module.css';

interface MobileDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode | ((animatedOnClose: () => void) => ReactNode);
}

export const MobileDrawer: FunctionComponent<MobileDrawerProps> = ({
  isOpen,
  handleClose,
  children,
}) => {
  const [shouldClose, setShouldClose] = useState(false);
  const timeoutRef = useRef<number>();

  const animatedOnClose = () => {
    setShouldClose(true);
    setTimeout(() => {
      handleClose();
      setShouldClose(false);
      reset();
    }, 300 /* wait for swipe animation to finish */);
  };

  const { eventListeners, distance, reset } = useSwipe({
    onSwipedDown: () => {
      animatedOnClose();
    },
    onSwipeCanceled: () => {
      reset();
    },
  });

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onRequestClose={animatedOnClose}
      shouldCloseOnOverlayClick
      borderless
    >
      <div
        className={classNames(styles.container, shouldClose && styles.swipedDown)}
        style={{
          bottom: 0 + (distance < 0 ? distance : 0),
        }}
      >
        <div data-testid="mobileDrawerHandle" className={styles.drawerHandle} {...eventListeners} />
        <div className={styles.content}>
          {typeof children === 'function' ? children(animatedOnClose) : children}
        </div>
      </div>
    </Modal>
  );
};

export default MobileDrawer;
