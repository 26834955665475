import { useContext } from 'react';
import { arrayOf, bool, instanceOf, shape, string } from 'prop-types';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { Timer } from '@fff-web/fff-utilities';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import { getNmfRedirectUrl } from '../../services';
import * as styles from './NmfTimeUp.module.css';

const NmfTimeUp = ({ expDate, userRequest }) => {
  const { timeUp } = Timer.useTimer(expDate);
  const { env } = useContext(GlobalNavInternalContext);
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });
  const viewSize = isDesktop ? 'large' : 'small';

  const ctaUrl = getNmfRedirectUrl(env, userRequest);

  // TODO make this a generic template, once we have more than one modal here
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={timeUp}
      defaultStyles={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
        },
        content: {
          position: 'absolute',
          inset: 'auto',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
        },
      }}
    >
      <div className={`${styles.modalBody} ${styles[viewSize]}`}>
        <h2>Time Has Ended</h2>
        <p>
          Oops! You’ve reached the time limit. Any free selections will be processed for your
          upcoming box.
        </p>
        <button
          className={`fff-button primary ${styles.cta}`}
          onClick={() => {
            window.location.href = ctaUrl;
          }}
          type="button"
        >
          DONE
        </button>
      </div>
    </Modal>
  );
};

NmfTimeUp.propTypes = {
  expDate: instanceOf(Date).isRequired,
  userRequest: shape({
    lives: arrayOf(
      shape({
        id: string,
        live: bool,
      }),
    ),
  }),
};

export default NmfTimeUp;
