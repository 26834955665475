import { IFetchUserInfo, IUserInfo } from '../../../interfaces';
import { getEnv } from '../../environment';
import request from '../../services/request';
import durationUserInfo from '../../utils/durationUserInfo';
import { isPublicPage } from '../../utils/publicPage';
import { getFromStorage, removeFromStorage, setToStorage } from '../../utils/storage';
import userInfoKey from '../../utils/userInfoKey';
import { defaultUserInfo } from '../defaultContext';

const fetchUserInfo = async ({ env }: IFetchUserInfo): Promise<IUserInfo | string> => {
  const response = await request<IUserInfo>({ path: env.REACT_APP_USER_INFO_URL });
  return response;
};

const invalidateUserCache = () => removeFromStorage({ key: userInfoKey });

const getUserFromStorage = () => getFromStorage({ key: userInfoKey, defaultItem: defaultUserInfo });

const getUserInfo = async (forceRefresh = false): Promise<IUserInfo> => {
  if (isPublicPage) return Promise.resolve(defaultUserInfo);

  const userFromSTG = getFromStorage({ key: userInfoKey });
  if (!forceRefresh && userFromSTG) {
    return userFromSTG;
  }

  removeFromStorage({ key: userInfoKey });
  try {
    const requestUserInfo = await fetchUserInfo({ env: getEnv() });

    if (requestUserInfo) {
      setToStorage({
        key: userInfoKey,
        value: requestUserInfo,
        durationInMs: durationUserInfo,
      });
      return requestUserInfo as IUserInfo;
    }
  } catch (err: unknown) {
    const error = err as Error;
    if (error.message === 'Unauthorized' || error.message === 'Forbidden') {
      removeFromStorage({ key: userInfoKey });
    } else {
      console.log(err, error.message);
    }
  }
  return Promise.resolve(defaultUserInfo);
};

export { fetchUserInfo, invalidateUserCache, getUserFromStorage, getUserInfo };
