import { useContext } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { track } from '@fff-web/fff-analytics';
import { useUserContext } from '@fff-web/fff-utilities';
import GlobalNavInternalContext from '../../../context/GlobalNavInternalContext';
import { getLink, getStarterBoxURL } from '../../../helpers/navigationMenu';
import { clearToastUId } from '../../../hooks/useExpirationToast';
import { REACTIVATION_URL } from '../../../utils/constants';
import SubMenu from '../SubMenu';

const LOG_OUT_ID = 'account_logout';
const SEND_STARTER_BOX_ID = 'invite_friends';
const CTA_LABEL_STARTER_BOX = 'Send A Starter Box';
const INVITE_TYPE = 'SGTF';
const EXPERIENCE_TYPE = 'Global Nav';
const TRACK_NAME = 'Invite Started';

const MenuItem = ({
  item: { label, domain, route, id, subRoutes, renderFunction, live, dataTestId } = {},
  isDesktop = false,
}) => {
  const { env } = useContext(GlobalNavInternalContext);
  const { clearStorage } = useUserContext();
  const baseUrl = domain || env.REACT_APP_BASE_URL;
  const handleLogoutOrTrack = (idL) => {
    if (idL === LOG_OUT_ID) {
      clearStorage();
      clearToastUId();
    }
    if (idL === SEND_STARTER_BOX_ID) {
      const properties = {
        cta_name: CTA_LABEL_STARTER_BOX,
        experience: EXPERIENCE_TYPE,
        invite_type: INVITE_TYPE,
      };
      track({
        trackName: TRACK_NAME,
        properties,
      }).then(() => true);
    }
    return true;
  };

  const routeLink =
    id === SEND_STARTER_BOX_ID && route !== REACTIVATION_URL ? getStarterBoxURL(route) : route;

  return subRoutes && subRoutes.length ? (
    <SubMenu
      label={label}
      domain={baseUrl}
      route={routeLink}
      id={id}
      items={subRoutes}
      isDesktop={isDesktop}
      renderFunction={renderFunction}
    />
  ) : (
    <li>
      <a
        data-testid={dataTestId}
        href={getLink({ domain: baseUrl, route: routeLink })}
        onClick={() => handleLogoutOrTrack(id)}
      >
        {label}
        {live && <span className="live">LIVE</span>}
      </a>
    </li>
  );
};

MenuItem.propTypes = {
  item: shape({
    label: string,
    domain: string,
    route: string,
    id: string,

    subRoutes: arrayOf(
      shape({
        id: string,
        label: string,
        route: string,
      }),
    ),
    renderFunction: func,
  }),
  isDesktop: bool,
};

export default MenuItem;
