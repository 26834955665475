import { v4 as uuidv4 } from 'uuid';

export const OPTIMIZELY_FEATURES_FLAGS = {
  SMART_BANNER_DOWNLOAD: 'smart-banner-download',
};

export const MOBILE_BANNER_VARIATIONS = {
  VERSION_1: 'version_1',
  CONTROL: 'control',
};

export const getUUID = () => {
  const UUID_LOCAL_STORAGE_KEY = 'FFF_gtb_uuid';
  const uuidFromLocalStorage = localStorage.getItem(UUID_LOCAL_STORAGE_KEY);
  if (!uuidFromLocalStorage) {
    localStorage.setItem(UUID_LOCAL_STORAGE_KEY, uuidv4());
  }
  return localStorage.getItem(UUID_LOCAL_STORAGE_KEY) || '';
};
