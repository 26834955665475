import {
  REQUEST_HERO_BANNER,
  REQUEST_HERO_BANNER_SUCCESS,
  REQUEST_HERO_BANNER_FAIL,
  REQUEST_WEB_CARDS,
  REQUEST_WEB_CARDS_SUCCESS,
  REQUEST_WEB_CARDS_FAIL,
  REQUEST_SEASONAL_TIMELINES,
  REQUEST_SEASONAL_TIMELINES_SUCCESS,
  REQUEST_SEASONAL_TIMELINES_FAIL,
  REQUEST_INVITE_TOTAL_SUCCESS,
  REQUEST_INVITE_TOTAL_FAIL,
  REQUEST_SHOW_INVITE_POPUP_SUCCESS,
  REQUEST_SHOW_INVITE_POPUP_FAIL,
} from './actionTypes';
import { getInitialValueFromStorage, setInitialValueToStorage } from './helpers';
import { sortByOrderSelector } from './selectors/common';
import { seasonalTimelineSelector } from './selectors/timeline';

const heroBannerKey = 'heroBanner2.0';
const webCardsKey = 'webCards2.0';
const seasonalTimelinesKey = 'seasonalTimelines2.0';

export const initialState = () => ({
  heroBanner: getInitialValueFromStorage(heroBannerKey, {}),
  webCards: getInitialValueFromStorage(webCardsKey, []),
  seasonalTimelines: getInitialValueFromStorage(seasonalTimelinesKey, []),
  showInvitePopup: false,
  inviteTotal: 0,
});

export const homepageReducer = (state, action) => {
  switch (action.type) {
    case REQUEST_HERO_BANNER:
      return {
        ...state,
        heroBanner: { ...state.heroBanner, loading: true, fail: false },
      };
    case REQUEST_HERO_BANNER_SUCCESS:
      setInitialValueToStorage(heroBannerKey, action.payload);
      return {
        ...state,
        heroBanner: { data: action.payload, loading: false, fail: false },
      };
    case REQUEST_HERO_BANNER_FAIL:
      return {
        ...state,
        heroBanner: { ...state.heroBanner, loading: false, fail: true },
      };

    case REQUEST_WEB_CARDS:
      return {
        ...state,
        webCards: { ...state.webCards, loading: true, fail: false },
      };
    case REQUEST_WEB_CARDS_SUCCESS:
      setInitialValueToStorage(webCardsKey, action.payload);
      return {
        ...state,
        webCards: {
          data: sortByOrderSelector(action.payload),
          loading: false,
          fail: false,
        },
      };
    case REQUEST_WEB_CARDS_FAIL:
      return {
        ...state,
        webCards: { ...state.webCards, loading: false, fail: true },
      };

    case REQUEST_SEASONAL_TIMELINES:
      return {
        ...state,
        seasonalTimelines: { ...state.seasonalTimelines, loading: true, fail: false },
      };
    case REQUEST_SEASONAL_TIMELINES_SUCCESS: {
      const data = {
        ...action.payload,
        timelineSteps: seasonalTimelineSelector(action.payload),
      };
      setInitialValueToStorage(seasonalTimelinesKey, data);
      return {
        ...state,
        seasonalTimelines: {
          data,
          loading: false,
          fail: false,
        },
      };
    }
    case REQUEST_SEASONAL_TIMELINES_FAIL:
      return {
        ...state,
        seasonalTimelines: { ...state.seasonalTimelines, loading: false, fail: true },
      };
    case REQUEST_INVITE_TOTAL_SUCCESS:
      return {
        ...state,
        inviteTotal: { data: action.payload },
      };
    case REQUEST_INVITE_TOTAL_FAIL:
      return {
        ...state,
        inviteTotal: { error: action.payload },
      };
    case REQUEST_SHOW_INVITE_POPUP_SUCCESS:
      return {
        ...state,
        showInvitePopup: true,
      };
    case REQUEST_SHOW_INVITE_POPUP_FAIL:
      return {
        ...state,
        showInvitePopup: false,
      };
    default:
      return state;
  }
};

export const actions = {
  getHeroBanner: () => ({ type: REQUEST_HERO_BANNER }),
  getHeroBannerSuccess: (data) => ({ type: REQUEST_HERO_BANNER_SUCCESS, payload: data }),
  getHeroBannerFail: (error) => ({ type: REQUEST_HERO_BANNER_FAIL, payload: error }),
  getWebCards: () => ({ type: REQUEST_WEB_CARDS }),
  getWebCardsSuccess: (data) => ({ type: REQUEST_WEB_CARDS_SUCCESS, payload: data }),
  getWebCardsFail: (error) => ({ type: REQUEST_WEB_CARDS_FAIL, payload: error }),
  getSeasonalTimelines: () => ({ type: REQUEST_SEASONAL_TIMELINES }),
  getSeasonalTimelinesSuccess: (data) => ({
    type: REQUEST_SEASONAL_TIMELINES_SUCCESS,
    payload: data,
  }),
  getSeasonalTimelinesFail: (error) => ({ type: REQUEST_SEASONAL_TIMELINES_FAIL, payload: error }),
  getInviteTotalSuccess: (data) => ({ type: REQUEST_INVITE_TOTAL_SUCCESS, payload: data }),
  getInviteTotalFail: (error) => ({ type: REQUEST_INVITE_TOTAL_FAIL, payload: error }),
  getShowInvitePopupSuccess: () => ({ type: REQUEST_SHOW_INVITE_POPUP_SUCCESS }),
  getShowInvitePopupFail: () => ({ type: REQUEST_SHOW_INVITE_POPUP_FAIL }),
};
