import { useContext } from 'react';
import classNames from 'classnames';
import { ProspectNavContext } from '../../../../context/ProspectNavContext';
import { isVariation } from '../../../../helpers/functions/isVariation';
import { GROWTH_GLOBAL_NAV } from '../../../../utils/featureFlagVariants/growth-global-nav';
import { constructUrlWithParams } from '../../../../utils/navigation';
import { createRoutes, createTrackingData, MENU_LABELS } from '../../../../utils/prospectGlobalNav';
import * as styles from './ProspectBanner.module.css';

/**
 * Find the specified route by label
 * @param {string} baseUrl - The base URL of the application.
 * @param {string} loginUrl - The login URL of the application.
 * @param {string} label - The label of the route to find.
 * @returns {Array} - An array containing the filtered routes that match the selected variation's labels.
 */
function findRouteByLabel(baseUrl, loginUrl, label) {
  return createRoutes(baseUrl, loginUrl).find((route) => route.label === label);
}

const { VARIATION_D, VARIATION_E } = GROWTH_GLOBAL_NAV;

const ProspectBanner = () => {
  const { env, segmentTrackingData, mainProps } = useContext(ProspectNavContext);
  const baseUrl = env.REACT_APP_BASE_URL;
  const loginUrl = env.REACT_APP_LOGIN_BASE_URL;

  const memberOption = findRouteByLabel(baseUrl, loginUrl, MENU_LABELS.membership);
  const shopOption = findRouteByLabel(baseUrl, loginUrl, MENU_LABELS.shop);
  const quizOption = findRouteByLabel(baseUrl, loginUrl, MENU_LABELS.quiz);

  const { variationKey } = mainProps;

  const isVariationD = isVariation(variationKey, VARIATION_D);
  const isVariationE = isVariation(variationKey, VARIATION_E);

  const bannerClass = classNames(styles.banner, {
    [styles.bannerVariationD]: isVariationD,
  });

  const handleCTAOnClick = async (buttonType, url) => {
    await createTrackingData({
      ctaName: buttonType,
      segmentTrackingData,
    });

    const newURL = constructUrlWithParams(url);

    window.location.href = newURL;
  };

  return (
    <div data-testid="header-banner" className={bannerClass}>
      <ul>
        <li className={styles.bannerItem}>
          <button
            type="button"
            onClick={() => handleCTAOnClick(MENU_LABELS.membership, memberOption.url)}
          >
            {memberOption.label}
          </button>
        </li>
        {!isVariationD && !isVariationE && (
          <li className={styles.bannerItem}>
            <button
              type="button"
              onClick={() => handleCTAOnClick(MENU_LABELS.shop, shopOption.url)}
            >
              {shopOption.label}
            </button>
          </li>
        )}
        {isVariationE && (
          <li className={styles.bannerItem}>
            <button
              type="button"
              onClick={() => handleCTAOnClick(MENU_LABELS.quiz, quizOption.url)}
            >
              {quizOption.label}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ProspectBanner;
