import type { FunctionComponent } from 'react';
import InternalContextProvider from './context/InternalContext';
import CartContainer from './scenes/Cart/CartContainer';

export const CartComponent: FunctionComponent<{
  env: string;
  newMemberState: string;
  onNewMemberStateFetched: (state: string) => void;
}> = ({ env, newMemberState, onNewMemberStateFetched }) => (
  <InternalContextProvider env={env}>
    <CartContainer
      newMemberState={newMemberState}
      onNewMemberStateFetched={onNewMemberStateFetched}
    />
  </InternalContextProvider>
);
