import { getParamFromUrl } from '@fff-web/fff-utilities';
import { PropertiesProps, TrackInfoProps } from '../../interfaces';
import { formatReferrerQueryStringValues } from './formatFields';
import { propsToTitleCase, toTitleCase } from './toTitleCase';

const REFERRER_QUERY_STRING = 'referrer';

const getExperienceField = (): string => {
  const referrerValue = getParamFromUrl(REFERRER_QUERY_STRING);
  const queryValue = formatReferrerQueryStringValues(referrerValue);
  if (!queryValue) {
    return '';
  }

  return toTitleCase(queryValue);
};

const trackInfo = ({
  fffAnalytics,
  properties = {},
  localStorageUserInfo,
}: TrackInfoProps): PropertiesProps => ({
  ...fffAnalytics.sessionInfo,
  ...(!properties?.omitExperience && {
    experience: properties?.experience
      ? toTitleCase(String(properties.experience))
      : getExperienceField(),
  }),
  ...propsToTitleCase(properties),
  fff_uuid: localStorageUserInfo?.user?.uuid,
  is_authenticated: localStorageUserInfo?.loggedIn,
});

export default trackInfo;
