import type { FunctionComponent } from 'react';
import * as styles from './CouponNotice.module.css';

type CouponNoticeProps = {
  hasAppliedCoupon: boolean;
};

const CouponNotice: FunctionComponent<CouponNoticeProps> = ({ hasAppliedCoupon }) => {
  const couponNoticeMessage = hasAppliedCoupon
    ? 'You have a promo code applied! Check out now to redeem.'
    : 'Save more with a promo code. Only redeemable in self checkout.';

  return (
    <div className={styles.container} data-testid="coupon-notice-container">
      {couponNoticeMessage}
    </div>
  );
};

export default CouponNotice;
