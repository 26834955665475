import Layout from '../../components/Layout';
import './NotFoundError.css';

const NotFoundError = ({ baseUrl = process.env.REACT_APP_SITE_URL }) => (
  <Layout pageTitle="Page Not Found - FabFitFun">
    <div className="pageContent">
      <article className="not-found-error">
        <h1>404</h1>
        <h2>We’re sorry! The page you are looking for cannot be found!</h2>
        <a href={baseUrl} className="btn-back-home">
          Go back home
        </a>
      </article>
    </div>
  </Layout>
);

export default NotFoundError;
