import {
  useState,
  type FunctionComponent,
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
} from 'react';
import './styles.css';

const ReadMore: FunctionComponent<
  PropsWithChildren<{ trackElements: (content: string) => void }>
> = ({ children, trackElements }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleShowLess = (e: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
    const el = e.currentTarget.closest('button');
    if (el && e.currentTarget.contains(el)) {
      setIsVisible(false);
    }
  };

  const handleShowMore = () => {
    setIsVisible(true);
    trackElements('Show more');
  };

  return (
    <div className="read-more">
      {isVisible ? (
        <div
          aria-hidden="true"
          onClick={handleShowLess}
          onKeyDown={handleShowLess}
          dangerouslySetInnerHTML={{
            __html: `${children}${'<span></span><button>Show less</button>'}`,
          }}
        />
      ) : (
        <button type="button" onClick={() => handleShowMore()}>
          Show more
        </button>
      )}
    </div>
  );
};

export default ReadMore;
