import get from 'lodash/get';

import { CAMPAIGN_TYPE_LINKS } from './campaign';
import { HOSTNAMES } from './env';
import { VARIANT_TYPES } from './types';
import { getPage, getIsInPandora, PAGE } from './page';

export const clickProductHandler = (variant, campaignType, onClickProduct, closeCart, env) => {
  const hostname = get(HOSTNAMES, env);
  const page = getPage();
  const salePage = get(CAMPAIGN_TYPE_LINKS, campaignType);
  const queryParam = `product_link=${variant.shareUrl}`;
  const isAdditionalProduct =
    variant?.variantType === VARIANT_TYPES.customizeAdditionalChoice && variant?.questionId;

  closeCart();
  if (isAdditionalProduct) {
    if (page === PAGE['new-member-customize'] && onClickProduct) {
      // we receive onClickProduct from customize to prevent a full page refresh
      onClickProduct({ variant });
    } else {
      window.location.href = `/new-member-customize/?q=${variant.questionId}`;
    }
  } else {
    if (getIsInPandora() && onClickProduct) {
      // we receive onClickProduct from pandora to prevent a full page refresh
      onClickProduct({
        pathname: `/${salePage}`,
        search: `?${queryParam}`,
      });
    } else {
      window.location.href = `https://${hostname}/${salePage}?${queryParam}`;
    }
  }
};
