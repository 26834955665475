export const AWAITING_ADDRESS = 'AWAITING_ADDRESS';
export const CUSTOMIZE_EDUCATION = 'CUSTOMIZE_EDUCATION';
export const PERSONAL_SURVEY = 'PERSONAL_SURVEY';
export const CUSTOMIZING = 'CUSTOMIZING';
export const USER_ONBOARDING_CUSTOMIZING = 'USER_ONBOARDING_CUSTOMIZING';
export const XSELLS = 'XSELLS';
export const USER_ONBOARDING_XSELLS = 'USER_ONBOARDING_XSELLS';
export const CONFIRMATION = 'CONFIRMATION';
export const USER_COMPLETED = 'USER_COMPLETED';
export const READY_FOR_SALE = 'READY_FOR_SALE';
export const USER_ONBOARDING_1 = 'USER_ONBOARDING_1';
export const USER_ONBOARDING_2 = 'USER_ONBOARDING_2';
export const USER_ONBOARDING_3 = 'USER_ONBOARDING_3';
export const USER_ONBOARDING_4 = 'USER_ONBOARDING_4';
export const ACTIVE_CHECKOUT = 'ACTIVE_CHECKOUT';
export const PASSIVE_CHECKOUT = 'PASSIVE_CHECKOUT';
export const TIMER_COMPLETED = 'TIMER_COMPLETED';
