// extracted by mini-css-extract-plugin
export var buttonsContainer = "NewItemAddedModal_buttonsContainer__j6nAW";
export var contentContainer = "NewItemAddedModal_contentContainer__Z7ay7";
export var disclaimer = "NewItemAddedModal_disclaimer__7VLze";
export var headerContainer = "NewItemAddedModal_headerContainer__InY5g";
export var imageAndDescriptionContainer = "NewItemAddedModal_imageAndDescriptionContainer__qMrZ9";
export var itemContainer = "NewItemAddedModal_itemContainer__3EMlo";
export var itemDescription = "NewItemAddedModal_itemDescription__eKpT7";
export var modal = "NewItemAddedModal_modal__jHncD";
export var modalPortal = "NewItemAddedModal_modalPortal__I1Zfd";
export var overlay = "NewItemAddedModal_overlay__zM9rN";
export var price = "NewItemAddedModal_price__WmT9b";
export var shared = "\"../../../shared/shared.module.css\"";
export var sm = "576px";