export const defaultMyBox = {
  data: {
    imageURL: '',
    name: '',
    status: {
      id: '',
      title: '',
      body: '',
      bodyDetail: '',
      trackingUrl: '',
      currentOrderStep: '',
    },
    shippingAddress: '',
    order: { currentOrderStep: '', orderSteps: '' },
  },
  fail: '',
};

export const defaultProducts = {
  list: [],
};

const defaultContext = {
  myBox: defaultMyBox,
  products: defaultProducts,
};

export default defaultContext;
