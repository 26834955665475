import { getEnv } from '../environment';
import request from './request';

const env = getEnv();
const { REACT_APP_USER_URL: userApi } = env;

export const createUser = (email: string) => {
  return request({
    path: `${userApi}/leads/create`,
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });
};

export const sendPhoneNumber = (phoneNumber: string, email?: string) => {
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/[^\d+]/g, '');

    return request({
      path: `${userApi}/leads/create`,
      method: 'POST',
      body: JSON.stringify({
        phoneNumber,
        ...(email ? { email } : {}),
      }),
    });
  }

  return Promise.resolve();
};
