export const toLowerCase = (string) => (string ? string.toLowerCase() : '');

export const toCapitalized = (string) => {
  const lowerCaseString = toLowerCase(string);
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
};

export const toCapitalizedEachWord = (string) => toLowerCase(string).charAt(0).toUpperCase();

/**
 * Remove the first and last slashes from a string
 * @param {string} text - The string to trim
 * @returns {string} - The trimmed string
 */
export function trimSlashes(text) {
  return text.replace(/^\/|\/$/g, '');
}

export const removeFirstReplaceSlashes = (text) => text.slice(1, -1).replace(/\//g, '.');

export const getTimestamp = () => {
  const theDate = new Date();
  const date = theDate.toISOString().split('T')[0];
  const time = theDate.toTimeString().split(' ')[0];
  return `${date} ${time}`;
};
