import { trackEvent } from '@fff-web/fff-analytics';
import { HOMEPAGE_EXPERIENCE } from '../../utils/constants';
import { getImageByScreenMode } from '../../utils/images';
import { trackInviteStarted } from '../../utils/track';
import './WebCards.css';

const STARTER_BOX_CTA_LABEL = 'Send Boxes';

type WebCardsProps = {
  screenMode: string;
  data: {
    id: number;
    title: string;
    subText: string;
    desktopImage: string;
    tabletImage: string;
    mobileImage: string;
    ctaLink: string;
    ctaLabel: string;
  }[];
};

const WebCards = ({ screenMode = 'mobile', data = [] }: WebCardsProps) => {
  const handleOnClick = (ctaLabel: string) => {
    if (ctaLabel === STARTER_BOX_CTA_LABEL) {
      trackInviteStarted(STARTER_BOX_CTA_LABEL, HOMEPAGE_EXPERIENCE);
    } else {
      trackEvent({ ctaName: ctaLabel }).finally(() => true);
    }
  };
  return (
    <section className="web-cards">
      <div className="web-cards__container">
        <h3>Explore Your Membership</h3>
        <div className="web-cards__cards">
          {data.map(({ id, title, subText, ctaLink, ctaLabel, ...images }) => (
            <div className="web-cards__cards__card" key={id}>
              <div className="web-card-image">
                <img src={getImageByScreenMode(images, screenMode, 'ImageURL')} alt={title} />
              </div>
              <div className="web-card-text">
                <h4>{title}</h4>
                <p>{subText}</p>
              </div>
              <div className="web-card-cta">
                <a
                  className="fff-btn-black-outline-small"
                  href={ctaLink}
                  onClick={() => handleOnClick(ctaLabel)}
                >
                  {ctaLabel}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WebCards;
