import { ScriptsExists } from '@fff-web/fff-utilities';
import { getHostName } from './getEnv';
import isBrowser from './isBrowser';

export function loadPactSafe() {
  if (!isBrowser()) {
    return false;
  }
  const envUrl = getHostName();
  const protocol = window.location.href.split('/')[0];
  const scriptSrc = `${protocol}//abtesting.${envUrl}.com/Shared/assets/js/fffPactSafe.min.js`;
  if (!ScriptsExists.scriptExists(scriptSrc)) {
    const blockScript = document.createElement('script');
    blockScript.src = scriptSrc;
    blockScript.type = 'text/javascript';
    document.head.appendChild(blockScript);
  }
  return true;
}
