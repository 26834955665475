import { createContext, useMemo, type FunctionComponent, type PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useUserContext } from '@fff-web/fff-utilities';
import { getEnv } from '../../environment';

export const ProspectNavContext = createContext({
  env: getEnv(),
  mainProps: {},
  segmentTrackingData: {},
  isMobile: false,
  isTablet: false,
});

// TODO: Correctly define this type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProspectNavProvider: FunctionComponent<PropsWithChildren<{ state: any }>> = ({
  children,
  state: { env, variationKey, ...props },
}) => {
  const { userInfo } = useUserContext();
  const isTablet = useMediaQuery({
    query: '(min-width:768px) and (max-width:992px)',
  });
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const contextValues = useMemo(() => {
    const segmentTrackingData = {
      useId: userInfo?.user?.userId,
      sessionCreationDate: userInfo?.user?.subscriptionStartDate,
    };

    return {
      env: getEnv(env),
      segmentTrackingData,
      isMobile: !isDesktop && !isTablet,
      isTablet,
      mainProps: {
        variationKey,
        ...props,
      },
    };
  }, [
    userInfo?.user?.userId,
    userInfo?.user?.subscriptionStartDate,
    isDesktop,
    isTablet,
    env,
    variationKey,
    props,
  ]);

  return (
    <ProspectNavContext.Provider value={contextValues}>{children}</ProspectNavContext.Provider>
  );
};
