const adaStyle = `
<style>
.show-button {
  display: block !important;
}

.hide-button {
  display: none !important;
}

/*
  You can position the custom Ada button in the lower right corner
  */
#custom-ada-chat-button {
  position: fixed;
  padding: 10px;
  right: 20px;
  bottom: 10px;
}

/*
  By default we hide the Ada button
*/
#ada-button-frame {
  display: none;
}
</style>`;

const adaScript = `
var liveChatId = 'fabfitfun.com-ada';
var liveChatButtonSelector = '#custom-ada-chat-button';
var liveChatStarted = false;
var liveChatEnded = false;

function setLastChatOpened(name) {
  sessionStorage.setItem('lastChatOpened', name);
}

function hideAda() {
  window.adaEmbed.getInfo().then((info) => {
    if (info.isChatOpen) {
      window.adaEmbed.toggle();
      const adaButton = document.querySelector('#ada-button-frame');
      adaButton.classList.remove('show-button');
    }
  });
}

function displayAda() {
  window.adaEmbed.getInfo().then((info) => {
    if (!info.isChatOpen) {
      const adaButton = document.querySelector('#ada-button-frame');
      window.adaEmbed.toggle();
      adaButton.classList.add('show-button');
      setLastChatOpened('ada');
    }
  });
}

function hideLiveChatButton(data, eventInfo) {
  if (!liveChatStarted || liveChatEnded) {
    const liveChatButton = document.querySelector(liveChatButtonSelector);
    liveChatButton.classList.add('hide-button');
    displayAda();
  }
}

function handleLiveChatState(data, eventInfo) {
  const liveChatState = data.state;
  if (liveChatState === 'ended' || liveChatState === 'postChat' || liveChatState === 'applicationEnded') {
    liveChatEnded = true;
  }
}

function checkLastChatOpened() {
  let lastChatOpened = sessionStorage.getItem('lastChatOpened');

  if (lastChatOpened !== 'ada') {
    const adaButton = document.querySelector("#ada-button-frame");
    adaButton.classList.add("show-button");
  }
}

function startLiveChat(handoffParams) {
  hideAda();

  lpTag.taglets.rendererStub.click(liveChatId, {});
  liveChatStarted = true;
  setLastChatOpened('ada');

  const userInfo = {
    "type": "personal",
    "personal": {
      "firstName": handoffParams.firstName,
      "contacts": [{
        "email": handoffParams.email,
      }],
    },
  };
  lpTag.sdes.send(userInfo);
}

  window.adaSettings = {
    adaReadyCallback: () => {
      const adaButton = document.querySelector('#ada-button-frame');
      checkLastChatOpened();
    },
    eventCallbacks: {
      "custom_handoff": event => {
        const handoffParams = {
          firstName: event.user_data.global.firstname,
          email: event.user_data.global.email,
        };

        startLiveChat(handoffParams);
      }
    }
  }`;

const gladlyScript = `
  !function(c,n,r,t){if(!c[r]){var i,d,p=[];
  d="PROD"!==t&&t?"STAGING"===t?"https://cdn.gladly.qa/gladly/chat-sdk/widget.js":
  t:"https://cdn.gladly.com/chat-sdk/widget.js",c[r]={init:function(){i=arguments;
  var e={then:function(t){return p.push({type:"t",next:t}),e},
  catch:function(t){return p.push({type:"c",next:t}),e}};return e}},
  c.onHelpAppHostReady=function(t){if(delete c.onHelpAppHostReady,(c[r]=t).loaderCdn=d,i)
  for(var e=t.init.apply(t,i),n=0;n<p.length;n++)
  {var a=p[n];e="t"===a.type?e.then(a.next):e.catch(a.next)}},
  function(){try{var t=n.getElementsByTagName("script")[0],e=n.createElement("script");
  e.async=!0,e.src=d+"?q="+(new Date).getTime(),t.parentNode.insertBefore(e,t)}catch(t){}}()}}
  (window,document,'Gladly','PROD')
`;
export { adaStyle, adaScript, gladlyScript };
