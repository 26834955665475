const webviewRules: string[] = [
  // Explicit mention of WebView in the user agent
  'WebView',
  // iOS WebView: Matches iOS devices but excludes Safari browser
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android WebView: Detects "wv" (Lollipop and above) or Version/Chrome pattern (KitKat to Lollipop)
  'Android.*(wv|Version/\\d\\.\\d\\sChrome/\\d+\\.\\d+\\.\\d+\\.\\d+)',
  // Old Android WebView user agent pattern
  'Linux; U; Android',
];

const webviewRegExp: RegExp = new RegExp(`(${webviewRules.join('|')})`, 'i');

const isWebView = (userAgent: string = navigator.userAgent): boolean =>
  webviewRegExp.test(userAgent);

export default isWebView;
