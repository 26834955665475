const scriptExists = (srcPath: string): boolean => {
  const scripts = document.getElementsByTagName('script');
  let i = scripts.length;
  let flag = false;
  while (i--) {
    if (scripts[i].src === srcPath) {
      flag = true;
      break;
    }
  }
  return flag;
};

const scriptExistsById = (scriptId: string): boolean => {
  const script = document.getElementById(scriptId);
  if (script) return script.id === scriptId;
  return false;
};

export { scriptExists, scriptExistsById };
