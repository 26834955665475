import { useContext } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import GlobalCart from '@fff-web/fff-cart';
import fffLogo from '../../assets/fff-logo.svg';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import useInView from '../../hooks/useInView';
import HamburgerMenuIcon from '../HamburgerMenuIcon';
import NavigationMenu from '../NavigationMenu';
import './Header.css';

const HeaderContent = ({
  isLoggedIn = false,
  isDesktop = false,
  isNavigationMenuVisible = false,
  handleToggleNavigationMenuVisible,
  routes = {},
  userInfo = {},
}) => {
  const { env, mainProps, newMemberState, onNewMemberStateFetched } =
    useContext(GlobalNavInternalContext);
  const baseUrl = env.REACT_APP_BASE_URL;
  const { isInView, elementRef: leftContainerRef } = useInView();
  const stickyCart = mainProps.stickyCartPage && !isInView;

  return (
    <>
      <div className="header__content">
        {!isDesktop ? (
          <>
            <HamburgerMenuIcon
              isOpen={isNavigationMenuVisible}
              onClick={handleToggleNavigationMenuVisible}
            />
            <a className="fff-logo" href={baseUrl}>
              <img src={fffLogo} alt="logo" />
            </a>
          </>
        ) : (
          <a className="fff-logo" href={baseUrl}>
            <img src={fffLogo} alt="logo" className="trademark" />
          </a>
        )}
        {!isDesktop &&
          (isLoggedIn ? (
            <div className="shopping-cart">
              {!mainProps.hideCart && (
                <GlobalCart
                  {...mainProps}
                  env={env.name}
                  newMemberState={newMemberState}
                  onNewMemberStateFetched={onNewMemberStateFetched}
                />
              )}
            </div>
          ) : (
            <a className="header__join" href={`${baseUrl}/get-the-box/?step=getbox&#getstarted`}>
              JOIN
            </a>
          ))}
      </div>
      {isNavigationMenuVisible && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="header__overlay" onClick={handleToggleNavigationMenuVisible} />
      )}
      <div className="left-container" ref={leftContainerRef}>
        <NavigationMenu
          routes={routes}
          isVisible={isNavigationMenuVisible}
          isDesktop={isDesktop}
          isLoggedIn={isLoggedIn}
          userInfo={userInfo}
          stickyCart={stickyCart}
          env={env.name}
          newMemberState={newMemberState}
          onNewMemberStateFetched={onNewMemberStateFetched}
        />
      </div>
    </>
  );
};

HeaderContent.propTypes = {
  isLoggedIn: bool,
  isDesktop: bool,
  isNavigationMenuVisible: bool,
  handleToggleNavigationMenuVisible: func,
  routes: shape({
    loginState: arrayOf(shape()),
    logoutState: arrayOf(shape()),
  }),
  userInfo: shape({
    firstName: string,
    lastName: string,
    role: string,
    subscriptionState: string,
    subscriptionType: string,
    avatar: string,
  }),
};

export default HeaderContent;
