import { useEffect, type FunctionComponent } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';
import { PromoCodeUtils } from '@fff-web/fff-payments';
import { useDataContext } from '../../../context/DataContext';
import { useInternalContext } from '../../../context/InternalContext';
import { SALE_TYPES } from '../../../services/utils/campaign';
import { COUNTRIES } from '../../../services/utils/constants';
import { formatCurrency, formatCurrencyInCents } from '../../../services/utils/currency';
import { PAGE } from '../../../services/utils/page';
import track, { segmentEvents } from '../../../services/utils/segment';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import CheckoutButton from '../CheckoutButton/CheckoutButton';
import CouponNotice from '../CouponNotice/CouponNotice';
import ShippingProgressBar from '../ShippingProgressBar/ShippingProgressBar';
import * as styles from './CartTotal.module.css';

const TAX_LABELS = {
  GREAT_BRITAIN: 'VAT (Import)',
  CANADA: 'Taxes (Import/Border)',
};

type CreditProps = {
  creditName: string;
  tooltipText: string;
  appliedCredit: number;
  id: string;
};

const Credit: FunctionComponent<CreditProps> = ({ creditName, tooltipText, appliedCredit, id }) => (
  <div className={styles.item}>
    <p data-testid={`${id}-credit-label`}>
      {creditName}
      {tooltipText && (
        <button
          data-tip={tooltipText}
          className="cart-button"
          type="button"
          data-for={id}
          data-html
        >
          <i className="fas fa-info-circle" />
        </button>
      )}
    </p>
    <p data-testid={`${id}-credit-amount`}>{`-$${appliedCredit}`}</p>
  </div>
);

type CartTotalProps = {
  shippingAmount: number;
  additionalShippingAmount: number;
  remainingSpendToFreeShipping: number;
  taxAmount: number;
  total: number;
  hasAppliedPurchaseCredit: boolean;
  hasAppliedSaleCredit: boolean;
  appliedPurchaseCredit: number;
  appliedSaleCredit: number;
  showShipping: boolean;
  trackingData: object;
  campaignType: string;
  displayTax: boolean;
  userCountry: string;
  taxError: boolean;
  hasAppliedShoppingCredit: boolean;
  appliedShoppingCredit: number;
  isPublicShopping: boolean;
  subtotal: number;
  selfCheckoutFF: boolean;
  flashSaleDiscountCouponsFF: boolean;
  minimumTotalFreeShippingPublicShop: number;
  tab: string;
};

const CartTotal: FunctionComponent<CartTotalProps> = ({
  shippingAmount,
  additionalShippingAmount,
  remainingSpendToFreeShipping,
  taxAmount,
  total,
  hasAppliedPurchaseCredit,
  hasAppliedSaleCredit,
  appliedPurchaseCredit,
  appliedSaleCredit,
  showShipping,
  trackingData,
  campaignType,
  displayTax,
  userCountry,
  taxError,
  hasAppliedShoppingCredit,
  appliedShoppingCredit,
  isPublicShopping,
  subtotal,
  selfCheckoutFF,
  flashSaleDiscountCouponsFF,
  minimumTotalFreeShippingPublicShop,
  tab,
}) => {
  const { shoppingCreditFF } = useDataContext();
  const { page } = useInternalContext();
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const creditTooltip = {
    PURCHASE: {
      label: 'Promotional Credit',
      content: null,
      id: 'purchase',
    },
    SEASON: {
      label: 'Add-Ons Credit',
      content: 'Add-Ons Credit is only applicable to the current sale.',
      id: 'sale',
    },
    EDIT: {
      label: 'Edit Sale Credit',
      content: 'Edit Sale Credit is only applicable to the current sale.',
      id: 'sale',
    },
    SHOPPING: {
      label: 'Shopping Credit',
      content:
        'Shopping credit will be applied to your next applicable FabFitFun purchase, excluding membership fees, gift cards, and taxes. <a href="https://legal.fabfitfun.com/#rewards-program-terms" target="_blank" rel="noopener noreferrer">Learn more</a>.',
      id: 'shopping',
    },
  };
  const purchaseCreditTooltip = creditTooltip.PURCHASE;
  const { label: shoppingLabel, content: shoppingContent, id: shoppingId } = creditTooltip.SHOPPING;
  const saleCreditTooltip = get(creditTooltip, campaignType);
  const isShopCampaign = campaignType === SALE_TYPES.SHOP;
  const isEditCampaign = campaignType === SALE_TYPES.EDIT;
  const displayEditCheckout =
    isEditCampaign &&
    selfCheckoutFF &&
    page !== PAGE['new-member-customize'] &&
    page !== PAGE['new-member-boosts'];
  const displayCouponCodeNotice = isEditCampaign && flashSaleDiscountCouponsFF;
  const hasAppliedCoupon = PromoCodeUtils.hasAppliedCoupon(campaignType);

  const remainingAmountForFreeShipping = () => {
    const remainingAmount = isPublicShopping
      ? minimumTotalFreeShippingPublicShop - subtotal
      : remainingSpendToFreeShipping;
    return remainingAmount > 0 ? remainingAmount : 0;
  };
  const minimumTotalForFreeShipping = () => {
    return isPublicShopping
      ? minimumTotalFreeShippingPublicShop
      : remainingSpendToFreeShipping + subtotal;
  };

  const hasShipping = remainingAmountForFreeShipping() > 0;

  const trackCheckout = (ctaText: string) => {
    track(segmentEvents.checkoutStarted, {
      ...trackingData,
      cta_name: ctaText,
      cart_tab: tab,
      shipping: formatCurrencyInCents(shippingAmount),
      discount: appliedPurchaseCredit,
      value: formatCurrencyInCents(total),
      currency: 'USD',
    });
  };

  const isUSUser = userCountry === COUNTRIES.us;

  return (
    <div
      className={classNames(styles.container, {
        [styles.shopCampaign]: isShopCampaign,
      })}
      data-testid="cart-total-container"
    >
      {hasAppliedShoppingCredit && shoppingCreditFF && (
        <Credit
          tooltipText={shoppingContent}
          creditName={shoppingLabel}
          appliedCredit={appliedShoppingCredit}
          id={shoppingId}
        />
      )}
      {hasAppliedSaleCredit && (
        <Credit
          tooltipText={get(saleCreditTooltip, 'content')}
          creditName={get(saleCreditTooltip, 'label')}
          appliedCredit={appliedSaleCredit}
          id={get(saleCreditTooltip, 'id')}
        />
      )}
      {hasAppliedPurchaseCredit && (
        <Credit
          tooltipText={get(purchaseCreditTooltip, 'content') || ''}
          creditName={get(purchaseCreditTooltip, 'label')}
          appliedCredit={appliedPurchaseCredit}
          id={get(purchaseCreditTooltip, 'id')}
        />
      )}
      {showShipping && (
        <div className={styles.item}>
          <div className={styles.shipping} data-testid="cart-total-shipping">
            <p>Shipping</p>
            {isUSUser &&
              (hasShipping ? (
                <p data-testid="shipping-remaining-spend">
                  Add
                  <span>{` $${formatCurrencyInCents(remainingAmountForFreeShipping())} `}</span>
                  to get free shipping in contiguous US
                </p>
              ) : (
                <p data-testid="shipping-free-qualify-notice">
                  You qualify for <span>FREE</span> shipping in contiguous US
                </p>
              ))}
          </div>
          <p data-testid="shipping-amount">
            {hasShipping ? `$${formatCurrencyInCents(shippingAmount)}` : 'Free'}
          </p>
        </div>
      )}
      <ShippingProgressBar
        estimatedTotal={subtotal}
        totalRequired={minimumTotalForFreeShipping()}
      />
      {showShipping && additionalShippingAmount > 0 && (
        <div className={styles.item}>
          <p>Add&apos;l Shipping & Handling</p>
          <p data-testid="additional-shipping">{`$${formatCurrencyInCents(
            additionalShippingAmount,
          )}`}</p>
        </div>
      )}
      {displayTax && !taxError && (
        <div className={styles.item} data-testid="tax">
          <p data-testid="tax-label">{TAX_LABELS[userCountry as keyof typeof TAX_LABELS]}</p>
          <p data-testid="tax-amount">{`$${formatCurrency(taxAmount)}`}</p>
        </div>
      )}
      <div className={styles.total}>
        <p data-testid="total-label">{taxError ? 'Total (Excluding Tax)' : 'Estimated Total'}</p>
        <p data-testid="total">{`$${formatCurrencyInCents(total)}`}</p>
      </div>
      {(isShopCampaign || displayEditCheckout) && (
        <CheckoutButton
          isShopCampaign={isShopCampaign}
          isEditCampaign={isEditCampaign}
          isPublicShopping={isPublicShopping}
          displayEditCheckout={displayEditCheckout}
          trackCheckout={trackCheckout}
        />
      )}
      {displayCouponCodeNotice && <CouponNotice hasAppliedCoupon={hasAppliedCoupon} />}
      <Tooltip
        //this tooltip faces up in local build but not when imported into Pandora
        place="right"
        id="sale"
        className={styles.tooltip}
      />
      <Tooltip
        //this tooltip faces up in local build but not when imported into Pandora
        place="right"
        id="purchase"
        className={styles.tooltip}
      />
      <Tooltip
        //this tooltip faces up in local build but not when imported into Pandora
        place="right"
        id="shopping"
        className={styles.tooltip}
        delayHide={1000}
      />
    </div>
  );
};

export default CartTotal;
