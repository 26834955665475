/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  cssTransition,
  toast,
  ToastContainer,
  ToastContainerProps,
  ToastOptions,
} from 'react-toastify';
import 'animate.css/animate.min.css';
import FontAwesome from '../FontAwesome';
import './Toast.css';
import type { ElementType, FunctionComponent } from 'react';
import {
  CloseToastProps,
  FFFToastProps,
  ToastContentType,
  ToastIconType,
  ToastProps,
  ToastType,
} from './interface';
import parseContent from './utils/parseContent';

const CART_TOAST_CLASSNAME = 'cart-toast';

const Fade = cssTransition({
  enter: 'animate__animated animate__fadeInDown',
  exit: 'animate__animated animate__fadeOutUp',
  collapseDuration: 1000,
});

export const showToast = toast;

const getIcon = (type: ToastIconType) => {
  switch (type) {
    case 'success':
      return 'fas fa-check-circle';
    case 'error':
      return 'fas fa-exclamation-circle';
    case 'warning':
      return 'fas fa-exclamation-circle';
    default:
      return '';
  }
};

const CustomToastBody: FunctionComponent<{
  icon?: ToastIconType;
  title: string;
  content: ElementType;
}> = ({ icon, title, content: Content }) => (
  <div className="toast-body">
    <FontAwesome />
    {icon && <i className={`icon ${getIcon(icon)}`} />}
    <div className={`content ${Content ? 'rows' : 'row'}`}>
      {title && <h3>{title}</h3>}
      <p className="toast-content">
        <Content />
      </p>
    </div>
  </div>
);

export const ToastBody: FunctionComponent<ToastProps> = ({ icon, title, content, onClick }) => {
  const parsedContent = typeof content === 'object' ? parseContent(content) : content;

  return (
    /* TODO: Please fix the a11y issues with this element and then remove the comments at the top of the file. */
    <div className="toast-body" role="button" onClick={onClick}>
      <FontAwesome />
      {icon && <i className={`icon ${getIcon(icon)}`} />}
      <div className={`content ${content ? 'rows' : 'row'}`}>
        <h3>{title}</h3>
        {parsedContent && (
          <p className="toast-content" dangerouslySetInnerHTML={{ __html: parsedContent }} />
        )}
      </div>
    </div>
  );
};

const CloseButton: FunctionComponent<CloseToastProps> = ({ onCloseToast, closeToast }) => {
  const onClick = () => {
    onCloseToast && onCloseToast();
    closeToast && closeToast();
  };

  return (
    <button
      type="button"
      className="close-button"
      onClick={onClick}
      data-testid="toast-close-button"
    >
      <i className="fal fa-times" />
    </button>
  );
};

export const Toast: FunctionComponent<ToastContainerProps & FFFToastProps> = ({
  onCloseButton,
  autoClose = 6000,
  isCart = false,
  containerId,
  ...rest
}) => (
  <ToastContainer
    enableMultiContainer
    containerId={containerId}
    position="top-center"
    className={isCart ? CART_TOAST_CLASSNAME : ''}
    autoClose={autoClose}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss={false}
    draggable={false}
    pauseOnHover={false}
    transition={Fade}
    closeButton={<CloseButton onCloseToast={onCloseButton} />}
    icon={false}
    theme="colored"
    {...rest}
  />
);

type DismissToast = (containerId?: number | string | undefined) => void;

type ShowToast = (
  title: string,
  content?: ToastContentType,
  options?: ToastOptions & { onClick: () => void },
) => void;

type ShowCustomToast = (
  toastType: ToastType,
  title: string,
  content: ElementType,
  options?: ToastOptions,
) => void;

export const showErrorToast: ShowToast = (title, content, options) => {
  showToast.error(
    <ToastBody icon="error" title={title} content={content} onClick={options?.onClick} />,
    options,
  );
};

export const showSuccessToast: ShowToast = (title, content, options) => {
  showToast.success(
    <ToastBody icon="success" title={title} content={content} onClick={options?.onClick} />,
    options,
  );
};

export const showWarningToast: ShowToast = (title, content, options) => {
  showToast.warning(
    <ToastBody icon="warning" title={title} content={content} onClick={options?.onClick} />,
    options,
  );
};

export const showInfoToast: ShowToast = (title, subtitle, options) => {
  showToast.info(
    <ToastBody title={title} content={subtitle} onClick={options?.onClick} />,
    options,
  );
};

export const showCustomToast: ShowCustomToast = (toastType, title, content, options) => {
  showToast[toastType](
    <CustomToastBody icon={toastType as ToastIconType} title={title} content={content} />,
    options,
  );
};

export const dismissAll: DismissToast = (containerId) => toast.dismiss(containerId);
