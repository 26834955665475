import { Fallbacks } from '../types';

const shopFallbacks: Fallbacks = {
  ['shop']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/shop_banner_desktop.jpg',
    title: '',
    cta: 'SHOP NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/shop_banner_mobile.jpg',
    subtitle: [
      {
        type: 'heading',
        level: 1,
        children: [
          {
            text: 'Deals for ',
            type: 'text',
          },
          {
            bold: true,
            text: 'Days',
            type: 'text',
            italic: true,
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Browse The Shop where Members can earn 5% in FFF Rewards on qualifying purchases (terms apply).',
            type: 'text',
          },
        ],
      },
    ],
  },
};

export default shopFallbacks;
