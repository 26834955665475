import type { FunctionComponent } from 'react';
import { getEnv } from '../../utils/environment';
import { activateMembership } from './boxButtonLinks';
import { NOT_SUBSCRIBED } from './boxStatusId';

type BoxTextBodyLinksProps = {
  boxStatusId: string;
  trackingUrl: string;
  showServiceLink: boolean;
  trackElements: (content: string) => void;
};

const BoxTextBodyLinks: FunctionComponent<BoxTextBodyLinksProps> = ({
  boxStatusId,
  trackingUrl,
  showServiceLink,
  trackElements,
}) => (
  <>
    {boxStatusId === NOT_SUBSCRIBED && (
      <a href={activateMembership.href} className="highlighted">
        Activate Membership
      </a>
    )}
    {showServiceLink && (
      <a href={getEnv().REACT_APP_CUSTOMER_SERVICE_URL} className="highlighted">
        Contact Customer Service
      </a>
    )}
    {!!trackingUrl && (
      <a
        href={trackingUrl}
        onClick={() => trackElements('Tracking Number')}
        className="challenge-highlighted"
      >
        Tracking Number
      </a>
    )}
  </>
);

export default BoxTextBodyLinks;
