import { Fallbacks } from '../types';

const customizeFallbacks: Fallbacks = {
  ['customization-closed']: {
    cta: 'VIEW MY BOX',
    imageDesktop:
      'https://scontent01.fabfitfun.com/gtb/cdn/customization_closed_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/customization_closed_banner_mobile.jpeg',
    subtitle: undefined,
    title: 'Customization is Closed for the Season',
  },
  ['upgrade-to-annual-customize']: {
    imageDesktop:
      'https://scontent01.fabfitfun.com/my-account/cdn/upgrade-page-hero-desktop_banner.png',
    title: ['Last Chance!', 'Save $60 by Upgrading to Annual'],
    cta: null,
    imageMobile:
      'https://scontent01.fabfitfun.com/my-account/cdn/upgrade-page-hero-mobile-banner.png',
    subtitle: null,
  },
};

export default customizeFallbacks;
