import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { trackElement } from '@fff-web/fff-analytics';
import { handleTrackAndRedirect } from '../../utils/track';
import { links, notSubscribedLinks } from './boxButtonLinks';

const NOT_SUBSCRIBED = 'NOT_SUBSCRIBED';
const INVOICE_HISTORY_LABEL = 'INVOICE HISTORY';
const ORDERS_BUTTON = {
  label: 'ORDERS',
  href: `${process.env.REACT_APP_SITE_URL}/my-account/orders`,
};

const replaceInvoiceHistory = (items: { label: string; href: string }[]) =>
  items.map((item) => {
    if (item.label === INVOICE_HISTORY_LABEL) return ORDERS_BUTTON;
    return item;
  });

type QuickLinksProps = {
  statusId?: string;
  flags?: {
    ordersButton?: boolean;
  };
};

const QuickLinks = ({ statusId = '', flags }: QuickLinksProps) => {
  const isSubscribed = statusId !== NOT_SUBSCRIBED;
  const boxLinks = isSubscribed ? links : notSubscribedLinks;
  const displayedLinks = flags && flags.ordersButton ? replaceInvoiceHistory(boxLinks) : boxLinks;

  return (
    <div className="buttons-container">
      {displayedLinks.map(({ href, label }) => (
        <button
          type="button"
          key={href}
          onClick={() =>
            handleTrackAndRedirect(
              () => trackElement({ elementName: `${label} - BOX STATUS` }),
              href,
            )
          }
          className="mybox-button"
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default withLDConsumer()(QuickLinks);
