import { stringify } from 'query-string';
import request from './request';
import { getCampaignIdQueryParam, getCampaignStatusQueryParam } from '../utils/page';

const campaignIdQueryParam = getCampaignIdQueryParam();

const campaignStatusQueryParam = getCampaignStatusQueryParam();

/**
 * Fetches the campaigns data
 * @param {string} campaignType - The type of campaign
 * @param {string} env - The environment
 * @param {boolean} isPublicShopping - Whether the cart is being fetched for public shopping
 * @returns {object} The campaigns data
 */
export async function fetchCampaignsData(campaignType, env, isPublicShopping) {
  const queryParams = {
    ...(campaignIdQueryParam && { campaignType }),
    ...(campaignIdQueryParam && { campaignId: campaignIdQueryParam }),
    ...(campaignStatusQueryParam && {
      campaignStatus: campaignStatusQueryParam,
    }),
  };
  const isCampaignWithQueryParam = campaignIdQueryParam
    ? `api/1/campaigns/default?${stringify(queryParams)}`
    : 'api/1/campaigns/default';
  const endpoint = isPublicShopping ? `public/api/1/campaigns?type=SHOP` : isCampaignWithQueryParam;
  // TODO, delete try useless on this file
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request(endpoint, 'GET', env);
    return response;
  } catch (err) {
    throw err;
  }
}
