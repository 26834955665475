import { REQUEST_MY_BOX, REQUEST_MY_BOX_SUCCESS, REQUEST_MY_BOX_FAIL } from './actionTypes';
import { getInitialValueFromStorage, setInitialValueToStorage } from './helpers/myBoxStatus';

const myBoxKey = 'myBox2.0';

export const initialState = () => ({
  myBox: getInitialValueFromStorage(myBoxKey, {}),
});

export const myBoxStatusReducer = (state, action) => {
  switch (action.type) {
    case REQUEST_MY_BOX:
      return {
        ...state,
        myBox: { ...state.myBox, loading: true, fail: false },
      };
    case REQUEST_MY_BOX_SUCCESS:
      setInitialValueToStorage(myBoxKey, action.payload);
      return {
        ...state,
        myBox: { data: action.payload, loading: false, fail: false },
      };
    case REQUEST_MY_BOX_FAIL:
      return {
        ...state,
        myBox: { ...state.myBox, loading: false, fail: true },
      };
    default:
      return state;
  }
};

export const actions = {
  setMyBox: () => ({ type: REQUEST_MY_BOX }),
  setMyBoxSuccess: (data) => ({ type: REQUEST_MY_BOX_SUCCESS, payload: data }),
  setMyBoxFail: (error) => ({ type: REQUEST_MY_BOX_FAIL, payload: error }),
};
