import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { UserProvider } from '@fff-web/fff-utilities';
import { MyBoxProvider } from '@fff-web/fff-box';
import { initialize } from '@fff-web/fff-analytics';
import App from './App';
import { UniversalNavigationContextProvider } from '@fff-web/fff-global-nav';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

globalThis.window.FFF = { ...globalThis.window.FFF };
globalThis.window.FFF[process.env.APP_NAME] = {
  version: process.env.APP_VERSION,
  libs: process.env.APP_LIBS,
};

TagManager.initialize(tagManagerArgs);
initialize().then();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <UserProvider>
      <MyBoxProvider>
        <UniversalNavigationContextProvider>
          <App />
        </UniversalNavigationContextProvider>
      </MyBoxProvider>
    </UserProvider>
  </StrictMode>,
);
