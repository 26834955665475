import type { FunctionComponent } from 'react';
import * as styles from './CartOverlay.module.css';

type CartOverlayProps = {
  closeCart: () => void;
};

const CartOverlay: FunctionComponent<CartOverlayProps> = ({ closeCart }) => (
  //TODO: The divs shouldn't contain functions for onClick or onKeyDown, change this div

  <div
    className={styles.container}
    onClick={closeCart}
    role="button"
    onKeyDown={closeCart}
    tabIndex={0}
  />
);

export default CartOverlay;
