import type { FunctionComponent } from 'react';
import classNames from 'classnames';
import ReactTooltip, { type TooltipProps } from 'react-tooltip';
import * as styles from './Tooltip.module.css';

type TooltipCustomProps = {
  place: TooltipProps['place'];
  id: string;
  className: string;
  delayHide?: number;
};

const Tooltip: FunctionComponent<TooltipCustomProps> = ({
  place,
  id,
  className,
  delayHide = 0,
}) => (
  <ReactTooltip
    className={classNames(styles.tooltip, className)}
    place={place}
    type="dark"
    effect="solid"
    id={id}
    delayHide={delayHide}
  />
);

export default Tooltip;
