import type { CSSProperties, FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { default as ReactModal } from 'react-modal';
import CloseButton from '../CloseButton';
import * as styles from './Modal.module.css';

type ModalProps = {
  isOpen: boolean;
  children: ReactNode;
  onRequestClose?: () => void | undefined;
  shouldCloseOnOverlayClick?: boolean | undefined;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  showCloseButton?: boolean;
  borderless?: boolean;
};

const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  children,
  onRequestClose,
  shouldCloseOnOverlayClick,
  style,
  className,
  showCloseButton = false,
  borderless = false,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 9999999,
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
      },
      content: {
        position: showCloseButton ? 'relative' : 'static',
        alignSelf: 'center',
        margin: 'auto',
        border: borderless ? undefined : '1px solid #ffdcd1',
        borderRadius: '6px',
        ...style,
      },
    }}
    className={classNames(styles.modalContent, className)}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    testId="modal"
    bodyOpenClassName={styles.openBody}
    ariaHideApp={false}
  >
    {showCloseButton && (
      <span className={styles.closeButtonContainer}>
        <CloseButton onClick={() => onRequestClose?.()} />
      </span>
    )}
    {children}
  </ReactModal>
);

export default Modal;
