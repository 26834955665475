import { ICookieInfo } from '../../interfaces';

export const calculateExpirationDate = (expireDate: ICookieInfo['expireDate']) => {
  const expires = `expires=${new Date(expireDate ?? '').toUTCString()}`;
  return expires;
};

export const setCookie = ({ key, value, expireDate, path = '/', maxAge, domain }: ICookieInfo) => {
  const encodeValue = encodeURIComponent(value);
  const expires = calculateExpirationDate(expireDate);
  const maxAgeValue = `max-age=${60 * 60 * 24 * maxAge}`;
  const domainValue = domain ? `domain=${domain};` : '';

  document.cookie = `${key}=${encodeValue}; ${
    expireDate ? `${expires};` : `${maxAgeValue};`
  } ${domainValue} path=${path}`;

  return getCookie(key) === encodeValue;
};

export const getCookie = (key: string) => {
  const cookieName = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  const foundCookie = cookieArray.find((cookie) => cookie.trim().startsWith(cookieName))?.trim();

  if (foundCookie) {
    return foundCookie.substring(cookieName.length);
  }

  return null;
};

export const deleteCookie = (key: string) => {
  if (key) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    return true;
  }
  return false;
};
