import Layout from '../../components/Layout';
import Banner from './Banner';
import HowItWorks from './HowItWorks';
import MoreInfo from './MoreInfo';
import './styles.css';

const SupplierDiversity = () => (
  <Layout>
    <Banner />
    <HowItWorks />
    <MoreInfo />
  </Layout>
);

export default SupplierDiversity;
