import { string, bool, oneOfType, node, func, arrayOf, shape } from 'prop-types';
import BoxTimeline from '../Timeline/BoxTimeline';
import AddressDetails from './AddressDetails';
import CollapsiblePanel from '../CollapsiblePanel';
import Status from './Status';
import { getEnv } from '../../utils/environment';

const ChallengeBoxMobile = ({
  wizmoMode,
  showAddressDetails = false,
  name,
  id,
  title,
  body,
  bodyDetail,
  trackingUrl,
  screenMode = false,
  orderSteps,
  fail,
  showReviewButton = false,
  currentOrderStep,
  shippingAddress,
  imageURL,
  RightContent = null,
  showServiceLink,
  trackElements = () => null,
}) => (
  <CollapsiblePanel
    title={screenMode.indexOf('desktop') === -1 && `${name ? name.toUpperCase() : 'BOX '} STATUS`}
  >
    <div className="mybox-container">
      <Status
        imageURL={imageURL}
        name={name}
        id={id}
        title={title}
        body={body}
        trackingUrl={trackingUrl}
        showReviewButton={showReviewButton}
        hasFailed={fail}
        currentOrderStep={currentOrderStep}
        wizmoMode={wizmoMode}
        isMobile
        trackElements={trackElements}
      />
      {showServiceLink && (
        <a href={getEnv().REACT_APP_CUSTOMER_SERVICE_URL} className="highlighted">
          Contact Customer Service
        </a>
      )}
      <BoxTimeline
        nodes={orderSteps}
        bodyDetail={bodyDetail}
        currentStep={currentOrderStep}
        trackingUrl={trackingUrl}
        isMobile
      />
      {(showAddressDetails || !wizmoMode) && (
        <>
          <div className="challenge-divider" />
          <AddressDetails
            shippingAddress={shippingAddress}
            hasFailed={fail}
            boxStatusId={id}
            wizmoMode={wizmoMode}
          />
          <div className="challenge-divider" />
        </>
      )}
      {RightContent && <RightContent statusId={id} />}
      <div className="challenge-last-divider" />
    </div>
  </CollapsiblePanel>
);

ChallengeBoxMobile.propTypes = {
  screenMode: string,
  showReviewButton: bool,
  RightContent: oneOfType([node, func]),
  trackElements: func,
  showAddressDetails: bool,
  wizmoMode: bool,
  name: string,
  id: string,
  title: string,
  body: string,
  bodyDetail: string,
  trackingUrl: string,
  orderSteps: arrayOf(shape),
  fail: bool,
  currentOrderStep: string,
  shippingAddress: shape,
  imageURL: string,
  showServiceLink: bool,
};

export default ChallengeBoxMobile;
