import { useEffect, useState } from 'react';
import { ICustomCheckoutPricing, IUseCustomCheckoutPricing } from '../../interfaces';
import IPricingPreview from '../../interfaces/IPricingPreview';
import { usePricesContext } from '../ContextProviders/PricesContext';
import { getExchangeRate } from '../services/localization';
import { fetchPricingPreview } from '../services/registerApi';
import countryCodes from '../utils/countryCodes';
import formatPrice from '../utils/formatPrice';
import { getPriceByPlanCode } from '../utils/planPrices';

const EXCHANGE_RATE_INITIAL_VALUE = 0;
const PRICING_PREVIEW_INITIAL_VALUE = {
  total_charge_amount: 0,
  tax_amount: 0,
  shipping_fee_amount: 0,
  discount: 0,
  gift_card: 0,
  price_in_gbp: 0,
};

function useDebouncedEffect(effect: () => void, deps: unknown[], delay: number) {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay]);
}

type StandardAPIResponse = Record<string, never>;

const useCustomCheckoutPricing = (
  customPricingObject: IUseCustomCheckoutPricing,
): ICustomCheckoutPricing => {
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isLocalizationLoading, setIsLocalizationLoading] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(EXCHANGE_RATE_INITIAL_VALUE);
  const [pricingPreviewData, setPricingPreviewData] = useState<
    IPricingPreview | StandardAPIResponse
  >(PRICING_PREVIEW_INITIAL_VALUE);
  const [error, setError] = useState<string | null>(null);

  const { prices, loading: isPricesLoading } = usePricesContext();
  const planPrice = getPriceByPlanCode(prices, customPricingObject.planCode);
  const {
    planCode,
    shipping: { country, zipCode, state },
    coupon,
    giftCard: giftCardCode,
  } = customPricingObject;

  const fetchPricingPreviewData = async (data: IUseCustomCheckoutPricing) => {
    try {
      const pricingResponse = await fetchPricingPreview(data);
      setPricingPreviewData(pricingResponse);
    } catch (error: unknown) {
      const e = error as { errors: { msg: string }[] };
      setPricingPreviewData(PRICING_PREVIEW_INITIAL_VALUE);
      if ('errors' in e) {
        setError(e.errors[0]?.msg);
      }
    } finally {
      setIsPreviewLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (country && zipCode && zipCode.length > 4) {
        setIsPreviewLoading(true);
        fetchPricingPreviewData(customPricingObject);
      }
    },
    [country, zipCode, state],
    300,
  );

  useDebouncedEffect(
    () => {
      setIsPreviewLoading(true);
      fetchPricingPreviewData(customPricingObject);
    },
    [coupon, giftCardCode, planCode],
    300,
  );

  useEffect(() => {
    if (country === countryCodes.GB) {
      setIsLocalizationLoading(true);
      getExchangeRate()
        .then((response) => {
          setExchangeRate(response as number);
        })
        .catch(() => {
          setExchangeRate(EXCHANGE_RATE_INITIAL_VALUE);
        })
        .finally(() => {
          setIsLocalizationLoading(false);
        });
    }
  }, [country]);

  const total = formatPrice(pricingPreviewData?.total_charge_amount);
  const taxes = formatPrice(pricingPreviewData?.tax_amount).toFixed(2);
  const shipping = formatPrice(pricingPreviewData?.shipping_fee_amount);
  const discount = (-formatPrice(pricingPreviewData?.discount)).toFixed(2);
  const giftCard = formatPrice(pricingPreviewData?.gift_card);
  const priceInGBP = total * exchangeRate;
  const previewErrors = pricingPreviewData?.errors;

  return {
    total,
    taxes,
    shipping,
    plan: planPrice,
    discount,
    giftCard,
    priceInGBP,
    ...(error && { error }),
    previewErrors,
    isLoading: isPricesLoading || isPreviewLoading || isLocalizationLoading,
  };
};

export default useCustomCheckoutPricing;
