import { useEffect, useReducer } from 'react';

import { actions, homepageReducer, initialState } from '../Store';
import {
  getInviteTotalFromInvitationApi,
  getInviteTotalFromLegacyApi,
} from '../../../services/inviteTotal';

const { getInviteTotalSuccess, getInviteTotalFail } = actions;

const useInviteTotal = (callNewEndpoint) => {
  const [{ inviteTotal }, stateDispatch] = useReducer(homepageReducer, initialState());

  useEffect(() => {
    if (callNewEndpoint !== undefined) {
      if (callNewEndpoint) {
        getInviteTotalFromInvitationApi()
          .then((data) => stateDispatch(getInviteTotalSuccess(data)))
          .catch((error) => stateDispatch(getInviteTotalFail(error)));
      } else {
        getInviteTotalFromLegacyApi()
          .then((data) => stateDispatch(getInviteTotalSuccess(data.invite_remain)))
          .catch((error) => stateDispatch(getInviteTotalFail(error)));
      }
    }
  }, [callNewEndpoint]);
  return inviteTotal;
};

export default useInviteTotal;
