import { FFFStorage } from '@fff-web/fff-utilities';

export const getInitialValueFromStorage = (key: string, defaultValue: unknown) => {
  const data = FFFStorage.getFromStorage({ key });

  if (data && data.data) {
    return { data: data.data, loading: false, fail: false };
  }

  return { data: defaultValue, loading: false, fail: false };
};

export const setInitialValueToStorage = (key: string, value: unknown) =>
  FFFStorage.setToStorage({ key, value: { data: value } });
