import { useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { getMembershipInfo } from '@fff-web/fff-utilities';

/**
 * Use the membership info
 * @returns {object} - The membership info.
 */
export function useMembershipInfo() {
  const [membershipInfo, setMembershipInfo] = useState({});

  useEffect(() => {
    try {
      getMembershipInfo().then((info) => setMembershipInfo(info));
    } catch (error) {
      datadogLogs.logger.error('Unable to get Membership Info', {}, error);
      return;
    }
  }, []);

  return {
    membershipInfo,
  };
}

export default useMembershipInfo;
