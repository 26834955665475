import { getEnv } from '../environment';
import request from './request';

const env = getEnv();
const { REACT_APP_QUESTIONNAIRE_API_URL: questionnaireApi } = env;

interface QuestionType {
  question: {
    id: string | number;
  };
  choices: Array<{
    id: string | number;
  }>;
}
interface AnswersType {
  countryIsoCode: string;
  answers: Array<QuestionType>;
}

export const getQuestionnaire = (variant: string) => {
  return request({
    path: `${questionnaireApi}/public/questionnaires?name=guided-quiz&variation=${variant}`,
  });
};

export const postQuestionnaire = (sessionId: string, answers: AnswersType) => {
  return request({
    path: `${questionnaireApi}/public/answers/sessions/${sessionId}`,
    method: 'POST',
    body: JSON.stringify(answers),
  });
};
