import { CartComponent } from './cart';
import CartContextProvider, { useCartContext } from './context/CartContext';
import {
  getAllCreditValues,
  getCartSubtotal,
  getCartTotal,
  getShipping,
  getTaxLineItems,
} from './services/utils/cartCalculations';
import { convertToDollars, formatCurrency, formatCurrencyInCents } from './services/utils/currency';
import {
  flattenProducts,
  getCartVariants,
  getCount,
  getSocialCartProducts,
  getSocialCartVariants,
  getVariantQuantityLimits,
} from './services/utils/formatResponse';

export {
  CartComponent,
  CartContextProvider,
  convertToDollars,
  flattenProducts,
  formatCurrency,
  formatCurrencyInCents,
  getAllCreditValues,
  getCartSubtotal,
  getCartTotal,
  getCartVariants,
  getCount,
  getShipping,
  getSocialCartProducts,
  getSocialCartVariants,
  getTaxLineItems,
  getVariantQuantityLimits,
  useCartContext,
};

export default CartComponent;
