import { createContext, useContext, type FunctionComponent, type PropsWithChildren } from 'react';
import useMyBoxStatus from '../hooks/useMyBoxStatus';
import defaultContext from './defaultContext';

const MyBoxContext = createContext(defaultContext);

// Make sure to use myBoxProvider one level up from where you want to consume values
const MyBoxProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const myBox = useMyBoxStatus();

  return (
    <MyBoxContext.Provider value={{ myBox, products: { list: [] } }}>
      {children}
    </MyBoxContext.Provider>
  );
};

// This hook is the equivalent of a context consumer
// TO USE: const { clearStorage, userInfo } = useMyBoxContextContext();
// MAKE SURE: <MyBoxProvider> is a level up
export function useMyBoxContext() {
  const context = useContext(MyBoxContext);
  if (!context) {
    throw new Error('You must use the MyBox Provider in order to consume this context.');
  }
  return context;
}

export default MyBoxProvider;
