import { createContext } from 'react';
import { getEnv } from '../../environment';
import { universalNavigationContextDefaultProps } from './UniversalNavigationContext';

interface GlobalNavInternalContextFlags {
  downloadMobileAppWebBannerFF: unknown;
  downloadMobileAppWebBannerReady: boolean;
  ordersCtaFF: unknown;
  ordersCtaReady: boolean;
  webRatingsAndReviewInGlobalNavDropDownFF: unknown;
  webRatingsAndReviewInGlobalNavDropDownReady: boolean;
  ccToastNotificationFF: unknown;
  ccToastNotificationReady: boolean;
  newMemberOnboardingFF: unknown;
  newMemberOnboardingReady: boolean;
}

const GlobalNavInternalContext = createContext({
  env: getEnv(),
  mainProps: {},
  ...universalNavigationContextDefaultProps,
  flags: {} as GlobalNavInternalContextFlags,
});

export default GlobalNavInternalContext;
