import { getEnv } from '../environment';

const { REACT_APP_LOGIN_URL: loginUrl, REACT_APP_BASE_URL: baseUrl } = getEnv();

export const ERROR_CODES = {
  activeUserExists: 'ACTIVE_USER_EXISTS',
  inactiveUserExists: 'INACTIVE_USER_EXISTS',
};

const cta = `${baseUrl}%2Freactivate-my-membership%2F%3Fcoupon_code%3Dgetstarted`;
const getLoginUrl = (errorCode?: string, email?: string) => {
  const link = {
    type: '',
    url: '',
  };
  if (errorCode === ERROR_CODES.activeUserExists) {
    link.type = 'active';
    link.url = `${loginUrl}/web-otp?email=${email || ''}`;
  } else if (errorCode === ERROR_CODES.inactiveUserExists) {
    link.type = 'inactive';
    link.url = `${loginUrl}/web-otp?email=${email || ''}&cta=${cta || ''}`;
  }
  return link;
};

export default getLoginUrl;
