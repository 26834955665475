import { CSSObject } from 'styled-components';
import { ButtonVariation } from './Button';

const primaryCssProperties: CSSObject = {
  border: 'none',
  background: '#ff5b5d',
  color: '#ffffff',
  '&:hover': {
    background: '#fa9c98',
  },
  '&:active': {
    background: '#c75552',
  },
  '&:disabled': {
    background: '#d0d0d0',
    border: 'none',
  },
};

const blackCssProperties: CSSObject = {
  border: 'none',
  background: '#000000',
  color: '#ffffff',
  '&:hover': {
    background: '#9b9b9b',
  },
  '&:active': {
    background: '#000000',
  },
  '&:disabled': {
    background: '#d0d0d0',
    border: 'none',
  },
};

const primaryOutlineCssProperties: CSSObject = {
  border: 'solid 1px #fb6a66',
  background: '#ffffff',
  color: '#ff5b5d',
  '&:hover': {
    border: 'solid 1px #faccca',
  },
  '&:active': {
    border: 'solid 1px #c75552',
  },
  '&:disabled': {
    background: '#d0d0d0',
    border: 'none',
    color: '#ffffff',
  },
};

const blackOutlineCssProperties: CSSObject = {
  border: 'solid 1px #000000',
  background: '#ffffff',
  color: '#000000',
  '&:hover': {
    border: 'solid 1px #d0d0d0',
  },
  '&:active': {
    border: 'solid 1px #9b9b9b',
  },
  '&:disabled': {
    background: '#d0d0d0',
    border: 'none',
    color: '#ffffff',
  },
};

const roundedDefaults: CSSObject = {
  border: 'none',
  borderRadius: 9,
  boxShadow: '0px 10px 20px -5px rgb(0 0 0 / 30%)',
};

const roundedPrimaryCssProperties: CSSObject = {
  ...roundedDefaults,
  background: '#ff5b5d',
  color: '#ffffff',
  '&:hover': {
    background: '#fa9c98',
  },
  '&:active': {
    background: '#c75552',
  },
  '&:disabled': {
    border: 'none',
    background: '#d0d0d0',
  },
};

const roundedBlackCssProperties: CSSObject = {
  ...roundedDefaults,
  background: '#000000',
  color: '#ffffff',
  '&:hover': {
    background: '#9b9b9b',
  },
  '&:active': {
    background: '#000000',
  },
  '&:disabled': {
    border: 'none',
    background: '#d0d0d0',
  },
};

export const getTheme = (type: ButtonVariation): CSSObject => {
  switch (type) {
    case 'primary':
      return primaryCssProperties;
    case 'black':
      return blackCssProperties;
    case 'rounded-primary':
      return roundedPrimaryCssProperties;
    case 'rounded-black':
      return roundedBlackCssProperties;
    case 'primary-outline':
      return primaryOutlineCssProperties;
    case 'black-outline':
      return blackOutlineCssProperties;
  }

  return {};
};
