import _ from 'lodash';
import { calculateCartItemTotal } from './currency';

export const flattenProducts = (products) => {
  const flattenedProducts = {};
  const extractedVariants = {};
  _.forOwn(products, (product, productId) => {
    const { variants, ...rest } = product;
    _.forOwn(variants, (variant, variantId) => {
      extractedVariants[variantId] = variant;
    });
    flattenedProducts[productId] = rest;
  });
  return {
    products: flattenedProducts,
    variants: extractedVariants,
  };
};

export const getCartVariants = (cartDetails, variants, products) => {
  const { variantIds, variantQuantities, variantCartItemTypes } = cartDetails;
  const cartVariants = _.map(variantIds, (id) => {
    const variant = variants[id];
    // cart details (where the list of ids comes from) and variants/products are not updated at the exact same time
    // there is a small chance that the variant is not found
    if (!variant) return {};
    const { salePrice, price, variantDescriptors } = variant;
    const quantity = variantQuantities[id];
    const product = products[variant.parentId];
    const {
      brandName,
      name,
      shareUrl,
      sponsored,
      shippingExempt,
      categoryNames,
      ordering,
      legacyPostId,
      taxExempt,
      premiumProduct,
    } = product;
    const itemDescriptors = variantDescriptors.map((v) => v.descriptor);
    const descriptorString = itemDescriptors.length ? itemDescriptors.toString() : '';
    const productName = brandName ? `${brandName} ${name}` : name;
    const cartDisplayName = descriptorString ? `${productName} ${descriptorString}` : productName;
    const variantType = variantCartItemTypes[id] || null;

    return {
      ...variant,
      id,
      name,
      cartDisplayName,
      quantity,
      shareUrl,
      itemTotal: calculateCartItemTotal(quantity, salePrice, price),
      sponsored,
      shippingExempt,
      taxExempt,
      // segment tracking data
      categories: categoryNames,
      merchPositionOrder: ordering,
      parentLegacyPostId: legacyPostId,
      variantType,
      premiumProduct,
    };
  });
  return _.filter(cartVariants, (variant) => variant.quantity > 0);
};

export const getSocialCartVariants = (variantUsers, variants, products) => {
  const cartVariants = [];
  _.mapKeys(variantUsers, (users, id) => {
    const variant = variants[id];
    const { variantDescriptors } = variant;
    const product = products[variant.parentId];
    const {
      brandName,
      name,
      shareUrl,
      sponsored,
      shippingExempt,
      shippingRestrictions,
      categoryNames,
      ordering,
      legacyPostId,
    } = product;
    const itemDescriptors = variantDescriptors.map((v) => v.descriptor);
    const descriptorString = itemDescriptors.length ? itemDescriptors.toString() : '';
    const productName = brandName ? `${brandName} ${name}` : name;
    const cartDisplayName = descriptorString ? `${productName} ${descriptorString}` : productName;
    const userQuantity = _.size(users);
    if (!sponsored) {
      cartVariants.push({
        ...variant,
        id,
        name,
        cartDisplayName,
        shareUrl,
        shippingExempt,
        users,
        shippingRestrictions,
        // segment tracking data
        categories: categoryNames,
        merchPositionOrder: ordering,
        parentLegacyPostId: legacyPostId,
        userQuantity,
      });
    }
  });
  return cartVariants;
};

export const getUpdatedCartVariants = (currentVariants, variantQuantities) => {
  const cartVariants = _.uniqBy(
    currentVariants
      .map((variant) => {
        const newVariant = { ...variant };
        newVariant.quantity = variantQuantities[variant.id];
        return newVariant;
      })
      .filter((variant) => variant.quantity > 0),
    (variant) => variant.id,
  );
  return cartVariants;
};

export const getCount = (cartVariants) => cartVariants.reduce((a, { quantity }) => a + quantity, 0);

export const getVariantQuantityLimits = (cartVariants, variantQuantities) => {
  if (!_.isEmpty(variantQuantities)) {
    return _.map(cartVariants, (variant) => {
      const purchasedQuantity = variantQuantities[variant.id] || 0;
      const cartLimit = variant.cartLimit - purchasedQuantity;
      return {
        ...variant,
        cartLimit,
      };
    });
  }
  return cartVariants;
};

export const getSocialCartProducts = (variantIdProductMap, variants) => {
  const cartProducts = _.reduce(
    variants,
    (result, value, key) => {
      const productId = variantIdProductMap[key];
      const resultProductId = result[productId];
      if (resultProductId) {
        return {
          ...result,
          [productId]: [...resultProductId, ...value],
        };
      }
      return {
        ...result,
        [productId]: value,
      };
    },
    {},
  );
  return _.reduce(
    cartProducts,
    (result, value, key) => {
      return {
        ...result,
        [key]: _.uniq(value),
      };
    },
    {},
  );
};
