import { Component, type ErrorInfo, type PropsWithChildren, type ReactNode } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as styles from './FFFErrorBoundary.module.css';

const DefaultUI = () => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.content}>
        <h1>An Error Occurred</h1>
        <p>
          We’re sorry, something went wrong.
          <br className={styles.separator} />
          Try that action again later.
        </p>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src="https://static.fabfitfun.com/wp-content/uploads/2019/05/icon-landscape%402x_1558569938.2483_1558569938.4051.png"
          alt="error page"
        />
      </div>
    </div>
  );
};

type Props = PropsWithChildren & {
  fallbackUI?: ReactNode;
  forceError?: boolean;
};

class FFFErrorBoundary extends Component<Props> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error | string, errorInfo: ErrorInfo) {
    const errorMessage = typeof error === 'string' ? error : error.message;
    const errorObj = { ...errorInfo, isCrash: true };
    datadogLogs.logger.error(errorMessage, errorObj);
    datadogRum.addError(errorMessage, errorObj);
    console.error(errorMessage, errorInfo);
  }

  render() {
    const { forceError = false, children, fallbackUI = <DefaultUI /> } = this.props;

    if (this.state.hasError || forceError) {
      return fallbackUI;
    }

    return children;
  }
}

export default FFFErrorBoundary;
