import { useEffect, useReducer } from 'react';

import { actions, homepageReducer, initialState } from '../Store';
import { getSeasonalTimelines } from '../../../services/homepage';

const useSeasonalTimelines = () => {
  const [{ seasonalTimelines }, stateDispatch] = useReducer(homepageReducer, initialState());

  useEffect(() => {
    stateDispatch(actions.getSeasonalTimelines());
    getSeasonalTimelines()
      .then((data) => stateDispatch(actions.getSeasonalTimelinesSuccess(data)))
      .catch((error) => stateDispatch(actions.getSeasonalTimelinesFail(error)));
  }, []);

  return seasonalTimelines;
};

export default useSeasonalTimelines;
