import { ASADO_BASE_URL } from '../utils/env';

const getBannerByName = async (name: string) => {
  try {
    const response = await fetch(`${ASADO_BASE_URL}/public/cms/collections/banners/${name}`);
    const responseJson = await response.json();
    return responseJson;
  } catch (error: unknown) {
    throw new Error(error as string);
  }
};

export { getBannerByName };
