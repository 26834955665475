import { useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { BlocksRenderer, type BlocksContent } from '@strapi/blocks-react-renderer';
import { getDisclaimerByName } from '../../api/disclaimers';
import fallbacks from '../../utils/disclaimer-fallbacks';

enum Variations {
  On = 'on',
}
interface DisclaimerProps {
  name: string;
  env: 'dev' | 'prod';
  variation?: string | null;
  onVariationClassname?: string;
  onVariationTitleClassname?: string;
}

const disclaimerError = [
  {
    type: 'paragraph',
    children: [
      {
        text: 'Unable to display disclaimer.',
        type: 'text',
      },
    ],
  },
];

interface Props {
  disclaimer: BlocksContent;
  termsClassname?: string;
  titleClassname?: string;
}

const Control = ({ disclaimer }: Props) => (
  <div>
    <BlocksRenderer content={disclaimer} />
  </div>
);

const OnVariation = ({ disclaimer, termsClassname, titleClassname }: Props) => {
  const TITLE = 'About Your Membership';

  return (
    <div className={termsClassname}>
      <span className={titleClassname}>{TITLE}</span>
      <BlocksRenderer content={disclaimer} />
    </div>
  );
};

const Disclaimer = ({
  name,
  env,
  variation,
  onVariationClassname,
  onVariationTitleClassname,
}: DisclaimerProps) => {
  const fallbackDisclaimer = fallbacks[name] || disclaimerError;
  const [loading, setLoading] = useState(true);
  const [disclaimer, setDisclaimer] = useState<BlocksContent>();

  useEffect(() => {
    const fetchDisclaimer = async () => {
      try {
        const disclaimer = await getDisclaimerByName(name, env);
        setDisclaimer(disclaimer.richText);
      } catch (error: unknown) {
        datadogLogs.logger.error(
          `Error displaying ${name} disclaimer from CMS`,
          {},
          error as Error,
        );
      }
      setLoading(false);
    };
    fetchDisclaimer();
  }, []);

  if (loading) return null;

  switch (variation) {
    case Variations.On:
      return (
        <OnVariation
          disclaimer={disclaimer || fallbackDisclaimer}
          termsClassname={onVariationClassname}
          titleClassname={onVariationTitleClassname}
        />
      );
    default:
      return <Control disclaimer={disclaimer || fallbackDisclaimer} />;
  }
};

export default Disclaimer;
