import classNames from 'classnames';
import { bool, func } from 'prop-types';
import './HamburgerMenuIcon.css';

const HamburgerMenuIcon = ({ isOpen = false, onClick }) => (
  <button
    type="button"
    data-testid="hamburger-menu-icon"
    className="hamburger-menu-icon"
    onClick={onClick}
  >
    <div className={classNames({ 'is-open': isOpen })}>
      <span />
      <span />
    </div>
  </button>
);

HamburgerMenuIcon.propTypes = {
  isOpen: bool,
  onClick: func,
};

export default HamburgerMenuIcon;
