import { memo, useEffect, useState, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { GlobalFooter } from '@fff-web/fff-global-footer';
import { GlobalNav } from '@fff-web/fff-global-nav';
import { Loading } from '../Loading';
import { verifyAuth } from './helpers';

type LayoutProps = {
  pageTitle?: string;
  meta?: {
    links?: { href: string; [key: string]: unknown }[];
    scripts?: { src: string; [key: string]: unknown }[];
  };
  isLoading?: boolean;
  redirectIfNoAuth?: boolean;
};

const Layout = ({
  pageTitle = 'FabFitFun',
  meta = {},
  isLoading = false,
  redirectIfNoAuth = false,
  children,
}: PropsWithChildren<LayoutProps>) => {
  const [hasPermissions, setHasPermissions] = useState(!redirectIfNoAuth);
  useEffect(() => {
    if (redirectIfNoAuth) {
      verifyAuth().then(({ ok }) => {
        if (!ok) {
          const url = `${process.env.REACT_APP_SITE_URL}/get-the-box`;
          const { search, hash } = window.location;

          window.location.replace(`${url}${search}${hash}`);
        } else {
          setHasPermissions(true);
        }
      });
    }
  }, [redirectIfNoAuth]);

  return hasPermissions ? (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        {meta &&
          meta.links &&
          meta.links.map(({ href, ...link }) => (
            <link key={href} {...link} href={href} rel="stylesheet" type="text/css" />
          ))}
        {meta &&
          meta.scripts &&
          meta.scripts.map(({ src, ...script }) => <script key={src} {...script} src={src} />)}
      </Helmet>
      <MemoizedGlobalNav />
      {isLoading ? <Loading /> : children}
      <GlobalFooter />
    </>
  ) : null;
};

export default Layout;

const MemoizedGlobalNav = memo(function MemoizedNav() {
  return <GlobalNav env={process.env.REACT_APP_ENVIRONMENT} />;
});
