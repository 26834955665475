// extracted by mini-css-extract-plugin
export var additionalChoiceContainer = "CartItem_additionalChoiceContainer__uX92V";
export var cadence = "CartItem_cadence__p3S7X";
export var container = "CartItem_container__h2r5L";
export var content = "CartItem_content__voNLt";
export var imageLink = "CartItem_imageLink__NzNak";
export var nakedButton = "CartItem_nakedButton__-pfU9";
export var name = "CartItem_name__Qgru6";
export var price = "CartItem_price__qHBFJ";
export var right = "CartItem_right__rzs9V";
export var shared = "\"../../../shared/shared.module.css\"";
export var sm = "576px";
export var strikethroughPrice = "CartItem_strikethroughPrice__47Zvb";
export var variant = "CartItem_variant__Dej+X";
export var warning = "CartItem_warning__98M9g";