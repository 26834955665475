import { createContext, useContext, type FunctionComponent, type PropsWithChildren } from 'react';

type CartContextType = {
  account?: {
    loggedIn: boolean;
  };
  cartData?: {};
  receiveUpdateCart?: () => void;
  onClickProduct?: ({ variant }: { variant: { questionId: string } }) => void;
  receiveCartProducts?: () => void;
  seasonalPlanPrice?: number;
  saleRefreshKey?: string;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

const CartContextProvider: FunctionComponent<PropsWithChildren<CartContextType>> = ({
  account,
  cartData,
  receiveUpdateCart,
  onClickProduct,
  children,
  receiveCartProducts,
  seasonalPlanPrice,
  saleRefreshKey,
}) => (
  <CartContext.Provider
    // TODO: Add useMemo here

    value={{
      account,
      cartData,
      receiveUpdateCart,
      onClickProduct,
      receiveCartProducts,
      seasonalPlanPrice,
      saleRefreshKey,
    }}
  >
    {children}
  </CartContext.Provider>
);

export const useCartContext = () => {
  const context = useContext(CartContext);
  return (
    context || {
      account: null,
      cartData: null,
      receiveUpdateCart: null,
      onClickProduct: null,
      receiveCartProducts: null,
      seasonalPlanPrice: null,
      saleRefreshKey: null,
    }
  );
};

export default CartContextProvider;
