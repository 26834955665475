import { datadogLogs } from '@datadog/browser-logs';
import { createInstance, type Client } from '@optimizely/optimizely-sdk';

const sdkKeys = {
  dev: 'CmxanfNTQWUBLjcjCwu7N',
  prod: 'PVEkuvxG7EbQeuTq5P7BJ',
};

const featureFlags = {
  disclaimerPriceIncrease: 'disclaimer_price_increase',
};

type FeatureFlagNames = keyof typeof featureFlags;

export type FeatureFlags = {
  [key in FeatureFlagNames]?: boolean;
};

type FeatureFlagClass = {
  ready(): Promise<{ success: boolean; reason?: string }>;
  getFlags(): Promise<void | { [key in FeatureFlagNames]: boolean }> | undefined;
  userId: string | undefined;
};

class Optimizely implements FeatureFlagClass {
  private client: Client | null;
  constructor(env: 'dev' | 'prod', userId?: string) {
    this.client = createInstance({
      sdkKey: sdkKeys[env],
    });
    this.userId = userId || 'anonymous';
  }
  userId: string;
  ready() {
    return (
      this.client?.onReady() ||
      Promise.resolve({ success: false, reason: 'Could not initialize Optimizely' })
    );
  }
  getFlags() {
    return this.client
      ?.onReady()
      .then(({ success, reason }) => {
        if (!success) {
          throw new Error(reason);
        }

        const user = this.client?.createUserContext(this.userId);

        if (!user) {
          throw new Error(
            'Failed to create user context when initializing Optimizely in feature-flags lib',
          );
        }

        const disclaimerPriceIncrease = user.decide(featureFlags.disclaimerPriceIncrease);

        return {
          disclaimerPriceIncrease: disclaimerPriceIncrease.enabled,
        };
      })
      .catch((err: unknown) => {
        datadogLogs.logger.error(
          'Error getting flags in Optimizely in feature-flags lib',
          {},
          err as Error,
        );
      });
  }
}

class FeatureFlag {
  private static instance: FeatureFlag;
  private featureFlags: FeatureFlagClass | undefined;

  private constructor(env: 'dev' | 'prod', userId?: string) {
    this.featureFlags = new Optimizely(env, userId);
  }

  static getInstance(env: 'dev' | 'prod', userId?: string) {
    if (!FeatureFlag.instance) {
      FeatureFlag.instance = new FeatureFlag(env, userId);
    }
    return FeatureFlag.instance;
  }

  ready() {
    return this.featureFlags?.ready();
  }

  getFlags() {
    return this.featureFlags?.getFlags();
  }
}

export default FeatureFlag;
