import type { FunctionComponent } from 'react';
import { ACTIONS, type Placement } from 'react-joyride';
import spacetime from 'spacetime';
import { OnboardingModal } from '@fff-web/fff-ui-shared';
import { NewMemberState, updateNewMemberState } from '@fff-web/fff-utilities';
import { useInternalContext } from '../../../context/InternalContext';
import * as styles from './CartOnboarding.module.css';

// TODO: move all states to Utilities and use accross all apps
export const NEW_MEMBER_STATE = {
  AWAITING_ADDRESS: 'AWAITING_ADDRESS',
  PERSONAL_SURVEY: 'PERSONAL_SURVEY',
  CUSTOMIZING: 'CUSTOMIZING',
  USER_ONBOARDING_CUSTOMIZING: 'USER_ONBOARDING_CUSTOMIZING',
  XSELLS: 'XSELLS',
  USER_ONBOARDING_XSELLS: 'USER_ONBOARDING_XSELLS',
  CONFIRMATION: 'CONFIRMATION',
};

type CartOnboardingProps = {
  campaignData: {
    edit: {
      endDate: string;
    };
  };
  newMemberOnboardingFF: boolean;
  userId: string;
  saleItemsCount: number;
  isNewMember: boolean;
  newMemberState: NewMemberState;
  onNewMemberStateFetched: (newState: string) => void;
};

export const CartOnboarding: FunctionComponent<CartOnboardingProps> = ({
  campaignData,
  newMemberOnboardingFF,
  userId,
  saleItemsCount,
  isNewMember,
  newMemberState,
  onNewMemberStateFetched,
}) => {
  const { isNewMemberBoostsPage, isNewMemberCustomize } = useInternalContext();

  const handleOnboardingCallback = async ({ action }: { action: string }) => {
    if (action === ACTIONS.CLOSE) {
      const newState =
        newMemberState === NEW_MEMBER_STATE.CUSTOMIZING
          ? NEW_MEMBER_STATE.USER_ONBOARDING_CUSTOMIZING
          : NEW_MEMBER_STATE.USER_ONBOARDING_XSELLS;
      // Opitimistically update our context with the new state to avoid re-opening the tutorial modal
      onNewMemberStateFetched(newState);
      await updateNewMemberState(userId, newState as NewMemberState);
    }
  };

  const showOnboarding = !!(
    newMemberOnboardingFF &&
    ((isNewMemberBoostsPage && newMemberState === NEW_MEMBER_STATE.XSELLS) ||
      (isNewMemberCustomize && newMemberState === NEW_MEMBER_STATE.CUSTOMIZING)) &&
    saleItemsCount
  );

  return (
    <>
      {campaignData && isNewMember && (
        <OnboardingModal
          hideBackButton
          disableOverlayClose
          disableScrolling
          stepIndex={0}
          steps={getOnboardingSteps(campaignData.edit.endDate)}
          spotlightPadding={5}
          run={showOnboarding}
          callback={handleOnboardingCallback}
        />
      )}
    </>
  );
};

const getOnboardingSteps = (campaignEndDate: string) => {
  const time = spacetime(campaignEndDate, 'UTC').goto('America/Los_Angeles');
  const formattedTime = time.format('{month} {date-ordinal} at {hour}:{minute-pad}{ampm} PST');

  return [
    {
      target: '#cart',
      title: (
        <>
          <span className={styles.centerAlign}>
            <i className="icon far fa-check-circle onboardingGreenIcon" />
            &nbsp;
            <span>ADDED TO YOUR BOX!</span>
          </span>
          <i className="icon far fa-arrow-up onboardingRedIcon" />
        </>
      ),
      content: (
        <>
          All add-on items will be automatically processed with your box. Add or remove items any
          time before <strong>{formattedTime}</strong>.
        </>
      ),
      placement: 'bottom-end' as Placement,
      disableBeacon: true,
      hideCloseButton: true,
      locale: {
        close: 'GOT IT!',
      },
    },
  ];
};
