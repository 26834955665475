import './css/fontawesome.min.css';
import './css/brands.min.css';
import './css/light.min.css';
import './css/regular.min.css';
import './css/solid.min.css';
import type { FunctionComponent } from 'react';

const FontAwesome: FunctionComponent = () => <div />;

export default FontAwesome;
