import { Fallbacks } from '../types';

const editFallbacks: Fallbacks = {
  ['flash-sale-closed']: {
    cta: '',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/flash_sale_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/flash_sale_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'View our ',
            type: 'text',
          },
          {
            url: 'https://get.fabfitfun.com/upcoming/',
            type: 'link',
            children: [
              {
                text: 'Events Calendar',
                type: 'text',
              },
            ],
          },
          {
            text: ' for upcoming sales.',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Check Back Soon for Our Next Sale!',
  },
};

export default editFallbacks;
