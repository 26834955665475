import { useEffect } from 'react';

const useUserWay = () => {
  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('data-account', 'jcEETxr6Cv');
    s.setAttribute('src', 'https://accessibilityserver.org/widget.js');
    (document.body || document.head).appendChild(s);
  }, []);

  return null;
};

export default useUserWay;
