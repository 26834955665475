import { CSSObject } from 'styled-components';
import { ButtonLinkType } from './ButtonLink';

const primaryCssProperties: CSSObject = {
  color: '#ff5b5d',
  background: 'none',
  '&:hover': {
    color: '#fa9c98',
    textDecoration: 'underline',
  },
  '&:active': {
    color: '#c75552',
  },
  '&:disabled': {
    color: '#faccca',
  },
};

const blackCssProperties: CSSObject = {
  color: '#000000',
  background: 'none',
  '&:hover': {
    color: '#9b9b9b',
    textDecoration: 'underline',
  },
  '&:active': {
    color: '#000000',
  },
  '&:disabled': {
    color: '#d0d0d0',
  },
};

const grayCssProperties: CSSObject = {
  color: '#4a4a4a',
  background: 'none',
  '&:hover': {
    color: '#9b9b9b',
    textDecoration: 'underline',
  },
  '&:active': {
    color: '#363636',
  },
  '&:disabled': {
    color: '#d0d0d0',
  },
};

export const getTheme = (type: ButtonLinkType): CSSObject => {
  switch (type) {
    case 'primary':
      return primaryCssProperties;
    case 'black':
      return blackCssProperties;
    case 'gray':
      return grayCssProperties;
  }

  return {};
};
