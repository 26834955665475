import classnames from 'classnames';
import { ArrowRight } from '../../Icon';
import * as styles from './CarouselButtons.module.scss';

enum ButtonsIds {
  left = 'arrow-button-left',
  right = 'arrow-button-right',
}
export interface CarouselButtonsProps {
  className?: string;
  isFirstItemVisible: boolean;
  isLastItemVisible: boolean;
  scrollRight: () => void;
  scrollLeft: () => void;
}

const CarouselButtons = ({
  className,
  scrollLeft,
  scrollRight,
  isFirstItemVisible,
  isLastItemVisible,
}: CarouselButtonsProps) => {
  const classNameWrapper = classnames(styles.container, className);
  const arrowButtonLeft = classnames(styles.arrowButton, styles.arrowButtonLeft);
  const arrowButtonRight = classnames(styles.arrowButton, styles.arrowButtonRight);

  return (
    <div className={classNameWrapper}>
      {!isFirstItemVisible && (
        <button
          type="button"
          className={arrowButtonLeft}
          onClick={scrollLeft}
          data-testid={ButtonsIds.left}
          name={ButtonsIds.left}
        >
          <ArrowRight />
        </button>
      )}
      {!isLastItemVisible && (
        <button
          type="button"
          className={arrowButtonRight}
          onClick={scrollRight}
          data-testid={ButtonsIds.right}
          name={ButtonsIds.right}
        >
          <ArrowRight />
        </button>
      )}
    </div>
  );
};

export { ButtonsIds, CarouselButtons };
