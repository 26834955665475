const small = 576;
const medium = 768;
const large = 992;
const extraLarge = 1200;

const breakpoints = {
  small,
  medium,
  large,
  extraLarge,
};

export default breakpoints;
