import isBrowser from './isBrowser';

const getParamFromUrl = (param: string): string => {
  if (!isBrowser()) return '';
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get(param);
  return paramValue || '';
};

export default getParamFromUrl;
