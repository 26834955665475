import classNames from 'classnames';
import * as styles from './CartButton.module.css';

type CartButtonProps = {
  itemsCount: number;
  toggleCart: () => void;
};

const CartButton = ({ itemsCount, toggleCart }: CartButtonProps) => (
  <button
    id="cart"
    className={classNames(styles.container, 'cart-button')}
    onClick={toggleCart}
    type="button"
    data-dd-action-name="open/close cart button"
  >
    {itemsCount > 0 && itemsCount <= 99 && <span>{itemsCount}</span>}
    {itemsCount > 99 && <span className={styles.maxCount}>99+</span>}
    <i className="fal fa-shopping-cart" />
  </button>
);

export default CartButton;
