export const CTAS = {
  invite: 'SEND A FREE BOX',
  upgrade: 'UPGRADE TO ANNUAL',
  reactivate: 'REACTIVATE',
  viewEvents: 'VIEW EVENTS',
  activateMembership: 'ACTIVATE MEMBERSHIP',
};

export const FFFTRIAL_CTAS = {
  active: 'ACTIVATE TODAY',
  canceled: 'REACTIVATE NOW',
  expired: 'REACTIVATE NOW',
};
