import { Fallbacks } from '../types';

const homepageFallbacks: Fallbacks = {
  ['homepage']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/homepage/cdn/homepage_desktop.jpg',
    title: 'Deals for Days',
    cta: 'CUSTOMIZE',
    ctaUrl: 'https://fabfitfun.com/customize/',
    secondCta: 'SHOP ADD-ONS',
    secondCtaUrl: 'https://fabfitfun.com/add-ons/',
    imageMobile: 'https://scontent01.fabfitfun.com/homepage/cdn/homepage_mobile.jpg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Browse The Shop where Members can earn 5% in FFF Rewards on qualifying purchases (terms apply).',
            type: 'text',
          },
        ],
      },
    ],
  },
};

export default homepageFallbacks;
