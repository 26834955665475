import type { FunctionComponent } from 'react';
import { Toast } from '@fff-web/fff-ui-shared';
import useExpirationToast from '../../hooks/useExpirationToast';

const NavToast: FunctionComponent<{ loggedIn: boolean }> = ({ loggedIn }) => {
  const { snoozeToast } = useExpirationToast(loggedIn);

  return <Toast onCloseButton={snoozeToast} />;
};

export default NavToast;
