import { Fallbacks } from '../types';

const giftingFallbacks: Fallbacks = {
  ['gifting']: {
    imageDesktop: 'https://static.fabfitfun.com/cms/2880x900_071224_NF_1_424a845db0.jpg',
    title: '',
    cta: null,
    ctaUrl: null,
    secondCta: null,
    secondCtaUrl: null,
    imageMobile: 'https://static.fabfitfun.com/cms/800x800_071224_NF_67b06388e0.jpg',
    subtitle: null,
  },
};

export default giftingFallbacks;
