const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

/**
 * Converts a value in dollars to cents
 * @param {number} valueInDollars - The value in dollars to convert
 * @returns {number} The value in cents
 */
export function convertToCents(valueInDollars) {
  return Number(valueInDollars) * 100;
}

/**
 * Converts a value in cents to dollars
 * @param {number} valueInCents - The value in cents to convert
 * @returns {number} The value in dollars
 */
export function convertToDollars(valueInCents) {
  return Number(valueInCents) / 100;
}

/**
 * Formats a value to a currency string
 * @param {number} value - The value to format
 * @returns {string} The formatted value
 */
export function formatCurrency(value) {
  return Number(value).toLocaleString('en', options);
}

//expects a number in cents
/**
 * Formats a value to a currency string in cents
 * @param {number} cents - The value in cents to format
 * @returns {string} The formatted value
 */
export function formatCurrencyInCents(cents) {
  const valueInDollars = cents / 100;
  return formatCurrency(valueInDollars);
}

/**
 * Calculates the total price for a cart item
 * @param {number} quantity - The quantity of the item
 * @param {number} salePrice - The sale price of the item
 * @param {number} price - The price of the item
 * @returns {string} The total price for the cart item
 */
export function calculateCartItemTotal(quantity, salePrice, price) {
  const finalPrice = salePrice || price;
  const cartItemTotalInCents = convertToCents(finalPrice) * quantity;
  return formatCurrencyInCents(cartItemTotalInCents);
}
