import type { FunctionComponent } from 'react';
import { styled, type CSSProperties } from 'styled-components';
import { Times } from '../Icon';

const Button = styled.button<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: #000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;

  &:hover {
    background-color: #4a4a4a;
  }
`;

type CloseButtonProps = {
  onClick: () => void | undefined;
  style?: CSSProperties | undefined;
  size?: number;
};

const CloseButton: FunctionComponent<CloseButtonProps> = ({ onClick, style, size = 24 }) => {
  const iconSize = (size * 3) / 8;
  return (
    <Button type="button" style={style} onClick={onClick} size={size} data-testid="close-button">
      <Times height={iconSize} width={iconSize} fill="white" />
    </Button>
  );
};

export default CloseButton;
