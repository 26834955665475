import { useEffect, type FunctionComponent } from 'react';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { initialize as initializeAnalytics } from '@fff-web/fff-analytics';
import { useUserContext, useViewSize } from '@fff-web/fff-utilities';
import useBoxState from './hooks/useBoxState';
import useChatConfiguration from './hooks/useFooterChatConfiguration';
import useFooterContent from './hooks/useFooterContent';
import { useShippingAddress } from './hooks/useShippingAddress';
import useSubscriptionPlans from './hooks/useSubscriptionPlans';
import useUserWay from './hooks/useUserWay';
import { LegalFooter } from './LegalFooter/LegalFooter';
import { LinkFooter } from './LinkFooter';
import { SMFooter } from './SMFooter/SMFooter';
import UserWay from './UserWay';
import { buildPath } from './utils/buildPath';
import { loadChat, removeAllWidgets } from './utils/loadChat';
import { loadPactSafe } from './utils/loadPactSafe';
import LogoPng from './images/logo.png';

type FooterPropsType = {
  minimalFooter?: boolean;
  hideChatWidget?: boolean;
  isUseAnalytic?: boolean;
  pathname?: string;
};

const GlobalFooterDefault: FunctionComponent<FooterPropsType> = ({
  minimalFooter = false,
  hideChatWidget = false,
  isUseAnalytic = true,
  pathname = null,
}) => {
  const { footerContent } = useFooterContent();
  const { userInfo } = useUserContext();
  const { loggedIn } = userInfo;
  const { subscriptionPlans } = useSubscriptionPlans();
  const chatConfig = useChatConfiguration();
  const data = footerContent;
  const { ccpaFooterLink } = useFlags();
  const { state } = useShippingAddress(ccpaFooterLink || {});
  const { isMobile, isTablet } = useViewSize();

  const { hideSwap } = useBoxState(loggedIn);
  useUserWay();
  useEffect(() => {
    if (isUseAnalytic) {
      initializeAnalytics();
    }
    loadPactSafe();
  }, []);

  useEffect(() => {
    if (hideChatWidget) removeAllWidgets();
    else loadChat(chatConfig, userInfo.user);
  }, [chatConfig, hideChatWidget, pathname]);

  const footerContainer = cx({
    'fff-shared-footer-container-mobile': isMobile,
  });
  const footerTop = cx({
    'fff-shared-footer-container-top': true,
    'fff-shared-footer-container-top-logged': loggedIn,
  });

  const getLinks = () => {
    if (loggedIn) {
      return data.mainFooter.loginState;
    }
    return data.mainFooter.logoutState;
  };

  return data ? (
    <div
      data-testid="global-footer"
      className={cx('fff-shared-footer-container', footerContainer, {
        'is-not-login': !loggedIn,
      })}
    >
      {!minimalFooter && (
        <>
          <div className="fff-shared-footer-top-border" />
          <div className={footerTop}>
            <div className="fff-logo-container">
              <img alt="logo" src={LogoPng} className="fff-logo" />
              {!isMobile && isTablet && <UserWay />}
            </div>
            <LinkFooter
              buildPath={buildPath}
              categories={getLinks()}
              isMobile={isMobile}
              isLogin={loggedIn}
              userInfo={userInfo}
              hideSwap={hideSwap}
              subscriptionPlans={subscriptionPlans}
            />
            {!isTablet && (
              <SMFooter
                socialMedias={data.socialMedias}
                isMobile={isMobile}
                isTablet
                isLogin={loggedIn}
              />
            )}
          </div>
        </>
      )}
      <LegalFooter
        buildPath={buildPath}
        legals={data.legal}
        isTablet={isTablet}
        shippingState={state}
        isLogin={loggedIn}
      />
    </div>
  ) : null;
};

export { GlobalFooterDefault };
