import cx from 'classnames';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';

import Node from './Node';
import './Timeline.css';

const BoxTimeline = ({
  nodes = [],
  bodyDetail = '',
  currentStep = '',
  isMobile = true,
  trackingUrl = '',
}) => {
  const [visibleNodes, setVisibleNodes] = useState(nodes);
  useEffect(() => {
    if (!trackingUrl.includes('fedex.com')) {
      setVisibleNodes(nodes.filter((n) => n.label !== 'Delivered'));
    }
  }, [trackingUrl]);

  useEffect(() => {
    setVisibleNodes(nodes);
  }, [nodes.length]);

  const activeStepIndex = visibleNodes.findIndex((x) => x.label === currentStep);

  return (
    <div className={`box-timeline ${visibleNodes.length === 4 ? 'shorter-timeline' : ''}`}>
      {visibleNodes &&
        visibleNodes.map((node, index) => {
          let nodeStatus = index < activeStepIndex ? 'past' : '';
          const isActive = index === activeStepIndex;
          nodeStatus = isActive ? 'active' : nodeStatus;
          const data = { message: node.label, status: nodeStatus };

          return (
            <div
              key={node.label}
              className={cx({
                'challenge-timeline__node-box': isMobile,
                'challenge-timeline__node-box-desktop': !isMobile,
              })}
            >
              {index < visibleNodes.length - 1 && (
                <div
                  className={cx({
                    'challenge-timeline__node-box__line': isMobile,
                    'challenge-timeline__node-box__line-desktop': !isMobile,
                    solid: index < activeStepIndex,
                  })}
                />
              )}
              <Node
                data={data}
                isBoxNode
                isMobile={isMobile}
                trackingUrl={trackingUrl}
                bodyDetail={bodyDetail}
              />
            </div>
          );
        })}
    </div>
  );
};

BoxTimeline.propTypes = {
  nodes: arrayOf(
    shape({
      label: string,
      message: string,
      order: number,
    }),
  ),
  bodyDetail: string,
  currentStep: string,
  trackingUrl: string,
  isMobile: bool,
};

export default BoxTimeline;
