import { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { getLink } from '../../../helpers/navigationMenu';
import MenuItem from '../MenuItem';
import './SubMenu.css';
import GlobalNavInternalContext from '../../../context/GlobalNavInternalContext';

const SubMenu = ({
  label = '',
  domain = null,
  route = '',
  items = [],
  isDesktop,
  renderFunction = ({ domain, route, label }) => <a href={getLink({ domain, route })}>{label}</a>,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const subMenuRef = useRef();

  const { env } = useContext(GlobalNavInternalContext);
  const baseUrl = domain || env.REACT_APP_BASE_URL;

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    subMenuRef.current.style.maxHeight = isOpen ? null : `${subMenuRef.current.scrollHeight + 6}px`;
    if (!isOpen) {
      const navRef = subMenuRef.current.parentElement.parentElement.parentElement;
      setTimeout(
        () =>
          navRef.scrollTo({
            top: subMenuRef.current.offsetTop - 100,
            behavior: 'smooth',
          }),
        700,
      );
    }
  };
  return (
    <div className={classNames('sub-menu', { 'is-open': isOpen })}>
      <li className="sub-menu__label">
        {renderFunction({
          domain: baseUrl,
          route,
          label,
          items,
          isDesktop,
        })}
        {isDesktop ? (
          <i className="fas fa-angle-down" />
        ) : (
          <button onClick={handleToggleOpen} type="button">
            {isOpen ? <i className="fas fa-angle-down" /> : <i className="fas fa-angle-right" />}
          </button>
        )}
      </li>

      <ul ref={subMenuRef}>
        {items.map((item) => (
          <MenuItem key={item.id} item={item} dataTestId={`submenu-${item.label}`} />
        ))}
      </ul>
    </div>
  );
};

SubMenu.propTypes = {
  label: string,
  domain: string,
  route: string,
  items: arrayOf(shape()),
  renderFunction: func,
  isDesktop: bool,
};

export default SubMenu;
