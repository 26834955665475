import type { FunctionComponent, PropsWithChildren } from 'react';
import { createInstance, enums, OptimizelyProvider } from '@optimizely/react-sdk';
import { useUserContext } from '@fff-web/fff-utilities';
import { getEnv } from '../../environment';
import { getUUID } from '../utils/configurations';

const optimizely = createInstance({
  sdkKey: getEnv().REACT_APP_OPTIMIZELY,
  logLevel: enums.LOG_LEVEL.ERROR,
});

const FFFOptimizelyProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { userInfo } = useUserContext();
  const userId = userInfo?.user?.userId ?? getUUID();

  return (
    <OptimizelyProvider optimizely={optimizely} user={{ id: userId.toString() }}>
      {children}
    </OptimizelyProvider>
  );
};

export default FFFOptimizelyProvider;
