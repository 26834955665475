import { Fallbacks } from '../types';

const addOnsFallbacks: Fallbacks = {
  ['add-ons-closed']: {
    cta: 'GO TO SHOP',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'In the meantime, check out our amazing products in the Shop!',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-closed-select-user']: {
    cta: 'GO TO SHOP',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'In the meantime, check out our amazing products in the Shop!',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-closed-trial-user']: {
    cta: 'UPGRADE TO ANNUAL',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'In the meantime, check out our amazing products in the Shop!',
            type: 'text',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            bold: true,
            text: 'Want to shop the next sale?',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-closed-expired-user']: {
    cta: 'REACTIVATE NOW',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            bold: true,
            text: 'Want to shop the next sale?',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-closed-club-user']: {
    cta: 'ACTIVATE MY MEMBERSHIP',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'In the meantime, check out our amazing products in the ',
            type: 'text',
          },
          {
            url: 'https://fabfitfun.com/shop',
            type: 'link',
            children: [
              {
                text: 'Shop',
                type: 'text',
              },
            ],
          },
          {
            text: '!',
            type: 'text',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            bold: true,
            text: 'Want to shop the next sale?',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-closed-cancelled-with-zero-boxes-user']: {
    cta: 'REACTIVATE NOW',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            bold: true,
            text: 'Want to get access when it does open?',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-closed-customer-user']: {
    cta: 'UPGRADE TO ANNUAL',
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'In the meantime, check out our amazing products in the Shop!',
            type: 'text',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            bold: true,
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
    title: 'Add-Ons is Closed for the Season',
  },
  ['add-ons-mid-status-select-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'ADD TO CALENDAR',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: null,
  },
  ['add-ons-mid-status-customer-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'ADD TO CALENDAR',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: null,
  },
  ['add-ons-mid-status-trial-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'ADD TO CALENDAR',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-mid-status-expired-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-mid-status-club-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'ACTIVATE MY MEMBERSHIP',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-mid-status-cancel-with-zero-boxes-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'ACTIVATE MY MEMBERSHIP',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-before-start-status-select-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'ADD TO CALENDAR',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: null,
  },
  ['add-ons-before-start-status-customer-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'UPGRADE TO ANNUAL',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-before-start-status-trial-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'UPGRADE TO ANNUAL',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-before-start-status-expired-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-before-start-status-club-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Will Open to Members Soon',
    cta: 'ACTIVATE MY MEMBERSHIP',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-before-start-status-cancelled-with-zero-boxes-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons Opens Soon',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-one-status-customer-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Open for Annual Members',
    cta: 'UPGRADE TO ANNUAL',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-one-status-trial-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Open for Annual Members',
    cta: 'UPGRADE TO ANNUAL',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-one-status-expired-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Open for Annual Members',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-one-status-club-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Open for Annual Members',
    cta: 'ACTIVATE MY MEMBERSHIP',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want early access to sales and Customization?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-one-status-cancelled-with-zero-boxes-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Open for Annual Members',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-two-status-expired-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Now Open',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-two-status-club-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Open for Members Only',
    cta: 'ACTIVATE MY MEMBERSHIP',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
  ['add-ons-window-two-status-cancelled-with-zero-boxes-user']: {
    imageDesktop: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_desktop.jpeg',
    title: 'Add-Ons is Now Open',
    cta: 'REACTIVATE NOW',
    imageMobile: 'https://scontent01.fabfitfun.com/gtb/cdn/add_ons_banner_mobile.jpeg',
    subtitle: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Want to shop Add-Ons?',
            type: 'text',
          },
        ],
      },
    ],
  },
};

export default addOnsFallbacks;
