import type { FunctionComponent } from 'react';
import { styled, type CSSObject } from 'styled-components';

export type BreakLineProps = {
  width?: number | string;
  height?: number | string;
  margin?: string;
  styles?: CSSObject;
};

const FFFBreakLine = styled.div<BreakLineProps>((props) => ({
  width: props.width || 351,
  height: props.height || 1,
  margin: props.margin || '14px 6px 0 4px',
  backgroundImage:
    'linear-gradient( to left, #ffffff, #dddddd 19%, #d0d0d0 52%, #e6e6e6 83%, #ffffff )',
  ...props.styles,
}));

export const BreakLine: FunctionComponent<BreakLineProps> = ({ width, height, margin, styles }) => (
  <FFFBreakLine
    width={width}
    height={height}
    margin={margin}
    styles={styles}
    data-testid="fff-break-line"
  />
);

export default BreakLine;
