import { useEffect, useRef, useState, type PropsWithChildren } from 'react';
import cx from 'classnames';
import useToggle from '../../hooks/useToggle';
import './CollapsiblePanel.css';

type CollapsiblePanelProps = {
  screenMode?: string;
  initialValue?: boolean;
  title: boolean | string;
};

const CollapsiblePanel = ({
  initialValue = true,
  title,
  screenMode = 'mobile',
  children,
}: PropsWithChildren<CollapsiblePanelProps>) => {
  const [height, setHeight] = useState<number | null>(null);
  const [isOpen, setOpen, , handleToggle] = useToggle(initialValue);
  const bodyRef = useRef<HTMLDivElement>(null);
  const isDesktop = screenMode === 'desktop';

  useEffect(() => {
    if (isDesktop) {
      setHeight(null);
      setOpen();
    } else {
      setHeight(bodyRef.current?.clientHeight || 0);
    }
  }, [children, screenMode, isDesktop]);

  const handleOpen = () => {
    if (!isDesktop) {
      handleToggle();
    }
  };

  return (
    <div className={cx('collapsible-panel', screenMode, { collapsed: !isOpen })}>
      {title && (
        <div
          className="collapsible-panel__header"
          role="button"
          tabIndex={-1}
          onClick={handleOpen}
          onKeyPress={handleOpen}
        >
          <h4>{title}</h4>
        </div>
      )}
      <div
        className="collapsible-panel__body"
        style={{ maxHeight: isOpen ? height || undefined : 0 }}
        ref={bodyRef}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsiblePanel;
