import type { FunctionComponent } from 'react';
import { styled, ThemeProvider } from 'styled-components';
import { getTheme } from './linkCssProperties';

export type LinkType = 'primary' | 'black' | 'gray' | 'blue';

type LinkProps = {
  href: string;
  text: string;
  color?: LinkType;
  bold?: boolean;
  fontSize?: number;
  permaUnderline?: boolean;
};

type FFFLinkProps = {
  href: string;
  color?: LinkType;
  bold?: boolean;
  fontSize?: number;
  permaUnderline?: boolean;
};

const FFFLink = styled.a.attrs((props) => ({
  href: props.href,
  color: props.color,
}))<FFFLinkProps>((props) => ({
  cursor: 'pointer',
  padding: 0,
  fontFamily: "'Nunito Sans', sans-serif;",
  lineHeight: 1.5,
  letterSpacing: 'normal',
  fontSize: props.fontSize || 16,
  textDecoration: props.permaUnderline ? 'underline' : 'none',
  fontWeight: props.bold ? 'bold' : 'normal',
  ...props.theme,
  '&:hover': {
    textDecoration: 'underline',
    ...props.theme['&:hover'],
  },
  '&:active': {
    textDecoration: 'none',
    ...props.theme['&:active'],
  },
}));

const Link: FunctionComponent<LinkProps> = ({
  href,
  text,
  color,
  bold,
  fontSize,
  permaUnderline,
}) => (
  <ThemeProvider theme={getTheme(color || 'primary')}>
    <FFFLink
      href={href}
      color={color}
      bold={bold}
      fontSize={fontSize}
      target="_blank"
      rel="noreferrer"
      permaUnderline={permaUnderline}
    >
      {text}
    </FFFLink>
  </ThemeProvider>
);

export default Link;
